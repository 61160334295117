export const environment = {
    production: false,
    defaultLocale: 'en-US',
    maxBookingUploadFileSize: 10000000,
    notifyBarTimeDelay: 5000,
    firebase: {
        apiKey: "AIzaSyCOUe5B8VQ1Zb1lzNYhYOJKe45OvAeClic",
        authDomain: "staynconnect-develop.firebaseapp.com",
        projectId: "staynconnect-develop",
        storageBucket: "staynconnect-develop.appspot.com",
        messagingSenderId: "335304782933",
        appId: "1:335304782933:web:a42cd977f03918659490dc",
        measurementId: "G-FDBXD88QRF"
    },
    actionCodeSettings: {
        // Your redirect URL
        url: 'https://develop.surfrizz.com',
        handleCodeInApp: true,
    }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.