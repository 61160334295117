import { DocumentData } from "firebase/firestore";

export class MessageData {

    key: string | null = null
    message: string | null = null

    senderKey: string | null = null
    senderName: string | null = null
    senderMail: string | null = null
    receiverName: string | null = null
    receiverMail: string | null = null
    receiverKey: string | null = null

    date: any | null = null

    contactKey: string | null = null
    boardKey: string | null = null

    static toObject(data: MessageData): {} {

        const mappped = new Map(Object.entries(data));

        return Object.fromEntries(mappped)
    }

    static toMessage(doc: DocumentData, messageKey: string): MessageData | null {

        if (doc === undefined || doc === null) {
            return null
        }

        const data = doc as MessageData
        data.key = messageKey

        return data
    }
}
