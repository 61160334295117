import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cmToFeetInch'
})
export class CmToFeetInchPipe implements PipeTransform {

    transform(value: number): string {
        if (!value) return '';

        // Convert cm to inches
        const totalInches = value / 2.54;

        // Calculate feet and remaining inches
        const feet = Math.floor(totalInches / 12);
        const inches = Math.round(totalInches % 12);

        return `${feet}' ${inches}"`
    }

}