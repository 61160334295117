<div class="content padval" style="--padding: 8px 16px 0 16px;">

    <div class="vstack dimval" style="--h: 100%;">
        Nikolaus Koller<br>Slavi-Soucek-Straße 18, 5026 Salzburg, Österreich<br>admin[AT]surfrizz.com
        <br><br>
        Based in Austria, made with ❤️ on the road.
        <br><br><br><br><br><br><br><br><br><br><br><br>
        <br><br><br><br><br><br><br><br><br><br><br><br><br>
        <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
    </div>
</div>