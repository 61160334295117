import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private _show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public get show$() {
    return this._show$.asObservable();
  }

  public show(): void {
    setTimeout(() => {
      this._show$.next(true);
    });
  }

  public hide(): void {
    setTimeout(() => {
      this._show$.next(false);
    }, 500);
  }
}
