import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../views/general/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '../../../projects/boardswap/src/app/localization/translate.service';


@Injectable({
  providedIn: 'root'
})
export class ConfirmHandlerService {


  constructor(
    private translateService: TranslateService
  ) { }

  openDialog(dialog: MatDialog, messageKey: string, yesKey: string | null, noKey: string | null): Promise<boolean> {
    const dialogRef = dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: this.translateService.translate(messageKey),
        yes: this.translateService.translate(yesKey ?? 'yes'),
        no: this.translateService.translate(noKey ?? 'no')
      },
    });

    return new Promise<boolean>((userResult) => {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log('User clicked Yes');
          userResult(true)
        } else {
          console.log('User clicked No or closed the dialog');
          userResult(false)
        }
      });
    })
  }
}
