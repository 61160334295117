import { DocumentData } from "@angular/fire/compat/firestore"
import { RoomData } from "./room"
import { IndexDataType } from "../../../../../../src/app/model/helper/IndexDataType"
import { LocationId } from "../../enum/locationId"

export class ListingData {

    key: string | null = null

    name: string | null = null
    // location

    aboutOffer: string | null = null

    // included
    yoga: boolean = false
    breakfast: boolean = false
    surfEquipment: boolean = false

    // room
    roomDataJson: string | null = null

    listingType: string | null = null

    // price
    discount1Week: number = 0
    discount4Week: number = 0

    businessKey: string | null = null

    locationId = LocationId.moroccoTamraghtTagazout.locationId
    publish = false

    // house data

    rooms: string[] = []

    // description
    maxRooms: number = 0
    maxGuests: number = 0

    apartmentDesc: string | null = null
    locationDesc: string | null = null
    surfSpots: string | null = null

    photos: IndexDataType[] = []

    wifi: boolean = false
    wifiSpeed: string | null = null
    kitchen: boolean = false
    laundry: boolean = false
    terrace: boolean = false
    gym: boolean = false
    dogsAllowed: boolean = false

    city: string | null = null
    district: string | null = null
    country: string | null = null
    post: string | null = null
    streetNr: string | null = null
    geoLoc = {}

    // host

    hostName: string | null = null
    hostAbout: string | null = null
    hostKey: string | null = null
    hostEmail: string | null = null

    static toObject(data: ListingData): {} {

        const mappped = new Map(Object.entries(data));

        return Object.fromEntries(mappped)
    }

    static toListing(doc: DocumentData, listingId: string): ListingData | null {

        if (doc === undefined || doc === null) {
            return null
        }

        const data = doc as ListingData
        data.key = listingId

        return data
    }

    static getRooms(listingData: ListingData, publishedOnly: boolean) {
        var rooms: RoomData[] = []
        for (let i = 0; i < listingData.rooms.length; i++) {
            const roomData = JSON.parse(listingData.rooms[i])
            if ((publishedOnly && roomData.publish) || !publishedOnly) {
                rooms.push(roomData)
            }
        }

        return rooms
    }

    static allFilled(listingData: ListingData): boolean {
        return listingData.streetNr != null && listingData.post != null && listingData.country != null && listingData.city != null && listingData.surfSpots != null
            && listingData.locationDesc != null && listingData.apartmentDesc != null && listingData.maxGuests > 0 && listingData.maxRooms > 0
            && listingData.aboutOffer != null && listingData.name != null
            &&
            listingData.streetNr.length > 0 && listingData.post.length > 0 && listingData.country.length > 0 && listingData.city.length > 0 && listingData.surfSpots.length > 0
            && listingData.locationDesc.length > 0 && listingData.apartmentDesc.length > 0
            && listingData.aboutOffer.length > 0 && listingData.name.length > 0
    }

}

