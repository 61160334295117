<div class="vstack">
    <app-header [showBack]=false></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">

        <div class="vstack dimval" style="--h: 100%;">
            <div class="spacer-l"></div>
            <h3 class="superbold accent-1">{{'welcome-userdata-headline-1' | translate}}</h3>
            <div class="spacer-s"></div>
            <h1 class="superbold accent-2">{{'welcome-userdata-headline-2' | translate}}</h1>

            <div class="spacer"></div>
            <div class="welcome">
                <div class="zstack">
                    <div>
                        <img src="../../../assets/logo.png">
                    </div>
                    <h1 class="hand welcome-text">{{'app-name' | translate}}</h1>
                </div>
            </div>
            <div class="spacer"></div>

            <div class="spacer-xxl"></div>
            <div class="hstack">
                <div class="spacer"></div>
                <a [routerLink]="['/search']">
                    <button mat-flat-button color="primary">
                        <span>{{'welcome-find' | translate}}</span>
                    </button>
                </a>
                <a *ngIf="globals.user != null" [routerLink]="['/add']">
                    <button mat-flat-button color="primary">
                        <span>{{'welcome-offer' | translate}}</span>
                    </button>
                </a>
                <a *ngIf="globals.user == null" [routerLink]="['/addinfo']">
                    <button mat-flat-button color="primary">
                        <span>{{'welcome-offer' | translate}}</span>
                    </button>
                </a>
            </div>
            <div class="spacer-xxxxl"></div>
            <div class="hstack">
                <div class="spacer"></div>
                <div class="vstack right-text">
                    <h4 class="accent-2 superbold">{{'welcome-contact' | translate}}</h4>
                    <h6>{{'welcome-contact-sub' | translate}}</h6>
                    <div class="spacer-l"></div>
                    <div class="hstack">
                        <div class="spacer"></div>
                        <a target="_blank"
                            href="https://www.instagram.com/surfrizz_com/profilecard/?igsh=dnI0ZWtueW43cjk=">
                            <img class="social-media-icon" src="../../../assets/instagram_icon.png">
                        </a>
                        <div class="spacer-xs"></div>
                        <a href="mailto: hello@surfrizz.com">
                            <img class="social-media-icon" src="../../../assets/mail_icon.png">
                        </a>
                    </div>
                </div>
            </div>
            <div class="spacer-xxl"></div>
            <div class="spacer-xxl"></div>
            <div class="hstack">
                <div class="spacer"></div>
                <div class="hint sec-color-light">
                    <a target="_blank" [routerLink]="['/terms']">{{'terms' | translate}}</a> - <a target="_blank"
                        [routerLink]="['/privacy']">{{'data-protection' | translate}}</a>
                </div>
                <div class="spacer"></div>
            </div>
        </div>
    </div>
</div>