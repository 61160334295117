import { Component } from '@angular/core';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { LoginStateFieldComponent } from "../login-state-field/login-state-field.component";
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Globals } from '../../../global';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  imports: [PipesModule, LoginStateFieldComponent, LanguageButtonComponent, RouterModule, CommonModule]
})
export class FooterComponent {

  constructor(public globals: Globals) { }

  getTotalBadgeCount(): number {
    let count = 0
    if (this.globals.notification.value && this.globals.notification.value.countMap) {
      for (const [key, value] of Object.entries(this.globals.notification.value.countMap)) {
        count += value
      }
    }

    return count
  }
}
