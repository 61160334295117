<div class="vstack">
    <app-header [showBack]=false></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">

        <div class="vstack">

            <!-- #1 Login buttons -->
            <div [hidden]="loginOrRegister !== null">

                <div class="spacer-xxl"></div>
                <h3 class="bold">{{'pwauth-headline' | translate}}</h3>
                <div class="spacer-m"></div>
                <p>{{'pwauth-subheadline' | translate}}</p>
                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>

                <div class="hstack">
                    <div class="spacer"></div>
                    <button mat-flat-button color="primary" (click)="chooseLogin()">
                        <span>{{'pwauth-login' | translate}}</span>
                    </button>
                    <button mat-flat-button color="primary" (click)="chooseRegister()">
                        <span>{{'pwauth-register' | translate}}</span>
                    </button>
                </div>
            </div>

            <!-- #2 Login chosen -->
            <div [hidden]="loginOrRegister === null || emailSent === true || loginOrRegister !== loginPWDKey">
                <div class="vstack">
                    <div class="spacer-l"></div>
                    <div class="hstack">
                        <p class="nav-icon material-symbols-outlined" (click)="resetLoginRegister()">
                            navigate_before
                        </p>
                        <h3>{{'pwauth-login' | translate}}</h3>
                    </div>

                    <div class="spacer-xxl"></div>
                    <p>
                        {{'pwauth-login-title' | translate}}
                    </p>
                    <div class="spacer-xxl"></div>
                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <input matInput placeholder="{{ 'email' | translate }}" [(ngModel)]='email'>
                    </mat-form-field>


                    <div class="spacer-xxl"></div>

                    <div class="hstack">
                        <div class="spacer"></div>
                        <button [hidden]="loginOrRegister !== loginPWDKey" [disabled]="!this.emailValid()"
                            mat-flat-button color="primary" (click)="sendLoginEmail()">
                            <span>{{'pwauth-login' | translate}}</span>
                        </button>
                    </div>
                </div>

            </div>


            <!-- #2 Register chosen -->
            <div [hidden]="loginOrRegister === null || emailSent === true || loginOrRegister === loginPWDKey">

                <div class="vstack">
                    <div class="spacer-l"></div>
                    <div class="hstack">
                        <p class="nav-icon material-symbols-outlined" (click)="resetLoginRegister()">
                            navigate_before
                        </p>
                        <h3>{{'pwauth-register' | translate}}</h3>
                    </div>

                    <div class="spacer-xxl"></div>
                    <div class="spacer-xxl"></div>


                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <input type="email" matInput placeholder="{{ 'email' | translate }}" [(ngModel)]='email'>
                    </mat-form-field>

                    <div class="hstack">

                        <mat-checkbox type="checkbox" [(ngModel)]="isConditionsAccepted" class=""
                            color="primary"></mat-checkbox>
                        <div class="spacer-m"></div>
                        <div class="vstack">
                            {{ 'pwauth-request-order-accept' | translate }}<br>
                            <div class="spacer-m"></div>
                            <h6 class="">
                                <a target="_blank" [routerLink]="['/terms']">{{ 'terms' | translate }}</a> - <a
                                    target="_blank" [routerLink]="['/privacy']">{{ 'data-protection' | translate }}</a>
                            </h6>
                        </div>
                    </div>
                    <div class="spacer-xxl"></div>

                    <div class="hstack">
                        <div class="spacer"></div>
                        <button [disabled]="!isConditionsAccepted || !this.emailValid()" mat-flat-button color="primary"
                            (click)="register()">
                            <span>{{'pwauth-register' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- #4 EMAIL sent -->
            <div [hidden]="loginOrRegister === null && emailSent === false" class="vstack">
                <div class="spacer-xxl"></div>
                <div [hidden]="emailSent === false">
                    <h3>{{ 'pwauth-email-sent' | translate }} -> {{email}}</h3>
                </div>
            </div>

        </div>
    </div>
</div>