import * as _ from 'lodash';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';

import { environment } from '../../../../rooms/src/environments/environment';
import { Globals } from '../global';
import { dictionary } from './dictionary';


@Injectable({
  providedIn: 'root',
})
export class TranslateService {

  private _dictionary: any;
  private _locale!: string;

  constructor(
    @Inject(LOCALE_ID) private _localeId: string,
    private globals: Globals
  ) {
    // Keys in dictionary convert to lowercase. Just in case.
    this._dictionary = _.mapKeys(dictionary, (v, k) => k.toLowerCase());
  }

  /**
   * Gets app language.
   */
  public get locale() {

    if (this.globals.language) {
      return this.globals.language
    }

    if (this._locale) {
      return this._locale;
    }

    if (typeof window !== 'undefined') {
      if ('locale' in localStorage) {
        this._locale = localStorage.getItem('locale')!;
        return this._locale;
      }
    }

    if (this._dictionary['locales'][this._localeId]) {
      this._locale = this._localeId;
      return this._locale;
    }

    this._locale = environment.defaultLocale;
    return this._locale;
  }

  /**
   * Set app language.
   */
  public set locale(value: string) {
    this._locale = value;
    if (typeof window !== 'undefined') {
      localStorage.setItem('locale', this._locale);
    }
  }

  /**
   * Returns word or phrase by defautl locale.
   * @param {{string}} word Key for word or phrase from dictionary.
   * @returns {{string}} Translated word or phrase.
   */
  public translate(word: string): string {

    if (!word) {
      console.error('Key for translate are required.');
      return '';
    }

    word = word.trim().toLowerCase();

    if (!this._dictionary[word]) {
      console.error(`Key "${word}" was not found in dictionary.`);
      return '';
    }

    return this._dictionary[word][this.locale];
  }
}
