import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { LocationData } from '../../../models/location';
import { SearchData } from '../../../models/search';
import { LocationOverviewComponent } from '../../shared/location-overview/location-overview.component';
import { Globals } from '../../../global';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-filter-boards',
  standalone: true,
  templateUrl: './filter-boards.component.html',
  styleUrl: './filter-boards.component.scss',
  imports: [
    CommonModule,
    AngularMaterialModule,
    PipesModule,
    FormsModule,
  ]
})
export class FilterBoardsComponent implements OnInit {

  @Output() closePanel = new EventEmitter<void>();
  @Input() searchData: SearchData = new SearchData();
  @Input() onSearch: ((searchData: SearchData) => void) | null = null;

  constructor(
    private overlay: Overlay,
    public globals: Globals,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('FilterBoards')
    analytics.logEvent("Open-FilterBoards")
  }

  ngOnInit(): void {

  }

  search() {
    if (!this.searchData || !this.onSearch) {
      return
    }

    this.onSearch(this.searchData)
    this.close()
  }

  close() {
    this.closePanel.emit();
  }

  openLocationOverlay() {

    let locationOverlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const filePreviewPortal = new ComponentPortal(LocationOverviewComponent);

    const ref = locationOverlayRef.attach(filePreviewPortal);
    ref.instance.locationPanel.subscribe(() => {
      locationOverlayRef.detach()
    });
    ref.instance.onSearch = (result: LocationData) => {
      if (this.searchData) {
        this.searchData.location = result
      }

      locationOverlayRef.detach();
    }

    locationOverlayRef.backdropClick().subscribe(() => {
      locationOverlayRef.detach();
    });
  }
}