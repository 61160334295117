import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class DateHelper {

    public daysSince1970(timestamp: number): number {
        return Math.ceil(timestamp / (24 * 60 * 60 * 1000));
    }

    public daysDifference(timestampStart: number, timestampEnd: number) {
        var differenceTime = timestampEnd - timestampStart;
        return (differenceTime / (1000 * 3600 * 24));
    }
}