<div class="vstack">
    <app-header (buttonClick)="onActionButton()"></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">
        <div *ngIf="board" class="vstack">

            <!--Gallery-->
            <div class="spacer-s"></div>
            <div *ngIf="board?.photos" class="hstack accent-3-verylight maval" style="--margin: 0 0 16px 0;">
                <div class="dimval" [ngStyle]="{'--w': globals.framewidth/2 - 20 + 'px', '--h': '300px'}">
                    <div class="zstack" (click)="openOverlay(0)">
                        <div class="imgval radiusval" style="--h: 300px;--w: 100%; --radius: 8px 0 0 8px;">
                            <img [src]=photoUrl(0) alt="">
                        </div>
                        <div class="vstack dimval" style="--h: 100%; --w: 100%;">
                            <div class="spacer"></div>
                            <p class="tag-elements unavailable"
                                *ngIf="board.publish == false && board.availability != Availability.deleted">
                                {{
                                'ab-not-published' | translate }}</p>
                            <p class="tag-elements unavailable"
                                *ngIf="board.availability == Availability.sold || board.availability == Availability.deleted">
                                {{
                                board.availability | available }}</p>
                            <p class="tag-elements reserved" *ngIf="board.availability == Availability.reserved">{{
                                board.availability | available }}</p>
                            <div class="spacer"
                                *ngIf="board.availability == Availability.sold || board.availability == Availability.reserved || board.publish == false">
                            </div>
                            <div
                                *ngIf="board.availability == Availability.availableFrom && (board.availableFrom ?? 0) > Date.now()">
                                <p class="tag-elements-date"> {{ 'bl-from' | translate}} {{
                                    board.availableFrom | date }} <span *ngIf="board.availableTo"> - {{
                                        board.availableTo | date }}</span> </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vstack">
                    <div class="imgval radiusval" style="--radius: 0 8px 0 0;"
                        [ngStyle]="{'--w': globals.framewidth/2 - 20 + 'px', '--h': '146px'}" (click)="openOverlay(1)">
                        <img *ngIf="photoUrl(1)" [src]=photoUrl(1) alt="">
                        <img *ngIf="!photoUrl(1)"
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            class="accent-1-verylight" alt="">
                    </div>
                    <p class="spacer-s"></p>
                    <div class="imgval radiusval" style="--radius: 0 0 8px 0;"
                        [ngStyle]="{'--w': globals.framewidth/2 - 20 + 'px', '--h': '146px'}" (click)="openOverlay(2)">
                        <img *ngIf="photoUrl(2)" [src]=photoUrl(2) alt="">
                        <img *ngIf="!photoUrl(2)"
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            class="accent-2-verylight" alt="">
                    </div>
                </div>
            </div>
            <!--Board-->
            <div class="hstack">
                <div class="vstack">
                    <p class="spacer-l"></p>
                    <h3 class="superbold accent-1" (click)="onActionButton()">{{ board.title }}</h3>
                </div>

                <div class="spacer"></div>
                <button *ngIf="!disableActionButton()" mat-flat-button color="primary" (click)="onActionButton()">
                    <span>{{actionName}}</span>
                </button>
            </div>
            <div class="spacer-s"></div>
            <h2 class="superbold accent-2">{{ board.subtitle }}</h2>
            <div class="spacer-l"></div>
            <p class="line-breaks">{{ board.aboutOffer }}</p>
            <div class="spacer-xxl"></div>
            <h5 class="bold">Details</h5>

            <div class="enumeration">
                <span class="material-symbols-outlined">schedule</span>
                <span *ngIf="board.isNew">{{ 'ab-condition-new' | translate }}</span>
                <span *ngIf="!board.isNew">{{ 'ab-condition-used' | translate}}</span>
            </div>
            <div class="enumeration">
                <span class="material-symbols-outlined">euro</span>
                <span class="">{{board.price}}</span>
            </div>
            <div class="enumeration">
                <span class="material-symbols-outlined">glass_cup</span>
                <span class="">{{board.volume}} Liter</span>
            </div>
            <h3 class="hand prim-color enumeration">
                <span class="material-symbols-outlined">bia</span>
                <span class="">{{board.length | cmToFeetInch}}</span>
                <span *ngIf="board.width"> x {{ board.width | cmToInch }}</span>
                <span *ngIf="board.thick"> x {{ board.thick | cmToInch }}</span>
            </h3>
            <div class="enumeration">
                <span class="material-symbols-outlined">location_on</span>
                <span class="">{{board.location}}</span>
            </div>

            <!--Owner-->
            <div class="spacer-xxl"></div>
            <div class="divider"></div>
            <div class="spacer-xxl"></div>
            <div class="vstack">

                <a [routerLink]="['/lister']" [queryParams]="{bkey: board.key}">
                    <div class="hstack">

                        <div class="vstack">
                            <h4 class="superbold">{{'bd-from-title' | translate }}</h4>
                            <div class="spacer-l"></div>
                            <h3 class="bold accent-1">{{ board.ownerName }}</h3>
                            <div class="spacer-xs"></div>
                            <p class="line-breaks">
                                {{ board.ownerAbout }}
                            </p>
                        </div>
                        <div class="spacer"></div>
                        <div *ngIf="board.ownerImage" class="imgval radiusval padval"
                            style="--h: 100px;--w: 100px; --radius: 50px;--padding: 0 8px 0;">
                            <img [src]=board.ownerImage alt="">
                        </div>
                    </div>
                </a>
                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>
            </div>
        </div>
    </div>
</div>