import { Injectable } from "@angular/core";
import firebase from "firebase/compat/app";
import { MessageData } from "../models/message";
import { FiresoreAdapter } from "../../../../../src/app/services/firestoreadapter.service";
import { AngularFireFunctions } from '@angular/fire/compat/functions';


@Injectable({
  providedIn: 'root'
})
export class MessageDataService {

  constructor(
    private db: FiresoreAdapter,
    private fns: AngularFireFunctions,
  ) { }


  public sendMessage(
    message: string,
    contactKey: string,
    boardKey: string,
    receiverKey: string,
    receiverName: string,
    receiverMail: string,
    userKey: string,
    userName: string,
    userMail: string): Promise<any | null> {
    console.log(message, contactKey, userKey)
    if (message.length <= 0 || contactKey == null || userKey == null) {
      return new Promise(async (result) => {
        result(null)
      })
    }

    let messageObject = new MessageData()
    messageObject.message = message
    messageObject.senderKey = userKey
    messageObject.senderName = userName

    messageObject.senderMail = userMail
    messageObject.receiverKey = receiverKey
    messageObject.receiverName = receiverName
    messageObject.receiverMail = receiverMail

    messageObject.contactKey = contactKey
    messageObject.boardKey = boardKey
    messageObject.date = firebase.firestore.FieldValue.serverTimestamp()

    console.log(MessageData.toObject(messageObject))

    return new Promise(async (result) => {

      try {
        var ref = this.db.db.collection('/board').doc(boardKey).collection('/contact').doc(contactKey).collection('message')
        await ref.add(MessageData.toObject(messageObject))
        result(messageObject)
      } catch (error) {
        console.error(error);
        result(null)
      }
    })
  }

  public getMessages(
    contactKey: string,
    boardKey: string
  ): Promise<MessageData[] | null> {
    if (contactKey == null || boardKey == null) {
      return new Promise(async (result) => {
        result(null)
      })
    }
    return new Promise(async (result) => {

      var ref = this.db.db.collection('/board').doc(boardKey).collection('/contact').doc(contactKey).collection('message').ref.limit(100).orderBy('date', 'asc')

      try {
        var queryResult = await ref.get()
        const resultData = queryResult?.docs.map(d =>
          MessageData.toMessage(d.data()!, d.id)!
        );
        result(resultData ?? []);

      } catch (error) {
        console.error(error);
        result(null)
      }
    });
  }
}
