
import { Injectable } from '@angular/core';
import { Businessdata } from '../model/business/business';
import { FiresoreAdapter } from '../../../../../src/app/services/firestoreadapter.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessdataService {

  constructor(
    private _db: FiresoreAdapter
  ) { }


  public searchBusiness(userId: string): Promise<(Businessdata | null)> {
    return new Promise(async (result) => {

      this._db.db.collection('/business').ref.where("userId", "==", userId).limit(1).get()
        .then((queryResult) => {
          const resultData = queryResult.docs.map(d => Businessdata.toBusiness(d.data()!, d.id));

          if (resultData.length > 0) {
            result(resultData[0]);
          } else {
            result(null);
          }
        })
        .catch((error) => {
          console.error(error);
        })

    });
  }

  public getBusiness(businessId: string): Promise<any> {

    return new Promise(async (result) => {

      this._db.db.collection('/business').doc(businessId).get().toPromise()
        .then((queryResult) => {
          result(queryResult?.data());
        })
        .catch((error) => {
          console.error(error);
        })
    });
  }

  public addBusiness(businessId: string | null, business: Businessdata): Promise<string | null> {

    business.key = businessId;

    return new Promise(async (result) => {

      if (business === null || business === undefined) {
        result(null);
        return
      }

      this._db.db.collection('/business').doc(businessId!).set(Businessdata.toObject(business))
        .then((docRef) => {
          result(businessId);
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    });
  }

  public saveBusiness(businessId: string | null, business: Businessdata): Promise<string | null> {

    business.key = businessId;

    console.error(businessId);

    return new Promise(async (result) => {

      if (business === null || business === undefined) {
        result(null);
        return
      }

      if (businessId) {
        console.log(Businessdata.toObject(business))
        this._db.db.collection('/business').doc(businessId).update(Businessdata.toObject(business))
          .then(() => {
            result(businessId);
          })
          .catch((error) => {
            result(null);
            console.error(error);
          })
      } else {
        this._db.db.collection('/business').add(Businessdata.toObject(business))
          .then((docRef) => {
            result(docRef.id);
          })
          .catch((error) => {
            result(null);
            console.error(error);
          })
      }
    });
  }
}
