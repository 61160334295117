import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roundCm'
})
export class RoundCm implements PipeTransform {

    transform(value: number): string {
        if (!value) return '';

        const rounded = Math.round(value);

        return `${rounded}`
    }

}