<app-toolbar></app-toolbar>
<div id="business-house-data" class="content-box">
    <div class="row justify-content-center">
        <div class="content-m">
            <a [routerLink]="['/dashboard']">
                <span class="nav-icon material-symbols-outlined">
                    navigate_before
                </span>
                <span class="nav-title">{{ 'dashboard' | translate }}</span>
            </a>
            <h3 class="mt-5">{{'house-title' | translate}}</h3>
            <p>
                {{'house-desc' | translate}}
            </p>
        </div>
    </div>
    <div class="seperate-m"></div>
    <div class="row justify-content-center">
        <div class="content-m">
            <h5 class="">{{'house-title-photos' | translate}}</h5>
            <div class="my-3">
                <img *ngIf="url1" src='{{url1}}' alt='businessimage1'>
                <img *ngIf="url2" src='{{url2}}' alt='businessimage2'>
                <img *ngIf="url3" src='{{url3}}' alt='businessimage3'>
                <img *ngIf="url4" src='{{url4}}' alt='businessimage4'>
            </div>
            <div class="img-upload">
                <p class="mt-1">
                    {{'house-title-photo-1' | translate}}<br>
                    <label for="file1">{{'choose' | translate}}</label><input class="inputfile" type="file" name="file1"
                        id="file1" (change)="selectFile($event, 1)">
                </p>
                <p class="mt-1">
                    {{'house-title-photo-2' | translate}}<br>
                    <label for="file2">{{'choose' | translate}}</label><input class="inputfile" type="file" name="file2"
                        id="file2" (change)="selectFile($event, 2)">
                </p>
                <p class="mt-1">
                    {{'house-title-photo-3' | translate}}<br>
                    <label for="file3">{{'choose' | translate}}</label><input class="inputfile" type="file" name="file3"
                        id="file3" (change)="selectFile($event, 3)">
                </p>
                <p class="mt-1">
                    {{'house-title-photo-4' | translate}}<br>
                    <label for="file4">{{'choose' | translate}}</label><input class="inputfile" type="file" name="file4"
                        id="file4" (change)="selectFile($event, 4)">
                </p>
            </div>
            <p class="">
                <button class="right" [disabled]="!newPhotoUploaded()" mat-flat-button color="primary"
                    (click)="saveImages()">
                    <span>{{'save-pics' | translate}}</span>
                </button>
            </p>
        </div>
    </div>
    <div class="seperate-m"></div>
    <div class="row justify-content-center">
        <div class="content-m">
            <h5 class="">{{'house-detail-title' | translate}}</h5>
            <div *ngIf="registerForm != undefined && listingData">

                <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                    <mat-label>{{'house-detail-values-maxrooms' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.maxRooms" required>
                        <div *ngFor="let i of [].constructor(20); let index = index">
                            <mat-option [value]="index + 1">{{ (index + 1) }}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                    <mat-label>{{'house-detail-values-maxGuests' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.maxGuests" required>
                        <div *ngFor="let i of [].constructor(25); let index = index">
                            <mat-option [value]="(index + 1) * 2">{{ (index + 1) * 2 }}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                    <mat-label>{{'house-detail-values-wifiSpeed' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.wifiSpeed" required>
                        <div *ngFor="let i of [].constructor(100); let index = index">
                            <mat-option [value]="(index) * 10">{{ (index) * 10 }} mbit</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>


                <form [formGroup]="registerForm" novalidate>

                    <mat-form-field appearance="fill" color="primary" class="form-field-text">
                        <mat-label>{{'property-name' | translate}}</mat-label>
                        <input maxlength="30" matInput formControlName="name" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="form-field-text">
                        <mat-label>{{ 'about-property' | translate }}</mat-label>
                        <textarea matInput maxlength="800" rows="8" formControlName="aboutOffer" required></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="form-field-text">
                        <mat-label>{{'house-detail-values-apartmentDesc' | translate}}</mat-label>
                        <textarea maxlength="800" matInput rows="8" formControlName="apartmentDesc" required></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="form-field-text">
                        <mat-label>{{'house-detail-values-locationDesc' | translate}}</mat-label>
                        <textarea maxlength="800" matInput rows="8" formControlName="locationDesc" required></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="form-field-text">
                        <mat-label>{{'house-detail-values-surfSpots' | translate}}</mat-label>
                        <textarea maxlength="800" matInput rows="8" formControlName="surfSpots" required></textarea>
                    </mat-form-field>

                    <h5 class="mt-5">{{'house-detail-values-Location-title' | translate}}</h5>

                    <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                        <mat-label>{{'street' | translate}}</mat-label>
                        <input maxlength="150" matInput formControlName="streetNr" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                        <mat-label>{{'city' | translate}}</mat-label>
                        <input maxlength="20" matInput formControlName="city" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                        <mat-label>{{'post' | translate}}</mat-label>
                        <input maxlength="20" matInput formControlName="post" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                        <mat-label>{{'country' | translate}}</mat-label>
                        <input maxlength="20" matInput formControlName="country" required>
                    </mat-form-field>

                    <!--

                         <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                        <mat-label>{{'district' | translate}}</mat-label>
                        <input maxlength="20" matInput formControlName="district">
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                        <mat-label>{{'geoLocation' | translate}}</mat-label>
                        <input maxlength="150" matInput formControlName="geoLoc">
                    </mat-form-field>
-->
                </form>

                <h5 class="mt-5">{{'house-detail-values-included-title' | translate}}</h5>

                <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                    <mat-label>{{'house-detail-values-included-wifi' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.wifi" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                    <mat-label>{{'house-detail-values-included-kitchen' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.kitchen" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                    <mat-label>{{'house-detail-values-included-laundry' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.laundry" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                    <mat-label>{{'house-detail-values-included-terrace' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.terrace" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                    <mat-label>{{'house-detail-values-included-gym' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.gym" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                    <mat-label>{{'house-detail-values-included-dogsAllowed' | translate}}</mat-label>
                    <mat-select [(ngModel)]="listingData.dogsAllowed" required>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                    <mat-label>{{ 'listing-included-breakfast' | translate }}</mat-label>
                    <mat-select [(ngModel)]="listingData.breakfast" required>
                        <mat-option [value]="true">{{ 'yes' | translate }}</mat-option>
                        <mat-option [value]="false">{{ 'no' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                    <mat-label>{{ 'listing-included-surfEquipment' | translate }}</mat-label>
                    <mat-select [(ngModel)]="listingData.surfEquipment" required>
                        <mat-option [value]="true">{{ 'yes' | translate }}</mat-option>
                        <mat-option [value]="false">{{ 'no' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                    <mat-label>{{ 'listing-included-yoga' | translate }}</mat-label>
                    <mat-select [(ngModel)]="listingData.yoga" required>
                        <mat-option [value]="true">{{ 'yes' | translate }}</mat-option>
                        <mat-option [value]="false">{{ 'no' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <h5 class="mt-5">{{ 'listing-included-title' | translate }}</h5>

                <mat-form-field appearance="fill" color="primary" class="form-field-cst-first">
                    <mat-label>{{ 'listing-values-discount1Week' | translate }}</mat-label>
                    <mat-select [(ngModel)]="listingData.discount1Week" required>
                        <div *ngFor="let i of [].constructor(10); let index = index">
                            <mat-option [value]="(index) * 5">{{ (index) * 5 }} %</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" color="primary" class="form-field-cst-second">
                    <mat-label>{{ 'listing-values-discount4Week' | translate }}</mat-label>
                    <mat-select [(ngModel)]="listingData.discount4Week" required>
                        <div *ngFor="let i of [].constructor(10); let index = index">
                            <mat-option [value]="(index) * 5">{{ (index) * 5 }} %</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>

            </div>
        </div>
        <div class="row justify-content-center">
            <div class="content-m">
                <button class="right" mat-flat-button color="primary" (click)="saveAccount()">
                    <span>{{'save' | translate}}</span>
                </button>

                <!-- <button mat-flat-button color="primary" (click)="deleteListing()">
                    <span>{{ 'delete' | translate }}</span>
                </button>
                -->
                <button *ngIf="listingData && listingData.publish" mat-flat-button color="primary"
                    (click)="togglePublish()">
                    <span>{{ 'listing-included-unpublish' | translate }}</span>
                </button>
                <button [disabled]="!allFilled()" *ngIf="listingData && !listingData.publish" mat-flat-button
                    color="primary" (click)="togglePublish()">
                    <span>{{ 'listing-included-publish' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>