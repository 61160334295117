import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-protection',
  standalone: true,
  imports: [],
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss'],
})
export class DataProtectionComponent implements OnInit {

  constructor() { };

  ngOnInit(): void {
  };

}
