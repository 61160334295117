<div class="">

    <div id="listing-top">
        <div class="logo desktop">
            <a [routerLink]="['/']">
                <img src="../../assets/logo.png">
                <h5>{{ 'landingpage-welcome-logo' | translate}}</h5>
            </a>
        </div>
        <mat-toolbar class="toolbar">
            <span class="toolbar-spacer desktop"></span>

            <span class="mobile mobile-logo">
                <a [routerLink]="['/']">
                    <img src="../../assets/logo.png">
                </a>
            </span>
            <a routerLink="/dashboard/business-data">
                <span class="desktop">{{ 'menu-business' | translate}}</span>
                <span class="material-symbols-outlined mobile">
                    checkbook
                </span>
            </a>
            <a routerLink="/dashboard/user-data">
                <span class="desktop">{{ 'menu-user' | translate}}</span>
                <span class="material-symbols-outlined mobile">
                    person_edit
                </span>
            </a>
            <a routerLink="/dashboard/request">
                <span class="desktop">{{ 'menu-request' | translate}}</span>
                <span class="material-symbols-outlined mobile">
                    comment_bank
                </span>
            </a>
            <span class="toolbar-spacer mobile"></span>
            <app-login-state-field></app-login-state-field>
            <app-language-button></app-language-button>
        </mat-toolbar>
    </div>
</div>