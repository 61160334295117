import { Injectable } from '@angular/core';
import { Geopoint, distanceBetween, geohashQueryBounds } from 'geofire-common';
import { IndexDataType } from '../../../../../src/app/model/helper/IndexDataType';
import { FiresoreAdapter } from '../../../../../src/app/services/firestoreadapter.service';
import { fieldBoardImage } from '../enums/fields';
import { BoardData } from '../models/board';
import { LocationData } from '../models/location';
import { ImageService } from './image.service';
import { firstValueFrom } from 'rxjs';
import firebase from "firebase/compat/app";
import { Availability } from '../models/availability';

@Injectable({
  providedIn: 'root'
})
export class BoarddataService {

  constructor(
    private db: FiresoreAdapter,
    private imageService: ImageService
  ) { }

  public searchBoards(fromLength: number | null, toLength: number | null, fromVol: number | null, toVol: number | null,
    location: LocationData | null, fromPrice: number | null, toPrice: number | null): Promise<any[]> {
    return new Promise(async (result) => {

      try {
        var ref = this.db.db.collection('board').ref.limit(50)
        ref = ref.where("publish", "==", true)

        if (fromVol) {
          ref = ref.where("volume", ">=", fromVol)
        }
        if (toVol) {
          ref = ref.where("volume", "<=", toVol)
        }
        if (fromLength) {
          ref = ref.where("length", ">=", fromLength)
        }
        if (toLength) {
          ref = ref.where("length", "<=", toLength)
        }
        if (fromPrice) {
          ref = ref.where("price", ">=", fromPrice)
        }
        if (toPrice) {
          ref = ref.where("price", "<=", toPrice)
        }

        if (location) {
          ref = ref.orderBy('geohash')
          ref = ref.orderBy('date', 'desc')

          const center: Geopoint = [location?.lat ?? 0, location?.lng ?? 0];
          // 50 * 1000m => 50km
          const radiusInM = 50 * 1000;

          const bounds = geohashQueryBounds(center, radiusInM);
          const promises = [];
          for (const b of bounds) {
            ref = ref.startAt(b[0]).endAt(b[1])
            promises.push((await ref.get()))
          }

          const snapshots = await Promise.all(promises);

          const matchingDocs = [];
          for (const snap of snapshots) {
            for (const doc of snap.docs) {
              const lat = doc.get('lat');
              const lng = doc.get('lng');

              // We have to filter out a few false positives due to GeoHash
              // accuracy, but most will match
              const distanceInKm = distanceBetween([lat, lng], center);
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= radiusInM) {
                matchingDocs.push(BoardData.toBoard(doc.data()!, doc.id));
              }
            }
          }

          result(matchingDocs ?? []);
        } else {
          ref = ref.orderBy('date', 'desc')
          var queryResult = await ref.get()
          const resultData = queryResult?.docs.map(d =>
            BoardData.toBoard(d.data()!, d.id)
          );
          result(resultData ?? []);
        }

      } catch (error) {
        console.log(error)
        result([]);
      }
    });
  }

  public getBoards(userKey: string | null, isOwner: Boolean): Promise<any[]> {
    return new Promise(async (result) => {

      var ref = this.db.db.collection('board').ref.limit(50)
      ref = ref.orderBy('date', 'desc')

      if (userKey) {
        ref = ref.where("ownerKey", "==", userKey)
      }

      if (!isOwner) {
        ref = ref.where("publish", "==", true)
      }

      ref = ref.where("availability", "!=", Availability.deleted)

      try {
        var queryResult = await ref.get()
        const resultData = queryResult?.docs.map(d =>
          BoardData.toBoard(d.data()!, d.id)
        );
        result(resultData ?? []);

      } catch (error) {
        console.error(error);
        result([])
      }
    });
  }

  public getBoard(boardId: string): Promise<BoardData | null> {
    return new Promise(async (result) => {
      firstValueFrom(this.db.db.collection('board').doc(boardId).get())
        .then(async (d) => {
          let board = BoardData.toBoard(d!.data()!, boardId)!
          board.photos = await this.getImageUrls(board)
          result(board);
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    })
  }

  public async getImageUrls(board: BoardData): Promise<IndexDataType[]> {
    return this.imageService.getBoardImageUrls(board)
  }

  public markBoardDeleted(boardData: BoardData): Promise<string | null> {
    boardData.availability = Availability.deleted
    boardData.publish = false
    return this.saveBoard(boardData.key, boardData)
  }

  public deleteBoard(boardId: string): Promise<boolean | null> {

    return new Promise(async (result) => {

      await this.imageService.deleteImages(boardId, fieldBoardImage)

      this.db.db.collection('board').doc(boardId).delete()
        .then(() => {
          result(true);
        })
        .catch((error) => {
          result(false);
          console.error(error);
        })
    })
  }

  public addBoard(boardId: string, board: BoardData): Promise<string | null> {

    board.key = boardId;

    return new Promise(async (result) => {

      if (board === null || board === undefined) {
        result(null);
        return
      }

      console.log(BoardData.toObject(board))
      this.db.db.collection('board').doc(boardId).set(BoardData.toObject(board))
        .then(() => {
          result(boardId);
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    });
  }


  public saveBoard(boardId: string | null, board: BoardData): Promise<string | null> {

    board.key = boardId;
    board.date = firebase.firestore.FieldValue.serverTimestamp()
    if (board.firstPublisDate == null && board.publish) {
      board.firstPublisDate = firebase.firestore.FieldValue.serverTimestamp()
    }

    return new Promise(async (result) => {

      if (board === null || board === undefined) {
        result(null);
        return
      }

      if (boardId) {
        this.db.db.collection('board').doc(boardId).update(BoardData.toObject(board))
          .then(() => {
            result(boardId);
          })
          .catch((error) => {
            result(null);
            console.error(error);
          })
      } else {
        this.db.db.collection('board').add(BoardData.toObject(board))
          .then((docRef) => {
            result(docRef.id);
          })
          .catch((error) => {
            result(null);
            console.error(error);
          })
      }
    });
  }
}
