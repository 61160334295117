import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { DateHelper } from '../../../../../../../src/app/utils/date-helper.service';
import { Day } from '../../../models/day';
import { CalendarCreator } from '../../../services/helper/calendar-creator.service';
import { BoardData } from '../../../models/board';

@Component({
  selector: 'app-calendar',
  standalone: true,
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
  imports: [
    CommonModule,
    AngularMaterialModule,
    PipesModule
  ]
})
export class CalendarComponent implements OnInit {
  @Input() board!: BoardData
  @Input() manageable!: Boolean

  oneDay = 24 * 60 * 60 * 1000;
  oneMonth = this.oneDay * 28
  oneYear = this.oneDay * 365

  public monthDays: Day[] = [];

  public monthNumber!: number;
  public year!: number;

  public weekDaysName: string[] = [];

  constructor(
    public calendarCreator: CalendarCreator,
    private dateHelper: DateHelper
  ) { }

  ngOnInit(): void {
    this.setMonthDays(this.calendarCreator.getCurrentMonth());

    this.weekDaysName.push("Mo");
    this.weekDaysName.push("Tu");
    this.weekDaysName.push("We");
    this.weekDaysName.push("Th");
    this.weekDaysName.push("Fr");
    this.weekDaysName.push("Sa");
    this.weekDaysName.push("Su");

    this.startDay = null
    if (this.board.availableFrom) {
      this.startDay = Day.createDayTS(this.board.availableFrom)
    }
    this.endDay = null //Day.createDayTS(this.board.availableTo)

    if (this.startDay && this.startDay.monthIndex && this.startDay.year) {
      this.monthNumber = this.startDay.monthIndex!
      this.year = this.startDay.year!
      this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year));
    }
  }

  private _startDay: Day | null = null
  get startDay(): Day | null { return this._startDay; }
  set startDay(value: Day | null) {
    this._startDay = value;
    this.board.availableFrom = value?.timestamp ?? null
  }
  private _endDay: Day | null = null
  get endDay(): Day | null { return this._endDay; }
  set endDay(value: Day | null) {
    this._endDay = value;
    this.board.availableTo = value?.timestamp ?? null
  }

  onSelectDay(day: Day): void {

    if (this.isPastDay(day)) {
      return
    }

    if (this.startDay == day || this.endDay == day) {
      this.startDay = null
      this.endDay = null
      return
    }

    this.startDay = day
    this.board.availableFrom = this.startDay.timestamp

    /*if (this.startDay) {

      if (this.endDay) {
        this.startDay = day
        this.endDay = null
      } else {
        if (day.timestamp! < this.startDay.timestamp!) {
          this.startDay = day
        } else {
          this.endDay = day
          this.board.availableFrom = this.startDay.timestamp
          this.board.availableTo = this.endDay.timestamp
        }
      }
    } else {
      this.startDay = day
    }*/
  }

  isPastDay(day: Day): boolean {

    let nowDay = this.dateHelper.daysSince1970(Date.now())
    let checkDay = this.dateHelper.daysSince1970(day.timestamp!)

    if (checkDay < nowDay) {
      return true
    }

    return false
  }

  hasNextMonth(): boolean {
    let today = new Date()
    let lastShownDay = Day.createDay(28, this.monthNumber, this.year)

    if ((today.getTime() + this.oneYear) > lastShownDay.timestamp!) {
      return true
    }
    return false
  }

  hasPreviousMonth(): boolean {
    let today = new Date()

    if (today.getMonth() == this.monthNumber && today.getFullYear() == this.year) {
      return false
    }
    return true
  }

  onNextMonth(): void {

    if (this.hasNextMonth()) {
      this.monthNumber++;

      if (this.monthNumber == 12) {
        this.monthNumber = 0;
        this.year++;
      }

      this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year));
    }
  }

  onPreviousMonth(): void {

    if (this.hasPreviousMonth()) {
      this.monthNumber--;

      if (this.monthNumber < 0) {
        this.monthNumber = 11;
        this.year--;
      }

      this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year));
    }
  }

  private setMonthDays(days: Day[]): void {
    this.monthDays = days;
    this.monthNumber = this.monthDays[0].monthIndex!;
    this.year = this.monthDays[0].year!;
  }
}
