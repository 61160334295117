import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { Globals } from '../../../global';
import { BoardData } from '../../../models/board';
import { MessageData } from '../../../models/message';
import { MessageDataService } from '../../../services/messagedata.service';
import { FooterComponent } from "../../shared/footer/footer.component";
import { HeaderComponent } from "../../shared/header/header.component";
import { LoginStateFieldComponent } from '../../shared/login-state-field/login-state-field.component';
import { ContactData } from '../../../models/contact';
import { NotificationService } from '../../../services/notification.service';
import { NotificationData } from '../../../models/notification';
import { ContactViewModel } from '../../../viewmodels/contact-viewmodel.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-contact-detail',
  standalone: true,
  templateUrl: './contact-detail.component.html',
  styleUrl: './contact-detail.component.scss',
  imports: [PipesModule,
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    LoginStateFieldComponent,
    LanguageButtonComponent, HeaderComponent, FooterComponent, RouterModule]
})
export class ContactDetailComponent implements OnInit, OnDestroy {

  actionName = "refresh"

  static boardKey: string | null = null
  static contactKey: string | null = null
  contact: ContactData | null = null
  board: BoardData | null = null

  constructor(
    private messageService: MessageDataService,
    private spinnerService: SpinnerService,
    public snackbarService: SnackbarService,
    public globals: Globals,
    public viewModel: ContactViewModel,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('ContactDetail')
    analytics.logEvent("Open-ContactDetail")

    this.route.queryParams.subscribe(params => {
      ContactDetailComponent.boardKey = params['bkey'];
      ContactDetailComponent.contactKey = params['ckey'];
    })
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  newMessage: any;
  @ViewChild('messagefield') messageField!: ElementRef;

  public messages: BehaviorSubject<MessageData[] | null> = new BehaviorSubject<MessageData[] | null>(null);

  async onActionButton() {
    if (this.contact?.key && ContactDetailComponent.boardKey && this.globals.user?.key) {
      this.spinnerService.show()
      let messages = await this.messageService.getMessages(this.contact.key, ContactDetailComponent.boardKey)
      if (messages == null) {
        this.spinnerService.hide()
        this.snackbarService.openDefaultErrorSnackBar()
        return
      }
      this.messages.next(messages)
      this.scrollMessagesToBottom()

      this.notificationService.markAsRead(this.globals.user.key, this.contact?.key)

      var that = this
      setTimeout(function () {
        that.spinnerService.hide()
      }, 500);
    }
  }

  ngOnInit(): void {
    this.initData()
  }

  subscription: Subscription | null = null

  async initData() {

    if (!ContactDetailComponent.boardKey) {
      console.error("No keys")
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }

    this.spinnerService.show()

    this.board = await this.viewModel.getBoard(ContactDetailComponent.boardKey)

    if (ContactDetailComponent.contactKey) {
      this.contact = await this.viewModel.getContact(ContactDetailComponent.boardKey, ContactDetailComponent.contactKey)
    } else {
      this.contact = await this.viewModel.findContact(ContactDetailComponent.boardKey)
      ContactDetailComponent.contactKey = this.contact?.key ?? null
    }

    if (this.contact?.key) {
      let messages = await this.messageService.getMessages(this.contact.key, ContactDetailComponent.boardKey)
      if (messages == null) {
        this.spinnerService.hide()
        this.snackbarService.openDefaultErrorSnackBar()
        return
      }
      this.messages.next(messages)
      this.scrollMessagesToBottom()
    }

    this.spinnerService.hide()

    this.subscription = this.globals.notification.subscribe(async (notification: NotificationData | null) => {
      if (notification
        && this.globals.user?.key
        && this.contact?.key
        && notification.countMap) {

        for (const [key, value] of Object.entries(notification.countMap)) {
          if (key == this.contact?.key && value > 0) {
            this.onActionButton()
            return
          }
        }
      }
    }
    );
  }

  public hasDate(message: MessageData) {
    return typeof message.date.toDate === 'function'
  }

  public scrollMessagesToBottom() {

    var that = this
    setTimeout(function () {
      that.messageField.nativeElement.scrollTop = that.messageField.nativeElement.scrollHeight;
    }, 500);
  }

  async sendMessage(message: string) {

    let user = this.globals.user

    if (!user) {
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }

    if (!ContactDetailComponent.boardKey || !this.board) {
      console.error("No keys")
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }

    this.spinnerService.show();

    if (this.contact != null) {

      let requester = ContactData.getRequesterData(this.contact)

      if (user.key == this.contact.ownerKey) {
        // host
        var result = await this.messageService.sendMessage(message, ContactDetailComponent.contactKey ?? this.contact.key!, ContactDetailComponent.boardKey, this.contact.senderKey!, requester?.firstName ?? "xxx", requester?.email ?? "", this.contact.ownerKey!, this.contact.ownerName!, this.contact.ownerMail!)
      } else {
        // requester
        var result = await this.messageService.sendMessage(message, ContactDetailComponent.contactKey ?? this.contact.key!, ContactDetailComponent.boardKey, this.contact.ownerKey!, this.contact.ownerName!, this.contact.ownerMail!, this.contact.senderKey!, requester?.firstName ?? "xxx", requester?.email ?? "")
      }

      if (!result) {
        this.snackbarService.openDefaultErrorSnackBar()
      } else {
        var allMessages = this.messages.value ?? []
        allMessages.push(result)
        this.messages.next(allMessages)
        this.newMessage = ""

      }

      this.spinnerService.hide();
      this.scrollMessagesToBottom()
    } else {
      // very first message needs to create a message request
      var resultValue = await this.viewModel.startContact(user, this.board!, message)
      if (resultValue.success) {
        ContactDetailComponent.contactKey = resultValue.contactKey
        this.newMessage = ""
        this.initData()
      } else {
        this.snackbarService.openDefaultErrorSnackBar()
      }
      this.spinnerService.hide();
      this.scrollMessagesToBottom()
    }
  }
}
