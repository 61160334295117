import { Component } from '@angular/core';
import { AngularMaterialModule } from '../../../angular-material.module';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [AngularMaterialModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  constructor() { }
}
