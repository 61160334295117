<div class="viewport vstack content padval dimval" style="--padding: 16px;"
  [ngStyle]="{'--w': globals.framewidth * 0.92 + 'px', '--h':'100%'}">

  <div class="hstack">
    <h3>{{'lo-headline' | translate}}</h3>
    <div class="spacer"></div>
    <div class="close-button" (click)="close()">
      <span class="material-symbols-outlined">close</span>
    </div>
  </div>

  <div class="zstack dimval" style="--padding: 8px; --h: 67%; --w: 100%">
    <div class="padval scroll-container dimval min-height-300" style="--padding: 8px; --h: 100%; --w: 100%">
      <div *ngFor="let result of resultList">
        <h5 class="padval" style="--padding: 8px 0;" (click)="selectResult(result)">{{ result.name }}</h5>
      </div>
    </div>
    <div *ngIf="resultList.length <= 0 && commitedQuery.length > 0">
      <div class="spacer-xxl"></div>
      <h5>{{'lo-no-location' | translate}}</h5>
    </div>
  </div>


  <div class="spacer"></div>
  <div class="spacer-l"></div>
  <div class="hstack">
    <div class="padval" style="--padding: 8px;">
      <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%; --h: 100px;">
        <mat-label>Location</mat-label>
        <input id="query" #query maxlength="15" matInput (keydown.enter)="onNewSearchQuery(query.value)">
      </mat-form-field>
    </div>
    <div class="spacer"></div>
    <button [disabled]="query.value.length <= 3" mat-flat-button color="primary"
      (click)="onNewSearchQuery(query.value)">
      <span>{{'search' | translate}}</span>
    </button>
  </div>

  <div class="spacer-xxl"></div>
</div>