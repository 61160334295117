import { DocumentData } from "firebase/firestore";

export class NotificationData {

    key: string | null = null
    countMap: Map<string, number> = new Map()

    static toObject(data: NotificationData): {} {

        const mappped = new Map(Object.entries(data));

        return Object.fromEntries(mappped)
    }

    static toNotification(doc: DocumentData, userKey: string): NotificationData | null {

        if (doc === undefined || doc === null) {
            return null
        }

        const data = doc as NotificationData
        data.key = userKey

        return data
    }
}
