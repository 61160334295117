import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DashboardContainer } from '../../../../datacontainer/dashboard-container.service';
import { ListingData } from '../../../../model/business/listing';
import { SnackbarService } from '../../../../../../../../src/app/services/snackbar.service';
import { SpinnerService } from '../../../../../../../../src/app/services/spinner.service';
import { FormHelperService } from '../../../../../../../../src/app/utils/form-helper.service';
import { AngularMaterialModule } from '../../../../../../../../src/app/angular-material.module';
import { CompanyDetailsComponent } from '../../../shared/company-details/company-details.component';
import { ImageService } from '../../../../services/image.service';
import { ToolbarComponent } from '../../../../../../../../src/app/views/general/toolbar/toolbar.component';
import { PipesModule } from "../../../../../../../../src/app/pipes/pipes.module";
import { fieldListingImage } from '../../../../enum/helper/fields';
import { Globals } from '../../../../global';
import { AccountService } from '../../../../services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmHandlerService } from '../../../../../../../../src/app/services/confirm-handler.service';

@Component({
  selector: 'app-listing-data',
  standalone: true,
  templateUrl: './listing-data.component.html',
  styleUrl: './listing-data.component.scss',
  imports: [
    AngularMaterialModule,
    CommonModule,
    CompanyDetailsComponent,
    FormsModule,
    ToolbarComponent,
    PipesModule,
    RouterModule
  ]
})
export class ListingDataComponent implements OnInit {

  url1: any;
  url2: any;
  url3: any;
  url4: any;

  public registerForm: FormGroup | undefined;

  static listingKey: string | null
  public listingData: ListingData | null = null

  constructor(
    private route: ActivatedRoute,
    public snackbarService: SnackbarService,
    private formHelperService: FormHelperService,
    private dashboardContainer: DashboardContainer,
    private spinnerService: SpinnerService,
    private imageService: ImageService,
    private globals: Globals,
    private accountService: AccountService,
    private confirmHandler: ConfirmHandlerService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['lkey']?.length > 0) {
        ListingDataComponent.listingKey = params['lkey'];
      }
    })
  }

  ngOnInit(): void {

    console.log("ListingDataComponent");

    this.initData()
  }


  private async initData() {
    if (!ListingDataComponent.listingKey) {
      console.error("No listingKey")
      return
    }

    const listing = await this.dashboardContainer.getListing(ListingDataComponent.listingKey!)

    if (!listing) {
      console.error("No listing")
      return
    }

    this.listingData = listing

    if (this.listingData) {
      this.imageService.loadImage(this.listingData.key!, '0', fieldListingImage)
        .then((result) => {
          this.url1 = result
        })
        .catch(errorResult => {
          console.error(errorResult);
        });
      this.imageService.loadImage(this.listingData.key!, '1', fieldListingImage)
        .then((result) => {
          this.url2 = result
        })
        .catch(errorResult => {
          console.error(errorResult);
        });
      this.imageService.loadImage(this.listingData.key!, '2', fieldListingImage)
        .then((result) => {
          this.url3 = result
        })
        .catch(errorResult => {
          console.error(errorResult);
        });
      this.imageService.loadImage(this.listingData.key!, '3', fieldListingImage)
        .then((result) => {
          this.url4 = result
        })
        .catch(errorResult => {
          console.error(errorResult);
        });

      this.formInit();
    }
  }

  selectFile(event: any, num: number) { //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.snackbarService.openInvalidImageBar()
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.snackbarService.openInvalidImageBar()
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {

      if (num === 1) {
        ImageService.compressImage(reader.result as string, 1600).then(compressed => {
          this.url1 = compressed;
        })
      } else if (num === 2) {
        ImageService.compressImage(reader.result as string, 1600).then(compressed => {
          this.url2 = compressed;
        })
      } else if (num === 3) {
        ImageService.compressImage(reader.result as string, 1600).then(compressed => {
          this.url3 = compressed;
        })
      } else {
        ImageService.compressImage(reader.result as string, 1600).then(compressed => {
          this.url4 = compressed;
        })
      }
    }
  }

  public newPhotoUploaded() {

    return this.url1?.startsWith('data') || this.url2?.startsWith('data') || this.url3?.startsWith('data') || this.url4?.startsWith('data')
  }

  public async saveAccount() {

    if (!this.registerForm) {
      console.log('form invalid');
      return
    }

    this.formHelperService.markFormGroupTouched(this.registerForm);

    if (this.registerForm.invalid || !this.listingData || !this.globals.user) {
      console.log('form invalid');
      this.spinnerService.hide();
      console.log(this.registerForm.getError);
      this.snackbarService.openFillAllDataBar()
      return;
    }

    this.listingData.aboutOffer = this.registerForm.value.aboutOffer;
    this.listingData.streetNr = this.registerForm.value.streetNr;
    this.listingData.city = this.registerForm.value.city;
    this.listingData.country = this.registerForm.value.country;
    this.listingData.district = this.registerForm.value.district;
    this.listingData.post = this.registerForm.value.post;
    this.listingData.geoLoc = this.registerForm.value.geoLoc;
    this.listingData.name = this.registerForm.value.name;

    this.listingData.apartmentDesc = this.registerForm.value.apartmentDesc;

    this.listingData.locationDesc = this.registerForm.value.locationDesc;
    this.listingData.surfSpots = this.registerForm.value.surfSpots;

    this.listingData.hostAbout = this.globals.user.about
    this.listingData.hostKey = this.globals.user.key
    this.listingData.hostName = this.globals.user.firstName
    this.listingData.hostEmail = this.globals.user.email

    console.log(this.listingData)

    if (!ListingData.allFilled(this.listingData) && this.listingData.publish) {
      this.snackbarService.openFillAllDataBar()
      return;
    }

    this.spinnerService.show();

    try {
      await this.dashboardContainer.saveListing(this.listingData.key!, this.listingData)
      await this.accountService.refreshUserAccount(this.globals.user.key!)

      if (this.newPhotoUploaded()) {
        this.confirmHandler.openDialog(this.dialog, 'save_photo', null, null)
          .then(async userResponse => {
            if (userResponse == true) {
              await this.saveImages()
            }
            this.spinnerService.hide();
            this.snackbarService.openSuccessBar()

            if (this.url1 && this.url2 && this.url3 && this.url4 && this.listingData?.publish) {
              this.router.navigate(['/dashboard']);
            }
          })
      } else {
        this.spinnerService.hide();
        this.snackbarService.openSuccessBar()
      }

    } catch (error) {
      console.error(error);
      this.spinnerService.hide();
      this.snackbarService.openDefaultErrorSnackBar()
    }
  }

  public allFilled(): boolean {
    if (!this.listingData) {
      return false
    }

    if (!(this.url1 && this.url2 && this.url3 && this.url4) || this.newPhotoUploaded()) {
      return false
    }

    return ListingData.allFilled(this.listingData)
  }

  public togglePublish() {
    if (!this.listingData) {
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }
    if (this.allFilled()) {
      var messageKey = 'really_publish'
      if (this.listingData.publish) {
        messageKey = 'really_unpublish'
      }

      this.confirmHandler.openDialog(this.dialog, messageKey, null, null)
        .then(userResponse => {
          if (userResponse == true && this.listingData) {
            this.listingData.publish = !this.listingData.publish
            this.saveAccount()
          }
        })
    } else {
      if (this.listingData.publish) {
        this.listingData.publish = false
        this.saveAccount()
      }
      this.snackbarService.openFillAllDataBar()
    }
  }

  /*public deleteListing() {
    // TODO ADD confirmation
    this.dashboardContainer.deleteListing(ListingDataComponent.listingKey!)
      .then(() => {
        history.back()
      })
      .catch((error) => {
        this.snackbarService.openDefaultErrorSnackBar()
        console.error(error);
      })
  }
  */

  async saveImages() {
    if (!this.listingData) {
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }
    this.spinnerService.show()
    if (this.url1 && this.url1.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url1, '0');
      var result = await this.imageService.saveImage(this.listingData.key!, file, '0', fieldListingImage)
      this.url1 = result?.['data']
    }
    if (this.url2 && this.url2.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url2, '1');
      var result = await this.imageService.saveImage(this.listingData.key!, file, '1', fieldListingImage)
      this.url2 = result?.['data']
    }
    if (this.url3 && this.url3.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url3, '2');
      var result = await this.imageService.saveImage(this.listingData.key!, file, '2', fieldListingImage)
      this.url3 = result?.['data']
    }
    if (this.url4 && this.url4.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url4, '3');
      var result = await this.imageService.saveImage(this.listingData.key!, file, '3', fieldListingImage)
      this.url4 = result?.['data']
    }

    this.spinnerService.hide()

    // TODO error handling
    this.snackbarService.openSuccessBar()
  }

  private formInit() {
    if (!this.listingData) {
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }
    this.registerForm = new FormGroup({
      name: new FormControl(this.listingData.name || '',
        Validators.required),
      aboutOffer: new FormControl(this.listingData.aboutOffer || '',
        Validators.required),
      streetNr: new FormControl(this.listingData.streetNr || '',
        Validators.required),
      city: new FormControl(this.listingData.city || '',
        Validators.required),
      country: new FormControl(this.listingData.country || '',
        Validators.required),
      district: new FormControl(this.listingData.district || ''),
      post: new FormControl(this.listingData.post || '',
        Validators.required),
      geoLoc: new FormControl(this.listingData.geoLoc || ''),

      surfSpots: new FormControl(this.listingData.surfSpots || '',
        Validators.required),

      locationDesc: new FormControl(this.listingData.locationDesc || '',
        Validators.required),
      apartmentDesc: new FormControl(this.listingData.apartmentDesc || '',
        Validators.required)
    });
  }
}