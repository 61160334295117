import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '../../shared/footer/footer.component';
import { HeaderComponent } from '../../shared/header/header.component';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from '../../../../../../../src/app/pipes/pipes.module';

@Component({
  selector: 'app-add-board-info',
  standalone: true,
  templateUrl: './add-board-info.component.html',
  styleUrl: './add-board-info.component.scss',
  imports: [
    HeaderComponent,
    FooterComponent,
    RouterModule,
    AngularMaterialModule,
    PipesModule
  ]
})
export class AddBoardInfoComponent {

}
