import { Geohash, geohashForLocation } from 'geofire-common';

export class LocationData {

    name: string | null = null
    lng: number | null = null
    lat: number | null = null
    geohash: Geohash | null = null

    constructor(name: string, lng: string, lat: string) {
        this.name = name
        this.lat = Number(lat)
        this.lng = Number(lng)

        this.geohash = geohashForLocation([this.lat, this.lng]);
    }
}
