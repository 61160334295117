import { DocumentData } from "firebase/firestore"
import { IndexDataType } from "../../../../../src/app/model/helper/IndexDataType"
import { Geohash } from "geofire-common"
import { Availability } from "./availability"

export class BoardData {

    key: string | null = null

    subtitle: string | null = null
    title: string | null = null

    length: number = 0
    thick: number | null = null
    width: number | null = null
    aboutOffer: string | null = null
    location: string | null = null
    lng: number | null = null
    lat: number | null = null
    geohash: Geohash | null = null
    volume: number | null = null
    price: number = 0

    date: any | null = null

    virtualBoard = false

    ownerKey: string | null = null
    ownerAbout: string | null = null
    ownerName: string | null = null
    ownerImage: string | null = null
    ownerMail: string | null = null

    photos: IndexDataType[] = []

    publish = false
    availability: Availability = Availability.available
    availableFrom: number | null = null
    availableTo: number | null = null

    static toObject(data: BoardData): {} {

        const mappped = new Map(Object.entries(data));

        return Object.fromEntries(mappped)
    }

    static toBoard(doc: DocumentData, boardId: string): BoardData | null {

        if (doc === undefined || doc === null) {
            return null
        }

        const data = doc as BoardData
        data.key = boardId

        return data
    }

    static allFilled(board: BoardData | null): boolean {
        if (board == null) {
            return false
        }
        return board.title != null && board.aboutOffer != null
            && board.length > 0 && board.subtitle != null
            && board.title.length > 0 && board.subtitle.length > 0 && board.aboutOffer.length > 0
    }
}
