import { Injectable } from "@angular/core";
import { SupporterDataService } from "../services/supporterdata.service";

@Injectable({
    providedIn: 'root'
})
export class SupporterDetailViewModel {

    constructor(
        private dataService: SupporterDataService
    ) {
    }

    public loadSupporterData(supporterId: string, password: string): Promise<any> {
        return this.dataService.loadSupporterData(supporterId, password)
    }
}
