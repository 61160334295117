import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { LocationService } from '../../../services/location.service';
import { LocationData } from '../../../models/location';
import { take } from 'rxjs';
import { Globals } from '../../../global';

@Component({
  selector: 'app-location-overview',
  standalone: true,
  templateUrl: './location-overview.component.html',
  styleUrl: './location-overview.component.scss',
  imports: [
    CommonModule,
    AngularMaterialModule,
    PipesModule,
    FormsModule,
  ]
})
export class LocationOverviewComponent implements AfterViewInit {

  constructor(
    public locationService: LocationService,
    public globals: Globals
  ) {
  }

  @ViewChild('query', { static: true }) queryInput!: ElementRef;

  ngAfterViewInit() {
    setTimeout(() => {
      this.queryInput.nativeElement.focus();
    }, 300);
  }

  @Output() locationPanel = new EventEmitter<void>();
  @Input() onSearch: ((result: LocationData) => void) | null = null;

  searchInProgress = false
  resultList: LocationData[] = []
  commitedQuery = ""

  async onNewSearchQuery(searchQuery: string) {
    if (searchQuery.length > 3 && this.searchInProgress == false) {
      this.searchInProgress = true
      this.commitedQuery = searchQuery

      let result = await this.locationService.searchLocation(searchQuery)

      this.resultList = result.filter((entry: any) => entry['addresstype'] != 'state' && entry['addresstype'] != 'country').map((entry: any) => new LocationData(entry['display_name'], entry['lon'], entry['lat']));
      this.searchInProgress = false
    }
  }

  selectResult(result: LocationData) {
    if (!this.onSearch) {
      return
    }

    this.onSearch(result)
  }

  close() {
    this.locationPanel.emit();
  }
}
