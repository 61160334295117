

import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";

import { BehaviorSubject, Observable } from "rxjs";
import { Globals } from "../global";

import firebase from "firebase/compat/app";
import { Result } from "../../../../../src/app/model/helper/result.model";
import { FiresoreAdapter } from "../../../../../src/app/services/firestoreadapter.service";
import { SnackbarService } from "../../../../../src/app/services/snackbar.service";
import { LoginState } from "../enums/login-state";
import { UserData } from "../models/user";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root',
})

export class AccountManager {

  public loggedIn: BehaviorSubject<LoginState> = new BehaviorSubject<LoginState>(LoginState.pending);
  public account: BehaviorSubject<UserData | null> = new BehaviorSubject<UserData | null>(null);
  private _user: Observable<firebase.User | null> = new BehaviorSubject<firebase.User | null>(null)

  constructor(
    private _db: FiresoreAdapter,
    private globals: Globals,
    private _afAuth: AngularFireAuth,
    private snackbarService: SnackbarService,
    private notificationService: NotificationService
  ) {
    this.refreshUser(false)

    let subscription = this._afAuth.authState.subscribe(
      (auth: firebase.User | null) => {
        if (auth) {
          this.notificationService.listenToNotificationCount(auth.uid, notification => {
            this.globals.notification.next(notification);
          });
          subscription.unsubscribe()
        }
      }
    );
  }

  /**
   * call if u want to load current user data
   * @param userId
   */
  public async refreshUserAccount(userId: string): Promise<void> {
    return this.refreshAccount(userId, await this._afAuth.currentUser);
  }

  public async getUserToken() {
    let user = await this._afAuth.currentUser
    return user?.getIdToken()
  }

  public refreshUser(updateLoginDate: Boolean) {
    this._user = this._afAuth.authState;

    let userSub = this._user.subscribe(
      (auth: firebase.User | null) => {
        if (!auth) {
          this.globals.clear();
          this.account.next(null);
          this.loggedIn.next(LoginState.loggedOut);
          return;
        }
        this.refreshAccount(auth.uid, auth).then((success) => {
          if (updateLoginDate) {
            let user = this.globals.user
            if (user) {
              user.lastLoginDate = firebase.firestore.FieldValue.serverTimestamp()
              this.saveAccount(user).then(async (success) => {
                this.refreshAccount(user.key!, await this._afAuth.currentUser);
              })
            }
          }

        })

        userSub.unsubscribe()
      }
    );
  }

  private refreshAccount(userId: string, auth: firebase.User | null): Promise<void> {

    return new Promise<void>((refreshAccountDone) => {
      this.getAccountData(userId, async result => {

        if (!auth) {
          refreshAccountDone()
          this.loggedIn.next(LoginState.loggedOut);
          return
        }

        if (!result && auth) {
          // Create new account
          const userData = new UserData();
          // TODO check!?
          userData.accountType = "ACCOUNT_PRIVATE"
          userData.firstName = auth.displayName;
          userData.email = auth.email;
          userData.phone = auth.phoneNumber;
          userData.key = auth.uid;
          userData.userCreationDate = firebase.firestore.FieldValue.serverTimestamp()

          this.getPath(userId).set(UserData.toObject(userData))
            .then(success => {
              this.fillGlobalData(userData);
              // if there is no user, then there is no contract
              this.account.next(userData);
              this.loggedIn.next(LoginState.loggedInNoContract);
            })
            .catch(error => {
              this.snackbarService.openDefaultErrorSnackBar();
              console.error(error)
            });
          refreshAccountDone()
          return;
        }

        try {
          var userData = UserData.toUser(result!, userId)!

          if (!userData.firstName ||
            !userData.secondName ||
            !userData.email || !userData.about
          ) {
            this.fillGlobalData(userData);
            this.account.next(userData);
            this.loggedIn.next(LoginState.loggedInNoUser);
            return
          }

          this.fillGlobalData(userData);
          this.account.next(userData);
          this.loggedIn.next(LoginState.loggedIn);

        } catch (error) {
          this.snackbarService.openDefaultErrorSnackBar();
          console.error(error)
        }

        refreshAccountDone()

      }, () => {
        this.loggedIn.next(LoginState.loggedOut);
      });
    })
  }

  /**
   * Save account id db.
   * @param {UserData} account Account.
   * @returns {Observable<Result>} Observable of saving status.
   */
  public saveAccount(account: UserData): Promise<Result> {
    if (!account) {

      return new Promise(async (result) => {
        result(({ status: false, errorMessage: 'account-required' } as Result));
      });
    }

    return new Promise(async (result) => {

      if (account.userCreationDate === undefined) {
        account.userCreationDate = firebase.firestore.FieldValue.serverTimestamp()
      }

      console.log(account)

      if (account.key == null) {
        return
      }

      this.getPath(account.key).set(UserData.toObject(account))
        .then((success) => {
          result({ status: true } as Result);
        })
        .catch((error) => {
          result(({ status: false, errorMessage: 'account-saving-error' } as Result))
          this.snackbarService.openDefaultErrorSnackBar();
          console.error(error);
        })
    });
  }

  private fillGlobalData(account: UserData | null) {

    if (account == null) {
      return
    }
    this.globals.user = account
  }


  private getPath(userid: string) {
    var docRef = this._db.db.collection("user").doc(userid);
    return docRef;
  }

  private getAccountData(userid: string, finish: (account: UserData | null) => any, error: () => any) {

    this.getPath(userid).get().toPromise().then((result) => {
      finish(UserData.toUser(result!.data()!, userid));
    })
      .catch(errorResult => {
        this.snackbarService.openDefaultErrorSnackBar();
        console.error(errorResult)
      });
  }

  public async deleteUser() {
    let user = this.globals.user!;
    user.markedToBeDeleted = true;
    user.deletionInitiated = firebase.firestore.FieldValue.serverTimestamp();
    await this.getPath(user.key!).set(UserData.toObject(user));
    this.globals.clear();
    let firebaseUser = await this._afAuth.currentUser
    firebaseUser?.sendEmailVerification
    await firebaseUser?.delete()
    this._afAuth.signOut();
  }
}
