import { FormGroup, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { TranslateService } from '../../../projects/boardswap/src/app/localization/translate.service';

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {

  constructor(
    private _translateService: TranslateService
  ) { }


  /**
   * Marks all controls in a form group as touched
   * @param {FormGroup} formGroup - The form group to touch
   */
  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: FormGroup<any>) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }


  /**
   * Creates mask by the current locale for input form field for decimal numbers.
   * @returns {string} Mask 0*.00 or 0*,00 depends on current locale.
   */
  public getLocaleMaskNumber(): string {
    const localeDecimalSeparator = (1.1)
      .toLocaleString(this._translateService.locale).substring(1, 2);
    return `0*${localeDecimalSeparator}00`;
  }


  /**
   * Convert string with locale number (comma separator) to number.
   * @param {string} str String for convert.
   * @returns {number} Number.
   */
  public localeDecimalToNumber(str: string): number {
    if (!str) {
      return 0;
    }

    return parseFloat(str.replace(',', '.'));
  }


  /**
   * Validator for check correct time in form field.
   * @returns {any} Null or not valid data.
   */
  public customRequiredValidator(formControl: FormControl): any {
    if (!formControl.value) {
      return { customRequiredValidator: true };
    }

    return isNaN(formControl.value.getTime()) ? { customRequiredValidator: true } : null;
  }
}
