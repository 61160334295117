<a [hidden]="globals.user" class="" [routerLink]="['/login']">
    <span class="action-icon material-symbols-outlined">
        person
    </span>
</a>

<p *ngIf="globals.user" class="account-icon" [matMenuTriggerFor]="menu">
    <span>{{globals.user.firstName | slice:0:1}}</span>
</p>

<!--
<a>
    <span class="material-symbols-outlined mobile" [matMenuTriggerFor]="menu">
        person
    </span>
</a>
-->

<mat-menu #menu="matMenu">
    <a [routerLink]="['/user']">
        <p class="menuItem">{{'menu-my-account' | translate}}</p>
    </a>
    <a [routerLink]="['/myboards']">
        <p class="menuItem">{{'menu-my-boards' | translate}}</p>
    </a>
    <a [routerLink]="['/contacts']">
        <p class="menuItem">
            <span>{{'menu-messages' | translate}}</span>
            <span *ngIf="getTotalBadgeCount() > 0" class="menu-badge">{{ getTotalBadgeCount() }}</span>
        </p>
    </a>
    <p class="divider"></p>
    <p class="menuItem accent-1" (click)="logout()">{{'menu-logout' | translate}}</p>
</mat-menu>