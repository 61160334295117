export class Day {

    public number: number | null = null;
    public year: number | null = null;

    public month: string | null = null;
    public monthIndex: number | null = null;

    public weekDayName: string | null = null;
    public weekDayNumber: number | null = null;

    public timestamp: number | null = null

    public getDate(): Date {
        return new Date(Number(this.timestamp))
    }

    static createDayTS(timestamp: any): Day {
        var fromDateValue = new Date(Number(timestamp))
        return Day.createDay(fromDateValue.getDate(), fromDateValue.getMonth(), fromDateValue.getFullYear())
    }

    static createDay(dayNumber: number, monthIndex: number, year: number): Day {
        let day = new Day();

        day.monthIndex = monthIndex;
        day.month = Day.getMonthName(monthIndex);

        day.number = dayNumber;
        day.year = year;

        day.weekDayNumber = new Date(year, monthIndex, dayNumber).getDay();
        day.weekDayName = Day.getWeekDayName(day.weekDayNumber);
        day.timestamp = new Date(year, monthIndex, dayNumber, 12).getTime()

        return day;
    }

    static getMonthName(monthIndex: number): string {
        switch (monthIndex) {
            case 0:
                return "January";
            case 1:
                return "February";
            case 2:
                return "March";
            case 3:
                return "April";
            case 4:
                return "May";
            case 5:
                return "June";
            case 6:
                return "July";
            case 7:
                return "August";
            case 8:
                return "September";
            case 9:
                return "October";
            case 10:
                return "November";
            case 11:
                return "December";

            default:
                return "|" + monthIndex;
        }
    }

    static getWeekDayName(weekDay: number): string {
        switch (weekDay) {
            case 0:
                return "Su"; // Sunday
            case 1:
                return "Mo"; // Monday
            case 2:
                return "Tu"; // Tuesday
            case 3:
                return "We"; // Wednesday
            case 4:
                return "Th"; // Thursday
            case 5:
                return "Fr"; // Friday
            case 6:
                return "Sa"; // Saturday

            default:
                return "";
        }
    }
}