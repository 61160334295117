import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwipeDirective } from './swipe.directive';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    SwipeDirective
  ],
  exports: [
    SwipeDirective
  ]
})
export class DirectivesModule { }
