<div class="content vstack dimval padval" style="--padding: 16px;" [ngStyle]="{'--w': globals.framewidth * 0.9 + 'px'}">
  <div class="hstack">
    <h3>{{'fb-title' | translate}}</h3>
    <div class="spacer"></div>
    <div class="close-button" (click)="close()">
      <span class="material-symbols-outlined">close</span>
    </div>
  </div>

  <div class="spacer-l"></div>
  <div *ngIf="searchData" class="padval" style="--padding: 8px;">

    <div class="hstack">
      <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
        <mat-label>{{'fb-length-from' | translate}}</mat-label>
        <mat-select [(ngModel)]="searchData.fromLength">
          <div *ngFor="let i of [].constructor(133); let index = index">
            <mat-option [value]="(index * 2.54 + 84)">{{ (index* 2.54 + 84) | cmToFeetInch }} ({{ (index* 2.54 + 84)
              |
              roundCm }}
              cm)</mat-option>
          </div>
        </mat-select>
      </mat-form-field>

      <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
        <mat-label>{{'fb-to' | translate}}</mat-label>
        <mat-select [(ngModel)]="searchData.toLength">
          <div *ngFor="let i of [].constructor(133); let index = index">
            <mat-option [value]="(index * 2.54 + 84)">{{ (index* 2.54 + 84) | cmToFeetInch }} ({{ (index* 2.54 + 84)
              |
              roundCm }}
              cm)</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="hstack">
      <mat-form-field color="primary" class="dimval" style="--w: 100%; --h: 100px;">
        <mat-label>{{'fb-volume-from' | translate}}</mat-label>
        <mat-select [(ngModel)]="searchData.fromVol">
          <div *ngFor="let i of [].constructor(400); let index = index">
            <mat-option [value]="(index + 1) * 5">{{ (index + 1) * 5 }} Liter</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="primary" class="dimval" style="--w: 100%; --h: 100px;">
        <mat-label>{{'fb-to' | translate}}</mat-label>
        <mat-select [(ngModel)]="searchData.toVol">
          <div *ngFor="let i of [].constructor(400); let index = index">
            <mat-option [value]="(index + 1) * 5">{{ (index + 1) * 5 }} Liter</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%; --h: 100px;">
        <mat-label>{{'fb-location-radius' | translate}}</mat-label>
        <input (click)="openLocationOverlay()" maxlength="50" matInput [ngModel]="searchData.location?.name">
      </mat-form-field>
    </div>

    <div class="hstack">
      <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
        <mat-label>{{'fb-price-from' | translate}}</mat-label>
        <mat-select [(ngModel)]="searchData.fromPrice">
          <div *ngFor="let i of [].constructor(600); let index = index">
            <mat-option [value]="(index + 1) * 5">{{ (index + 1) * 5 }} €</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="primary" class="dimval" style="--w: 50%; --h: 100px;">
        <mat-label>{{'fb-to' | translate}}</mat-label>
        <mat-select [(ngModel)]="searchData.toPrice">
          <div *ngFor="let i of [].constructor(600); let index = index">
            <mat-option [value]="(index + 1) * 5">{{ (index + 1) * 5 }} €</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="hstack">
    <div class="spacer"></div>
    <button mat-flat-button color="primary" (click)="search()">
      <span>{{'search' | translate}}</span>
    </button>
  </div>
</div>