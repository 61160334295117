import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { Globals } from '../../../global';
import { LoginStateFieldComponent } from "../login-state-field/login-state-field.component";

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  imports: [
    CommonModule,
    PipesModule,
    LoginStateFieldComponent,
    LanguageButtonComponent,
    AngularMaterialModule,
    RouterModule
  ]
})
export class HeaderComponent {

  @Input() showBack: boolean = true

  constructor(public globals: Globals) { }

  getTotalBadgeCount(): number {
    let count = 0
    if (this.globals.notification.value && this.globals.notification.value.countMap) {
      for (const [key, value] of Object.entries(this.globals.notification.value.countMap)) {
        count += value
      }
    }
    return 0
  }
}
