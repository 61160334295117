import { Injectable } from '@angular/core';
import { Globals } from '../global';
import { BehaviorSubject } from 'rxjs';
import { ImageService } from '../services/image.service';
import { IndexDataType } from '../../../../../src/app/model/helper/IndexDataType';
import { BoarddataService } from '../services/boarddata.service';
import { BoardData } from '../models/board';
import { fieldBoardImage } from '../enums/fields';
import { UserData } from '../models/user';
import { AccountManager } from '../services/account-manager.service';
import { ContactService } from '../services/contact.service';
import { LocationData } from '../models/location';
import firebase from "firebase/compat/app";

@Injectable({
  providedIn: 'root'
})
export class BoardListViewModel {

  public boards: BehaviorSubject<BoardData[] | null> = new BehaviorSubject<BoardData[] | null>(null);

  constructor(
    private boardService: BoarddataService,
    private globals: Globals,
    private imageService: ImageService,
    private accountManager: AccountManager,
    private contactService: ContactService
  ) {
  }

  public resetData() {
    this.boards.next(null)
  }

  async loadListerBoards(listerKey: string) {
    this.boards.next(null)

    try {
      var result = await this.boardService.getBoards(listerKey, false)
      this.processResult(result)
    } catch (error) {
      console.log('EventRepoService' + error);
    }
  }

  async loadUserBoards() {
    this.boards.next(null)

    if (this.globals.user?.key) {
      try {
        var result = await this.boardService.getBoards(this.globals.user.key, true)
        this.processResult(result)
      } catch (error) {
        console.log('EventRepoService' + error);
      }
    }
  }

  async loadBoards() {
    this.boards.next(null)
    try {
      let result = await this.boardService.getBoards(null, false)
      this.processResult(result)
      return
    } catch (error) {
      console.log('EventRepoService' + error);
    }
  }

  searchBoards(
    fromLength: number | null,
    toLength: number | null,
    fromVol: number | null,
    toVol: number | null,
    location: LocationData | null,
    fromPrice: number | null,
    toPrice: number | null) {

    this.boards.next(null)
    try {
      this.boardService.searchBoards(fromLength, toLength, fromVol, toVol, location, fromPrice, toPrice).then((result) => {
        this.processResult(result)
      })
    } catch (error) {
      console.log('EventRepoService' + error);
    }
  }

  public async startRequest(user: UserData, boardData: BoardData, message: string): Promise<any> {
    let userToken = await this.accountManager.getUserToken()
    return this.contactService.startContact(user, boardData, message, userToken)
  }

  public async saveBoardImage(key: string, file: any, name: string): Promise<IndexDataType | null> {
    return new Promise(async (result) => {
      var url = await this.imageService.saveImage(key, file, name, fieldBoardImage)

      var board = await this.getBoard(key)

      if (board && url) {
        board.photos.push(url);
      }
      result(url)
    })
  }

  public saveBoard(key: string | null, board: BoardData): Promise<string | null> {

    var allBoards = this.boards.getValue()
    if (allBoards) {
      var index = allBoards.findIndex(board => board.key == key) ?? null
      if (index >= 0) {
        allBoards.splice(index, 1)
        this.boards.next(allBoards)
      }

      allBoards.push(board)
    }

    return this.boardService.saveBoard(key, board)
  }

  public deleteBoard(key: string): Promise<boolean | null> {

    var allListings = this.boards.getValue()
    if (allListings) {
      var index = allListings.findIndex(listing => listing.key == key) ?? null
      if (index >= 0) {
        allListings.splice(index, 1)
        this.boards.next(allListings)
      }
    }

    return this.boardService.deleteBoard(key)
  }

  public getBoard(key: string): Promise<BoardData | null> {

    return new Promise<BoardData | null>(async (result) => {
      var board = this.boards.getValue()?.find(board => board.key == key) ?? null

      if (!board) {
        board = await this.boardService.getBoard(key)
      }

      result(board)
    })
  }

  public async newBoard(): Promise<string | null> {
    var board = new BoardData()
    board.ownerKey = this.globals.user!.key
    board.date = firebase.firestore.FieldValue.serverTimestamp()
    var result = this.boardService.saveBoard(null, board)

    var allBoards = this.boards.getValue()
    allBoards?.push(board)
    this.boards.next(allBoards)

    return result
  }

  private processResult(result: BoardData[]) {
    this.boards.next(result)

    result.forEach(async (element) => {

      try {
        var urls = await this.boardService.getImageUrls(element)
        element.photos = urls;

      } catch (error) {
        console.error(error);
      }
    });
  }
}
