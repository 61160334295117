<div class="vstack">
    <app-header [showBack]=false></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">
        <div class="spacer-xxl"></div>
        <div *ngIf="!supporterData" class="vstack">
            <h6 *ngIf="supporterId" class="">{{ supporterId }}</h6>
            <h6 *ngIf="!supporterId" class="">sid must be set in url .. https://www.surfrizz.com/supporter?sid=XXX</h6>
            <p>Please provide a password</p>
            <p>Reload page to enter password</p>
        </div>
        <div *ngIf="supporterData" class="vstack">
            <h3 class="accent-1 superbold">{{ supporterData.supporterName }}</h3>
            <h6 class="">{{ supporterData.supporterEmail }}</h6>
            <h6 class="">{{ supporterId }}</h6>
            <div class="spacer-xxl"></div>
            <h3 class="accent-2">Users</h3>
            <div class="spacer-l"></div>
            <div *ngIf="supporterData.users">
                <div *ngFor="let user of supporterData.users; let i = index">
                    <a [routerLink]="['/lboards']" [queryParams]="{lkey: user.key}">
                        <p>{{ i + 1 }}. {{ user.firstName }} {{ user.secondName }}</p>
                        <p>{{ user.userCreationDate | date }}</p>
                    </a>
                    <div class="spacer-m"></div>
                    <div class="divider"></div>
                    <div class="spacer-m"></div>
                </div>
            </div>
            <div class="spacer-xxl"></div>
            <h3 class="accent-2">Boards</h3>
            <div class="spacer-l"></div>
            <div *ngIf="supporterData.users">
                <div *ngFor="let board of supporterData.boards; let i = index">

                    <a [routerLink]="['/board']" [queryParams]="{bkey: board.key}">
                        <p>{{ i + 1 }}. {{board.title}} (published -> {{board.publish}})</p>
                        <p *ngIf="board.firstPublisDate">{{ board.firstPublisDate | date }}</p>
                    </a>
                    <div class="spacer-m"></div>
                    <div class="divider"></div>
                </div>
            </div>
        </div>
    </div>
</div>