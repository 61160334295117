import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from '../../../../../../../src/app/pipes/pipes.module';
import { Globals } from '../../../global';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-login-state-field',
  standalone: true,
  templateUrl: './login-state-field.component.html',
  styleUrl: './login-state-field.component.scss',
  imports: [
    AngularMaterialModule,
    CommonModule,
    RouterModule,
    PipesModule
  ]
})
export class LoginStateFieldComponent {

  constructor(
    private authService: AuthService,
    public globals: Globals,
    public router: Router
  ) {
  }

  public logout(): void {
    this.authService.logout()
    this.router.navigate(['/'])
  }

  getTotalBadgeCount(): number {
    let count = 0
    if (this.globals.notification.value && this.globals.notification.value.countMap) {
      for (const [key, value] of Object.entries(this.globals.notification.value.countMap)) {
        count += value
      }
    }

    return 0
  }
}
