<div class="vstack">
    <app-header [showBack]="true"></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">
        <div *ngIf="board" class="vstack">

            <div class="hstack">
                <div class="spacer"></div>
                <div *ngIf="board.ownerImage" class="imgval" style="--h: 300px;--w: auto;">
                    <img [src]=board.ownerImage alt="">
                </div>
                <div class="spacer"></div>
            </div>


            <div class="spacer-xxl"></div>
            <div class="vstack">

                <h2 class="bold accent-2">{{ board.ownerName }}</h2>
                <div class="spacer-xs"></div>
                <h6 class="bold">{{ board.ownerMail }}</h6>
                <div class="spacer-xxl"></div>
                <p class="line-breaks">
                    {{ board.ownerAbout }}
                </p>

                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>

                <a [routerLink]="['/lboards']" [queryParams]="{lkey: board.ownerKey}">
                    <h4 class="accent-1 superbold">{{'bll-all-boards' | translate }}{{ board.ownerName }} </h4>
                </a>

                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>
                <div class="spacer-xxl"></div>
            </div>
        </div>
    </div>
</div>