<div class="white bar vstack dimval footer" style="--h: 60px; --w: 100%;">

    <div class="zstack">
        <div class="hstack padval" style="--padding: 0 8px 0 8px;">
            <div class="spacer-xs"></div>

            <div class="dimval" style="--w: 80px;">
                <app-login-state-field></app-login-state-field>
                <p class="footer-text" *ngIf="globals.user != null">Konto</p>
                <p class="footer-text" *ngIf="globals.user == null">Anmelden</p>
            </div>

            <div class="spacer"></div>

            <div class="zstack" *ngIf="globals.user != null">
                <a [routerLink]="['/contacts']">
                    <span class="footer-icon material-symbols-outlined">
                        mail
                    </span>
                    <p class="footer-text">Post</p>
                </a>
                <div *ngIf="getTotalBadgeCount() > 0" class="badge">
                    <p>{{ getTotalBadgeCount() }}</p>
                </div>
            </div>

            <div class="spacer" *ngIf="globals.user != null"></div>

            <a [routerLink]="['/myboards']" *ngIf="globals.user != null">
                <span class="footer-icon material-symbols-outlined">
                    hov
                </span>
                <p class="footer-text">Meine Boards</p>
            </a>

            <div class="spacer"></div>
            <a [routerLink]="['/search']">
                <span class="footer-icon material-symbols-outlined">
                    search
                </span>
                <p class="footer-text">Suche</p>
            </a>
            <div class="spacer-xs"></div>
        </div>
        <p class="divider"></p>
    </div>

</div>