import { Injectable } from '@angular/core';
import { ListingData } from '../../model/business/listing';
import { ListingdataService } from '../listingdata.service';
import { ImageService } from '../image.service';
import { Businessdata } from '../../model/business/business';
import { UserData } from '../../model/user';
import { UserdataService } from '../userdata.service';
import { BusinessdataService } from '../businessdata.service';
import { RoomData } from '../../model/business/room';
import { LocationId } from '../../enum/locationId';

@Injectable({
  providedIn: 'root'
})
export class ExampleService {

  constructor(
    private listingService: ListingdataService,
    private userService: UserdataService,
    private imageService: ImageService,
    private businessService: BusinessdataService
  ) { }

  public addExampleListings(): Promise<boolean> {

    return new Promise(async (result) => {

      try {

        var user = new UserData()
        user.accountType = "ACCOUNT_COMMERCIAL"
        user.key = "IAzrzhSQ6LSu9ULEbrXUgzaU7FA2"
        user.firstName = "Niko"
        user.secondName = "Laus"
        user.about = "Sdfsdfsdfsdf"
        user.accountType = "ACCOUNT_COMMERCIAL"
        user.email = "hello@nikolauskoller.com"
        user.businessKey = "LLLLLLLL"

        await this.userService.addUser(user.key, user)

        var business = new Businessdata()
        business.key = "LLLLLLLL"
        business.userId = user.key
        business.email = "hello@nikolauskoller.com"
        business.phone = "+43 670 50 777 84"
        business.website = "https://www.nikolauskoller.com"
        business.name = "House of Niko"

        await this.businessService.addBusiness(business.key, business)


        var roomData = new RoomData()
        roomData.key = "34465435345"
        roomData.bedKind = "1 Kingsize"
        roomData.name = "Surf and Sleep"
        roomData.numberOfGuests = 2
        roomData.pricePerNight = 19
        roomData.roomDesc = "It is a room with a king size bed, a simple wardrope, a window and a small desk."

        var listingAAA = new ListingData()
        listingAAA.key = "AAAAAAAA"
        listingAAA.name = "Surf n Sleep"
        listingAAA.rooms.push(JSON.stringify(roomData));

        listingAAA.breakfast = false

        listingAAA.discount1Week = 10
        listingAAA.discount4Week = 20


        listingAAA.surfEquipment = false
        listingAAA.yoga = false

        listingAAA.businessKey = business.key
        listingAAA.locationId = LocationId.moroccoTamraghtTagazout.locationId

        listingAAA.businessKey = business.key
        listingAAA.district = ""
        listingAAA.dogsAllowed = false
        listingAAA.geoLoc = {}
        listingAAA.gym = false
        listingAAA.kitchen = true
        listingAAA.laundry = true
        listingAAA.locationDesc = "Tamraght, Morocco, boasts a diverse culinary scene, with an array of enchanting restaurants and cafes that cater to various tastes. From traditional Moroccan eateries offering flavorful tagines and couscous to vibrant seaside cafes serving freshly caught seafood, the dining options are both eclectic and enticing. Surrounded by the beauty of the Atlantic Ocean, many establishments provide a picturesque backdrop for dining. Visitors can savor aromatic Moroccan mint tea in cozy cafes or indulge in international cuisine with ocean views. Whether seeking local flavors or international delights, Tamraght's restaurants and cafes create a delightful tapestry of culinary experiences against the backdrop of Morocco's coastal charm."
        listingAAA.maxGuests = 6
        listingAAA.maxRooms = 3
        listingAAA.city = "Tamraght"
        listingAAA.country = "Morocco"
        listingAAA.apartmentDesc = "Nestled in the heart of Morocco, this charming apartment offers a perfect blend of comfort and elegance. Adorned with Moroccan aesthetics, it features a spacious interior adorned with vibrant colors and intricate designs, providing an authentic and cozy atmosphere. The highlight of the residence is its inviting terrace, where residents can unwind while enjoying panoramic views of the surrounding landscapes. The terrace serves as an idyllic oasis, adorned with traditional Moroccan decor and lush greenery, creating a serene environment for relaxation and social gatherings. With its stylish interior, cultural ambiance, and a picturesque terrace, this apartment is a delightful retreat in the heart of Morocco."
        listingAAA.surfSpots = "There are 3 surf spots close to the location."
        listingAAA.terrace = true
        listingAAA.wifi = true
        listingAAA.wifiSpeed = "40 Mbit"
        listingAAA.streetNr = "Close to the main street"
        listingAAA.post = "00000"

        listingAAA.hostKey = user.key
        listingAAA.hostAbout = user.about
        listingAAA.hostName = user.firstName
        listingAAA.hostEmail = user.email

        await this.listingService.addListing(listingAAA.key, listingAAA)

        result(true)

      } catch (error) {
        result(false);
        console.error(error);
      }
    })
  }
}
