// consent.service.ts
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SupporterDataService {

    constructor(
        public analytics: AngularFireAnalytics,
        private fns: AngularFireFunctions,
    ) {

    }

    public loadSupporterData(supporterId: string, password: string): Promise<any> {

        let passData = {
            supporterId: supporterId,
            password: password,
        }

        return new Promise((result) => {
            const callable = this.fns.httpsCallable('supporter-onRequestSupporterData');
            const data = callable(passData);
            firstValueFrom(data)
                .then((resultValue) => {
                    console.log('resultValue: ' + JSON.stringify(resultValue));
                    result(resultValue);
                })
                .catch((error) => {
                    console.error(error);
                    result({
                        success: false,
                    });
                })
        });
    }
}
