<div class="vstack">
    <app-header [showBack]=false></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">

        <div class="vstack dimval" style="--h: 100%;">

            <div class="spacer-l"></div>

            <h3 class="accent-2 superbold">{{ 'abi-title' | translate }}</h3>
            <div class="spacer-xxl"></div>
            <h6 class="bold">{{ 'abi-dot-1' | translate }}</h6>
            <div class="spacer-l"></div>
            <h6 class="bold">{{ 'abi-dot-2' | translate }}
            </h6>
            <div class="spacer-l"></div>
            <h6 class="bold">{{ 'abi-dot-3' | translate }}</h6>

            <div class="spacer-l"></div>
            <h6 class="bold">{{ 'abi-dot-4' | translate }}</h6>
            <div class="spacer-xxl"></div>
            <a class="accent-1 superbold" href="mailto: hello@surfrizz.com">{{ 'abi-contact' | translate }}</a>

            <div class="spacer-xxxl"></div>
            <div class="hstack">
                <div class="spacer"></div>
                <a [routerLink]="['/add']">
                    <button mat-flat-button color="primary">
                        <span>{{'abi-action' | translate}}</span>
                    </button>
                </a>
            </div>

            <div class="spacer"></div>
        </div>
    </div>
</div>