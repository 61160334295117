import { Injectable } from "@angular/core";
import { UserData } from "../model/user";
import { FiresoreAdapter } from "../../../../../src/app/services/firestoreadapter.service";

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  constructor(private _db: FiresoreAdapter) { }


  public addUser(userId: string, user: UserData): Promise<string | null> {

    return new Promise(async (result) => {

      if (user === null || user === undefined) {
        result(null);
        return
      }

      this._db.db.collection('/user').doc(userId).set(UserData.toObject(user))
        .then((docRef) => {
          result(userId);
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    });
  }

  public saveUser(userId: string, user: UserData): Promise<string | null> {

    return new Promise(async (result) => {

      if (user === null || user === undefined) {
        result(null);
        return
      }

      if (userId) {
        this._db.db.collection('/user').doc(userId).set(UserData.toObject(user))
          .then(() => {
            result(userId);
          })
          .catch((error) => {
            result(null);
            console.error(error);
          })
      } else {
        this._db.db.collection('/user').add(UserData.toObject(user))
          .then((docRef) => {
            result(docRef.id);
          })
          .catch((error) => {
            result(null);
            console.error(error);
          })
      }
    });
  }

  public getPublicUser(userId: string): Promise<UserData | null> {
    return new Promise(async (result) => {

      if (userId === null || userId === undefined) {
        result(null);
        return
      }

      this._db.db.collection('/user_pup').doc(userId).get().toPromise()
        .then((data) => {
          if (data) {
            result(UserData.toUser(data.data()!, data.id));
          } else {
            result(null)
          }
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    });
  }
}
