<div class="content padval" style="--padding: 8px 16px 0 16px;">

    <div class="vstack dimval" style="--h: 100%;">
        Datenschutzerklärung Surfrizz Anwendung<br><br>
        Im Rahmen der Nutzung dieser Anwendung werden personenbezogene Daten von Ihnen durch uns als den für die
        Datenverarbeitung Verantwortlichen verarbeitet und für die Dauer gespeichert,
        die zur Erfüllung der festgelegten Zwecke und gesetzlicher Verpflichtungen erforderlich ist. <br>
        Im Folgenden informieren wir Sie darüber, um welche Daten es sich dabei handelt, auf welche Weise sie
        verarbeitet werden und welche Rechte Ihnen diesbezüglich zustehen.<br>
        Personenbezogene Daten sind gemäß Art. 4 Nr. 1 Datenschutzgrundverordnung (DSGVO) alle Informationen,
        die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.<br><br>
        1) Name und Kontaktdaten des für die Verarbeitung Verantwortlichen und des behördlichen
        Datenschutzbeauftragten<br>
        Diese Datenschutzinformation gilt für die Datenverarbeitung im Rahmen der Nutzung unserer Anwendung
        durch den
        Verantwortlichen:<br>Nikolaus Koller<br>Slavi-Soucek-Straße 18, 5026 Salzburg, Österreich <br>
        E-Mail: admin[AT]Surfrizz.com<br>Telefon: +43 670 50 777 84<br>
        Sie können sich jederzeit bei Fragen zum Datenschutzrecht oder Ihren Betroffenenrechten direkt an uns
        werden.<br><br>
        2) Berechtigungen der Anwendung für Web<br>
        INTERNET (Daten aus dem Internet abrufen/Zugriff auf alle Netzwerke): Wird benötigt um die Daten zu
        laden. <br>
        4) Gespeicherte Daten <br>
        Wir speichern folgende Daten:<br>
        <br>
        E-Mail-Adresse, Vorname, Nachname, About Feld, Angaben zu den Surfboards sowie Angebotsort, Bilderpfad zu einem
        in
        der Anwendung
        hochgeladenen Profilbild, Hochgeladene Bilder, allgemeine Nutzungsdaten<br>
        <br>
        Sichtbarkeit der Daten für andere Nutzer:<br>
        Werden Angebote eingestellt, sind alle Daten inklusive der Nutzerprofile
        öffentlich in der Anwendung, auf der Webseite oder auf den sozialen
        Kanälen von Surfrizz zugänglich.<br>
        Interaktionen von Nutzern und Anfragen zu Angeboten sind für die jeweiligen Anbieter sichtbar. Werden
        Nachrichten im Chat geschrieben, können diese inklusive Profil
        wie Name und Foto für die anderen Nutzer sichtbar sein.<br>
        <br>
        Die Einbindung erfolgt auf Grundlage Ihrer ausdrücklichen Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a
        und Art. 6 Abs. 1 S. 1 lit. f DSGVO. Sie erfolgt, um die
        Funktion unserer Anwendung nutzerfreundlicher und interessanter zu gestalten. Darin ist ein berechtigtes
        Interesse im Sinne der vorgenannten Vorschrift zu sehen.<br><br>
        5) Auswertung von Nutzungsdaten<br>
        Anonymisierte Nutzungsdaten werden zum sicheren Betrieb und zur Weiterentwicklung der Anwendung
        ausgewertet.
        Diese Daten lassen sich aber nicht mit Ihrer Identität
        in Verbindung bringen. Wir haben keine Möglichkeiten, diese Daten Ihrer Person zuzuordnen und führen
        diese Daten auch nicht mit anderen Datenquellen zusammen.<br>
        Im Rahmen der Nutzung der Anwendung werden Daten für statistische und Sicherungszwecke gespeichert.<br>
        Dienste zur Auswertung der Daten <br>
        Die Anwendung verwendet optional Google Analytics for Firebase, Firebase Crashlytics und Firebase
        Performance.
        Falls die Dienste aktiviert werden sollen, kann der
        Benutzer während der Nutzung der Anwendung auswählen, ob Google Analytics for Firebase, Firebase
        Crashlytics
        und Firebase Performance verwendet werden soll. Bis
        der Nutzer gefragt wird, sind die Dienste deaktiviert und auch nicht im Profil-Menü verfügbar. Der
        Benutzer kann dann jederzeit die Verwendung von Google
        Analytics for Firebase, Firebase Crashlytics und Firebase Performance in der Anwendung deaktivieren. Die
        erzeugte Information über die Benutzung der Anwendung wird mit
        anonymisierter IP-Adresse an einen Server von Google übertragen und dort gespeichert. Google wird diese
        Informationen benutzen, um Ihre Nutzung der Anwendung
        auszuwerten, um Reports über die Aktivität für die Anwendung-Betreiber zusammenzustellen. Auch wird
        Google
        diese Informationen gegebenenfalls an Dritte übertragen.
        Falls der Benutzer der Nutzung von Google Analytics for Firebase, Firebase Crashlytics und Firebase
        Performance in der Anwendung zustimmt, erklärt er sich mit der
        Bearbeitung der über ihn erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu
        dem zuvor benannten Zweck einverstanden. Diese
        Nutzungsdaten bilden die Basis für statistische, anonyme Auswertungen, so dass Trends erkennbar sind,
        anhand derer das Angebot entsprechend verbessert werden kann.<br><br>
        6) Dienste für den Login<br>
        Um den Login für den User zu vereinfachen wird mit E-Mail Anbietern
        gearbeitet.<br>
        Um die Logins zu verarbeiteten und mit den Benutzer-Daten zu verknüpfen wird Firebase Authentication
        verwendet.<br>
        Die Login-Dienste sind notwendig um den User in der Anwendung zu authentifizieren und eine Verknüpfung
        mit den
        Benutzerdaten/ seinem Konto herzustellen.<br>
        Google Login ist ein Authentication Dienst, bereitgestellt von Google LLC oder von Google Ireland
        Limited, je nach Standort, von dem aus auf diese
        Anwendung zugegriffen wird.<br>
        Erhobene personenbezogene Daten: Nutzungsdaten; verschiedene Datenarten, wie in der Datenschutzerklärung
        des Dienstes beschrieben.<br>
        Verarbeitungsort: USA –&nbsp;Datenschutzerklärung; Irland –&nbsp;Datenschutzerklärung. Privacy
        Shield-Mitglied. <br>
        Die Einbindung erfolgt auf Grundlage Ihrer ausdrücklichen Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a
        und Art. 6 Abs. 1 S. 1 lit. f DSGVO. Sie erfolgt,
        um unsere Anwendung nutzerfreundlicher und interessanter zu gestalten. Darin ist ein berechtigtes
        Interesse im
        Sinne der vorgenannten Vorschrift zu sehen.<br><br>
        7) Auftragsverarbeter<br>
        Firebase Cloud Authentication ist ein Authentication Dienst, bereitgestellt von Google LLC oder von
        Google Ireland Limited, je nach Standort, von dem
        aus auf diese Anwendung zugegriffen wird. Erhobene personenbezogene Daten: Nutzungsdaten; verschiedene
        Datenarten, wie in der Datenschutzerklärung
        des Dienstes beschrieben.Verarbeitungsort: USA –&nbsp;Datenschutzerklärung; Irland
        –&nbsp;Datenschutzerklärung. Privacy Shield-Mitglied. <br>
        Firebase Cloud (Analytics for Firebase, Crashlytics for Firebase, Performance for Firebase, Firestore
        und Firebase Storage für die Datensicherung)
        ist ein Backend Dienst, bereitgestellt von Google LLC oder von Google Ireland Limited, je nach Standort,
        von dem aus auf diese Anwendung zugegriffen wird. <br>
        Erhobene personenbezogene Daten: Nutzungsdaten; verschiedene Datenarten, wie in der Datenschutzerklärung
        des Dienstes beschrieben. <br>
        Verarbeitungsort: EU –&nbsp;Datenschutzerklärung. Privacy Shield-Mitglied. <br>
        Easyname GmbH, Canettistraße 5/10, A-1100 Wien, Ist ein Host für die Webseite sowie werden Nutzerdaten
        hier zur Verarbeitung gespeichert. Sitz Österreich. Verarbeitung und Speichern der Daten erfolgt in
        Österreich.
        <br>
        Die Einbindung erfolgt auf Grundlage Ihrer ausdrücklichen Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a
        und Art. 6 Abs. 1 S. 1 lit. f DSGVO.
        Sie erfolgt, um unsere Anwendung nutzerfreundlicher und interessanter zu gestalten. Darin ist ein
        berechtigtes
        Interesse im Sinne der vorgenannten Vorschrift zu sehen.<br><br>
        8) Weitergabe von Daten an Dritte<br>
        Daten, die bei der Nutzung der Anwendung protokolliert worden sind, werden grundsätzlich an Dritte nur
        übermittelt, soweit dies gesetzlich vorgeschrieben,
        durch eine Gerichtsentscheidung festgelegt oder die Weitergabe im Falle von Angriffen auf die
        Internetinfrastruktur des Betreibers zur Rechts- oder
        Strafverfolgung erforderlich ist. Eine Weitergabe zu anderen nichtkommerziellen oder zu kommerziellen
        Zwecken erfolgt nicht.<br><br>
        9) Betroffenenrechte<br>
        Sie haben das Recht:<br>
        gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies
        hat zur Folge, dass wir die Datenverarbeitung,
        die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;<br>
        gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.
        Insbesondere können Sie Auskunft über die
        Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
        denen Ihre Daten offengelegt wurden
        oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
        Einschränkung der Verarbeitung oder Widerspruch,
        das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden
        verlangen;<br>
        gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns
        gespeicherten personenbezogenen Daten zu verlangen<br>
        gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,
        soweit nicht die Verarbeitung zur Ausübung des
        Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus
        Gründen des öffentlichen Interesses oder
        zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;<br>
        gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,
        soweit die Richtigkeit der Daten von Ihnen
        bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten
        nicht mehr benötigen, Sie jedoch diese
        zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21
        DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;<br>
        gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
        strukturierten, gängigen und maschinenlesebaren Format
        zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen und<br>
        gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür
        an die Aufsichtsbehörde ihres üblichen
        Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.<br>
        <br>
        &nbsp;<br>
        10) Aktualität und Änderung dieser Datenschutzinformation<br>
        Diese Datenschutzinformation ist aktuell gültig und hat den Stand Juli 2024. Durch die
        Weiterentwicklung unserer Webseite und Angebote darüber
        oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese
        Datenschutzinformation zu ändern. Die jeweils
        aktuelle Datenschutzinformation kann jederzeit auf der Webseite unter
        https://www.Surfrizz.com/privacy von Ihnen abgerufen und ausgedruckt werden.<br>
        <br>
        <a href="../../../../../assets/docs/privacy.pdf">Download PDF</a>
    </div>
</div>