<div class="impressum-content">
    <div class="">
        <div class="row">
            <div class="col-md-4 px-3">
                <h5>{{ 'companydetails-Contact-title' | translate }}</h5>
                <p>{{ 'companydetails-Contact-subtitle' | translate }}</p>
                <p class="mt-3">hello[AT]freeboho.com</p>

            </div>
            <div class="col-md-4 px-3">
                <h5>{{ 'companydetails-payment-title' | translate }}</h5>
                <p>{{ 'companydetails-payment-subtitle' | translate }}</p>
                <p class="mt-3">
                    <!-- <img class="payment" src="../../assets/payment/payment_1.png">-->
                </p>
            </div>
            <div class="col-md-4 px-3">
                <h5>{{ 'companydetails-social-title' | translate }}</h5>
                <p>{{ 'companydetails-social-subtitle' | translate }}</p>

            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12  px-3">

                <h5 class="mt-5">{{ 'companydetails-Gratification-title' | translate }}</h5>
                <p>{{ 'companydetails-Gratification-subtitle' | translate }}</p>
                <p class="mt-3">
                    <a href="https://www.pexels.com/photo/cozy-tent-with-bed-and-terrace-on-beach-4825701/">
                        Rachel Claire</a>
                    <a href="https://www.pexels.com/de-de/foto/menschen-die-auf-kamelen-reiten-1703312/">
                        Tomáš Malík</a>
                    <a
                        href="https://pixabay.com/de//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4087278">
                        Fernanda Pérez</a>
                    <a
                        href="https://pixabay.com/de//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4828134">
                        Andrzej</a>
                </p>
            </div>
        </div>
        <div class="row justify-content-center impressum">
            <div class="col-md-6">
                <p>Impressum: Nikolaus Koller - Slavi-Soucek-Straße 18, 5026 Salzburg, Österreich - Austria - 0043 123
                    123 -
                    hello[AT]freeboho.com - <a target="_blank" [routerLink]=" ['/terms']">{{ 'terms' | translate}}</a> -
                    <a target="_blank" [routerLink]="['/privacy']">{{
                        'data-protection' | translate}}</a>
                </p>
            </div>
        </div>
    </div>
</div>