import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roundCm2'
})
export class RoundCm2 implements PipeTransform {

    transform(value: number): string {
        if (!value) return '';

        const rounded = Math.round(value * 100) / 100;

        return `${rounded}`
    }

}