import { NgModule } from '@angular/core';

import { DatePipePipe } from './date-pipe.pipe';
import { TranslatePipe } from './translate.pipe';
import { CmToFeetInchPipe } from './cmToFeetInch.pipe';
import { RoundCm } from './roundCm.pipe';
import { CmToInchPipe } from './cmToInch.pipe';
import { RoundCm2 } from './roundCm2.pipe';
import { MmToInchPipe } from './mmToInch';
import { AvailabilityPipe } from './availability.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    DatePipePipe,
    CmToFeetInchPipe,
    CmToInchPipe,
    RoundCm2,
    RoundCm,
    MmToInchPipe,
    AvailabilityPipe
  ],
  exports: [
    TranslatePipe,
    DatePipePipe,
    CmToFeetInchPipe,
    CmToInchPipe,
    RoundCm2,
    RoundCm,
    MmToInchPipe,
    AvailabilityPipe
  ],
  providers: [
    TranslatePipe,
    DatePipePipe,
    CmToFeetInchPipe,
    CmToInchPipe,
    RoundCm2,
    RoundCm,
    MmToInchPipe,
    AvailabilityPipe
  ]
})
export class PipesModule {

}
