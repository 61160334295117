import { Injectable } from "@angular/core";
import { Globals } from "../global";
import { BoarddataService } from "../services/boarddata.service";
import { UserData } from "../models/user";

@Injectable({
    providedIn: 'root'
})
export class UserDataViewModel {

    constructor(
        private boardService: BoarddataService,
        private globals: Globals
    ) {
    }

    public async unpublishAllBoards(userdata: UserData) {
        if (this.globals.user?.key) {
            var boards = await this.boardService.getBoards(this.globals.user.key, true)
            boards.forEach((board) => {
                if (board.ownerKey == userdata.key) {
                    board.publish = false
                    this.boardService.saveBoard(board.key!, board)
                }
            })
        } else {
            throw Error("No user available")
        }
    }

    public async updateFromUser(userdata: UserData) {
        if (this.globals.user?.key) {
            var boards = await this.boardService.getBoards(this.globals.user.key, true)
            boards.forEach((board) => {
                if (board.ownerKey == userdata.key) {
                    board.ownerAbout = userdata.about
                    board.ownerName = userdata.firstName
                    board.ownerImage = userdata.imageUrl
                    this.boardService.saveBoard(board.key!, board)
                }
            })
        } else {
            throw Error("No user available")
        }
    }
}