<div class="">
    <div class="zstack">
        <div class="viewport">
            <router-outlet></router-outlet>
        </div>
        <div *ngIf="showBanner" class="vstack dimval" style="--h:100%; --w: 100%;">
            <div class="spacer"></div>
            <div class="viewport accent-2-back prim-color padval dimval"
                style="--padding: 16px; --w: auto; --h: auto; z-index: 1000;">
                <p>{{'analytics-question' | translate}}</p>
                <div class="spacer-xl"></div>
                <div class="hstack">
                    <button mat-flat-button color="primary" (click)="onUserConsentDenied()">{{'no' |
                        translate}}</button>
                    <div class="spacer"></div>
                    <button mat-flat-button color="primary" (click)="onUserConsentGiven()">{{'yes' |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="spinnerService.show$ | async" class="spinner-back">
    <app-spinner></app-spinner>
</div>