import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Globals } from "../global";
import { FacebookAuthProvider, GoogleAuthProvider, updatePassword } from 'firebase/auth';
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _afAuth: AngularFireAuth,
    private globals: Globals,
    private fns: AngularFireFunctions,
  ) { }

  public loginWithGoogle() {
    return this._afAuth.signInWithPopup(new GoogleAuthProvider);
  }

  public resetPassword(email: string) {
    return this._afAuth.sendPasswordResetEmail(email)
  }

  public loginWithFacebook() {
    return this._afAuth.signInWithPopup(new FacebookAuthProvider());
  }

  public logout() {
    this.globals.clear();
    this._afAuth.signOut();
  }

  public async register(email: string, continueUrl: string): Promise<any> {

    let passData = {
      email: email,
      continueUrl: continueUrl,
    }

    return new Promise((result) => {
      const callable = this.fns.httpsCallable('boardswap-onNewRegister');
      const data = callable(passData);
      firstValueFrom(data)
        .then((resultValue) => {
          console.log('resultValue: ' + JSON.stringify(resultValue));
          result(resultValue);
        })
        .catch((error) => {
          console.error(error);
          result({
            success: false,
          });
        })
    });
  }

  public loginWithPassword(email: string, password: string): Promise<any> {
    return new Promise(async (result) => {
      try {
        let user = (await this._afAuth.signInWithEmailAndPassword(email, password)).user
        console.log(user)
        if (user?.uid) {
          result({
            success: true,
          });
        } else {
          result({
            success: false,
          });
        }
      } catch (error) {
        result({
          success: false,
        });
      }
    })
  }

  public login(email: string, continueUrl: string): Promise<any> {

    let passData = {
      email: email,
      continueUrl: continueUrl,
    }

    return new Promise((result) => {
      const callable = this.fns.httpsCallable('boardswap-onNewLogin');
      const data = callable(passData);
      firstValueFrom(data)
        .then((resultValue) => {
          console.log('resultValue: ' + JSON.stringify(resultValue));
          result(resultValue);
        })
        .catch((error) => {
          console.error(error);
          result({
            success: false,
          });
        })
    });
  }

  public updateUserPassword(password: string): Promise<any> {

    return new Promise(async (result) => {

      try {
        const user = await this._afAuth.currentUser;
        updatePassword(user!, password)
          .then(() => {
            result({
              relogin: false,
              success: true,
            });
          })
          .catch((error) => {
            console.error(error);
            var relogin = false
            if (error.message.toLowerCase().includes("operation is sensitive")) {
              relogin = true
            }
            result({
              relogin: relogin,
              success: false,
            });
          });
      } catch (error) {
        console.error(error);
        result({
          success: false,
          relogin: true
        });
      }
    })
  }
}
