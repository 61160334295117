import { Injectable } from "@angular/core";
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FiresoreAdapter } from "../../../../../src/app/services/firestoreadapter.service";
import { BoardData } from "../models/board";
import { ContactData } from "../models/contact";
import { UserData } from "../models/user";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private db: FiresoreAdapter,
    private fns: AngularFireFunctions,
  ) { }

  public getContacts(userKey: string): Promise<(ContactData)[]> {
    if (userKey == null) {
      return new Promise(async (result) => {
        result([])
      })
    }
    return new Promise(async (result) => {

      var senderKeyQuery = this.db.db.firestore.collectionGroup('contact').where('senderKey', '==', userKey).orderBy('lastMessageTS');

      // Perform the second query where ownerKey matches userKey
      var ownerKeyQuery = this.db.db.firestore.collectionGroup('contact').where('ownerKey', '==', userKey).orderBy('lastMessageTS');

      try {
        var senderQueryResult = (await senderKeyQuery.get()).docs
        var ownerQueryResult = (await ownerKeyQuery.get()).docs

        var queryResult = senderQueryResult.concat(ownerQueryResult);

        const resultData: ContactData[] = queryResult
          .filter(d => d.data() !== null && d.id !== null)
          .map(d => ContactData.toContact(d.data(), d.id))
        result(resultData ?? []);

      } catch (error) {
        console.error(error);
        result([])
      }
    });
  }

  public findContact(boardKey: string, userKey: string): Promise<ContactData | null> {
    return new Promise(async (result) => {
      let senderQueryResult = this.db.db.collection('board').doc(boardKey).collection('contact').ref.where("senderKey", "==", userKey).limit(1).get()

      try {
        const resultData = (await senderQueryResult).docs.map(d =>
          ContactData.toContact(d.data()!, d.id)
        );
        if (resultData.length > 0) {
          result(resultData[0]);
        } else {
          result(null)
        }

      } catch (error) {
        console.error(error);
        result(null)
      }
    })
  }

  public getContact(boardKey: string, contactKey: string): Promise<ContactData | null> {
    return new Promise(async (result) => {
      firstValueFrom(this.db.db.collection('board').doc(boardKey).collection('contact').doc(contactKey).get())
        .then((d) => {
          result(ContactData.toContact(d!.data()!, contactKey));
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    })
  }

  public startContact(user: UserData, boardData: BoardData, message: string, userToken: string | undefined): Promise<any> {

    let passData = {
      userJson: JSON.stringify(UserData.toBasicObject(user)),
      boardKey: boardData.key,
      message: message,
      ownerKey: boardData.ownerKey,
      ownerName: boardData.ownerName,
      ownerMail: boardData.ownerMail,
      boardJson: JSON.stringify(BoardData.toObject(boardData)),
      userToken: userToken,
    }

    return new Promise((result) => {
      const callable = this.fns.httpsCallable('boardswap-onNewRequest');
      const data = callable(passData);
      firstValueFrom(data)
        .then((resultValue) => {
          result(resultValue);
        })
        .catch((error) => {
          console.error(error);
          result({
            success: false,
          });
        })
    });
  }
}
