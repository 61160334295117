<div class="content padval" style="--padding: 8px 16px 0 16px;">

    <div class="vstack dimval" style="--h: 100%;">
        Allgemeine Geschäftsbedingungen<br>
        <br>
        Präambel<br>
        Danke für die Verwendung von „Surfrizz“. „Surfrizz“ ist ein Produkt von&nbsp;<br>
        <br>
        Nikolaus Koller<br>Slavi-Soucek-Straße 18, 5026 Salzburg, Österreich<br>
        <br>
        nachfolgend auch Betreiber genannt.<br>
        <br>
        Durch die Verwendung von „Surfrizz“ erklären Sie sich mit diesen AGB einverstanden. Sollten Sie hierzu
        Fragen haben,
        kontaktieren Sie uns bitte per E-Mail an: admin[AT]surfrizz.com.<br>
        <br>
        Die „Surfrizz“ Anwendung-Nutzer werden nachfolgend als Nutzer bezeichnet. <br>
        Die Anwendung (nachfolgend: „Surfrizz“) ist an alle Personen gerichtet.<br>
        <br>
        1. Leistungen von Surfrizz<br>
        Surfrizz ist eine Anwendung, über die Nutzer Surfboars anbieten können.<br><br>

        Möchte der Nutzer einen Anbieter kontaktieren, muss er sich registrieren. Möchte ein Nutzer ein
        Surfboard zum Angebot einstellen, muss er sich registrieren. Der Verkauf findet nicht über Surfrizz statt.
        Surfrizz bietet nur das Einstellen von Angebote, und die dazugehörige Anfragefunktion.<br><br>
        Die vorstehend genannten Leistungen sind abschließend. Darüber hinaus sind wir nicht zu weiteren
        Leistungen verpflichtet, insbesondere nicht
        zur Erbringung von Installations-, Anpassungs-, Programmier-, Beratungs- und Schulungsleistungen.<br>

        <br>2. Änderung und Beendigung der Leistung<br>
        (1) Surfrizz behält sich das Recht vor, die Leistungen jederzeit in einer dem Nutzer
        zumutbaren Art und Weise zu ändern,
        z.B. um diese weiter zu entwickeln und qualitativ zu verbessern.<br>
        (2) Zudem behält sich Surfrizz das Recht vor, die Dienste vorübergehend oder endgültig einzustellen,
        auch
        ohne die Nutzer individuell zu informieren. Tritt dieser Fall ein, wird Surfrizz eine diesbezügliche Information
        für eine Dauer von drei Monaten
        auf der Homepage https://www.Surfrizz.com bereitstellen.<br>


        <br>3. Verfügbarkeit<br>
        Der Nutzer hat keinen Anspruch auf eine permanente und ununterbrochene Verfügbarkeit von Surfrizz.
        Surfrizz ist jedoch
        bemüht, eine höchstmögliche Verfügbarkeit zu gewährleisten und eventuelle Störungen stets
        schnellstmöglich zu beheben.<br>


        <br>4. Registrierung<br>
        Ist für diverse Features eine Registrierung notwendig, wird der Nutzer dazu aufgefordert.
        (1) Der Nutzer kann sich über E-Mail bei Surfrizz anmelden.<br>
        (2) Der Nutzer ist verpflichtet, seine Zugangsdaten und sein Passwort geheim zu halten und vor
        Missbrauch
        durch Dritte zu schützen.
        Der Kunde hat uns bei Verlust der Zugangsdaten, des Passwortes oder bei Verdacht der missbräuchlichen
        Nutzung dieser Daten unverzüglich
        zu unterrichten. Im Übrigen sind wir berechtigt, bei Missbrauch den Zugang zum Kundenkonto zu sperren.
        Der Kunde haftet bei von ihm zu
        vertretendem Missbrauch.<br>
        (3) Dem Nutzer ist bekannt, dass alle Daten welche er in Surfrizz zur Verfügung stellt, auf
        allen Medien von Surfrizz, wie etwa App,
        Webseite, Facebook oder Instagram öffentlich gestellt werden.<br>
        (4) Ein Nutzer bietet seine Surfboards immer eigenverantwortlich und unabhängig von
        Surfrizz
        an. Surfrizz überprüft zu keiner Zeit die Richtigkeit der Nutzerdaten sowie die eingestellten Angebote.<br>
        (5) Das Anbieten von Boards ist ausschließlich von privat zu privat möglich. Ein einstellen von Angeboten als
        Unternehmer ist ausdrücklich nicht erlaubt.<br>
        (6) Ist ein Löschen der Nutzerdaten via Software (App oder Webseite) nicht möglich, muss
        der Nutzer Surfrizz über die angegebenen Kontaktdaten kontaktieren.
        Eine geeignete Identifizierung der Person ist notwendig. Sowie eine klare
        Information
        zum Löschen der Daten<br>

        <br>5. Haftung<br>
        (1) Surfrizz übernimmt keine Haftung für die Richtigkeit der Nachrichten und Information, die Nutzer
        über Surfrizz einstellen. Für die Richtigkeit der
        Inhalte sind ausschließlich die jeweiligen Nutzer verantwortlich.<br>
        (2) Surfrizz überprüft zu keinem Zeitpunkt die von den Nutzern eingestellten Inhalte auf
        diskriminierende, beleidigende, unseriöse
        und sonstige Teile.<br>
        (3) Die Nutzung von Surfrizz ist von Leistungen Dritter (insbesondere Telekommunikationsanbietern)
        abhängig. Für diese Verfügbarkeit
        übernimmt Surfrizz keine Haftung.<br>
        (4) Der Nutzer haftet im vollen Umfang für seine Angebote
        mit
        Nutzern und Richtigkeit der Daten.
        Surfrizz übernimmt hierfür keine Haftung. <br>
        (5) Surfrizz haftet für sämtliche sich im Zusammenhang mit diesem Vertrag ergebenden Schäden, gleich aus
        welchem tatsächlichen oder rechtlichen
        Grund nur nach Maßgabe der folgenden Vorschriften:<br>
        1. Bei Vorsatz und grober Fahrlässigkeit, Ansprüchen nach dem Produkthaftungsgesetz haften wir
        unbeschränkt nach den gesetzlichen Vorschriften<br>
        2. Bei einer Verletzung des Lebens, des Körpers oder der Gesundheit haften wir unbeschränkt nach den
        gesetzlichen Vorschriften, sofern dies im Rahmen der Nutzung von Surfrizz
        wie in den Leistungen beschrieben ist.<br>
        3. Im Übrigen beschränkt sich die Haftung pro Kalenderjahr auf den bei Vertragsschluss vorhersehbaren
        Schaden bis zu einem Gesamtbetrag
        für alle Schadensfälle pro Kalenderjahr, der 50% der in diesem Kalenderjahr vom Nutzer gezahlten
        Vergütung entspricht. Diese
        Haftungsbeschränkung gilt auch für den Fall des Datenverlusts und der Datenverschlechterung.<br>

        <br>6. Verantwortlichkeit und verbotene Inhalte
        <br>(1) Der Nutzer ist alleine für die von ihm eingestellten Inhalte sowie für die Kommunikation mit
        anderen (sowohl öffentlich als
        auch nicht-öffentlich) verantwortlich. Der Nutzer ist verpflichtet, die Rechte und Interessen der
        anderen Nutzer oder sonstiger Dritter,
        insbesondere deren Persönlichkeitsrechte, zu wahren.
        <br>(2) Der Nutzer verpflichtet sich, auf Surfrizz und insbesondere im Chat keine Inhalte einzustellen,
        die gegen gesetzliche Bestimmungen
        verstoßen. Verboten sind insbesondere folgende Inhalte:<br>
        Inhalte, die gegen gesetzliche Verbote, die guten Sitten und/oder Rechte Dritter (Marken-, Namens-,
        Urheber-, Datenschutzrechte usw.) verstoßen,
        sind unzulässig. Dies gilt insbesondere für diskriminierende, sexistische, pornografische, rassistische
        und zu Gewalt oder zu einer Straftat
        auffordernde Inhalte, aber auch persönliche Bedrohungen und Beleidigungen.<br>
        Unzulässig sind gewerbliche Inhalte, Handy-/Telefonkarten- bzw. Geldgesuche, URLs, Fremdsprachen,
        Adressen sowie Telefonnummern jeder Art, außer
        den Nummern/ Urls, die über die möglichen Funktionen eingestellt werden.<br>
        Unzulässig sind alle Inhalte, die der Umgehung der oben genannten Beschränkungen dienen. Dies gilt für
        Nicknamen, für sämtliche Texte,
        Bilder/Videos, die eingegeben oder hochgeladen werden.<br>
        Dabei ist es gleichgültig, ob derartige Inhalte in der Kommunikation mit anderen Nutzern oder auf der
        Profilseite des Nutzers verbreitet
        werden. Gleichgültig ist auch, ob sich derartige Inhalte gegen andere Nutzer von Surfrizz , gegen
        Surfrizz-Mitarbeiter oder gegen sonstige
        Personen oder Unternehmen richten. Unzulässig sind auch Hyperlinks zu derartigen Inhalten. Die
        Entscheidung über die Zulässigkeit des Inhalts
        liegt im Ermessen des Betreibers. Außerdem behält sich der Betreiber vor,
        die eingesendeten Texte sowie Bilder/Videos zu löschen.
        <br>Zusätzliche Hinweise für Bilder:
        <br>Der Betreiber
        respektiert das geistige Eigentum
        Dritter, die Integrität der Persönlichkeit sowie die Wahrung der Persönlichkeitssphäre, insbesondere der
        in den Inhalten dargestellten Personen,
        und fordert seine Nutzer auf, dies ebenfalls zu tun. Unzulässig im Rahmen des Uploads sind Bilder von
        nicht bekleideten Personen. Abgebildete
        Nutzer müssen mindestens Bikini bzw. blickdichte Unterwäsche (es dürfen keine primären und/oder
        sekundären Geschlechtsmerkmale zu sehen sein)
        tragen. Bilder, die die Grenzen des guten Geschmacks überschreiten, insbesondere Bilder, auf denen
        Exkremente oder Körperflüssigkeiten abgebildet
        sind oder die Personen mit abnormalen Verhaltensweisen oder Lebensumständen darstellen, sind nicht
        erlaubt. Außerdem dürfen eingestellte Inhalte
        nicht gegen gesetzliche Bestimmungen oder die guten Sitten verstoßen. Insbesondere dürfen Inhalte nicht
        bedrohend, missbräuchlich, rassistisch,
        gewaltverherrlichend, verleumderisch, belästigend, anstößig, pornografisch, sexistisch oder sonst wie
        jugendgefährdend sein.
        Mit Blick auf die Teilnahmeberechtigungen entscheidet der Betreiber allein aufgrund des äußeren
        Erscheinungsbildes der abgebildeten Person(en)
        bzw. der abgebildeten Darstellungen oder Dinge über die Veröffentlichung der Bilder. Unzulässige Inhalte
        werden nicht freigegeben bzw. veröffentlicht, d.h. sie werden gelöscht.
        <br>(3) Die Nutzun von Surfrizz ist ausschließlich für Zwecke der Surfboard Amgenote. Der Nutzer verpflichtet
        sich
        daher:<br>
        die Dienste von Surfrizz auch nur ausschließlich für diese Zwecke zu nutzen,<br>
        keine Inhalte einzustellen, die andere kommerzielle Interessen verfolgen (beispielsweise Spamming),<br>
        keine wettbewerbswidrigen Handlungen vorzunehmen oder zu fördern, einschließlich progressiver
        Kundenwerbung (wie Ketten-, Schneeball- oder Pyramidensysteme),<br>
        Surfrizz nicht für sonstige belästigende Handlungen gegenüber anderen Nutzern oder Mitarbeitern von
        Surfrizz zu nutzen (z.B. Stalking),<br>
        keinen Versuch zu unternehmen, unberechtigten Zugriff auf Daten Dritter zu erlangen oder durch
        technische Einrichtungen oder sonstige Maßnahmen
        die Funktion von Surfrizz zu beeinträchtigen und<br>
        die internen Kommunikationssysteme von Surfrizz nicht für vom Empfänger unerwünschte Nachrichten
        (beispielsweise Spamming), Kettenbriefe o.ä. zu missbrauchen.<br>
        Unzulässig sind zudem der Einsatz von Skripten bei der Nutzung von Surfrizz und das automatisierte
        Abrufen von Inhalten auf Surfrizz.
        <br>(4) Der Nutzer ist dafür verantwortlich, dass die von ihm auf der Profilseite oder sonst auf
        Surfrizz
        eingestellten Inhalte, frei von Viren,
        Würmern, Trojanern oder sonstigen Programmen sind, die die Funktionsfähigkeit oder den Bestand von
        Surfrizz oder anderen Webseiten gefährden oder beeinträchtigen können.
        <br>(5) Der Nutzer ist verpflichtet, vor der Einstellung von Inhalten auf Surfrizz sorgfältig zu prüfen,
        ob die vorstehenden Voraussetzungen erfüllt sind.


        <br><br>7. Nutzung von Inhalten und Rechten Dritter
        <br>(1) Dem Nutzer ist bekannt, dass die von ihm eingestellten Inhalte mit Einstellung vervielfältigt
        und über Surfrizz verbreitet und für Dritte
        öffentlich zugänglich gemacht werden, insbesondere durch die Möglichkeit, diese Inhalte über geeignete
        Endgeräte (z.B. PC, Notebook, Mobiltelefon,
        Smartphone, etc.) abzurufen und herunterzuladen. Hierdurch werden Dritten Nutzungs- und
        Vervielfältigungshandlungen möglich, die vom Nutzer oder
        vom Betreiber nicht mehr kontrolliert werden können. Dem Nutzer ist ferner bekannt, dass er seine
        Inhalte
        auf einer Plattform einstellt, die zu
        einem späteren Zeitpunkt auch Werbung der unterschiedlichsten Art enthalten kann. Hierzu zählt
        insbesondere auch verhaltens- und ggf. ortsbasierte
        Werbung sowie angepasste Werbung. Der Nutzer wird dann die Möglichkeit haben, der Ausspielung von
        angepasster Werbung durch entsprechende Einstellungen
        zu widersprechen. Dem Nutzer ist schließlich bekannt, dass seine Inhalte zur Einstellung unter Surfrizz
        technisch aufbereitet werden (z.B. Anpassen des
        Formats oder Änderung der Auflösung von Bildern).
        <br>(2) Der Nutzer trägt die Verantwortung dafür, dass durch das Einstellen seiner Inhalte keine Rechte
        Dritter, wie insbesondere Persönlichkeitsrechte, Urheber- und Leistungsschutzrechte oder sonstige
        Rechte, verletzt werden. Der Nutzer ist verpflichtet,
        vor der Einstellung von Inhalten sorgfältig zu prüfen, ob durch das Einstellen von Inhalten auf Surfrizz
        Rechte Dritter verletzt werden. Der Nutzer ist
        für die Inhalte vollständig selbst verantwortlich und stellt Surfrizz und deren Eigentümer im Falle von
        Rechtsverletzungen diesbezüglich frei.
        <br>(3) Der Betreiber ist für Nutzungshandlungen anderer Nutzer von Surfrizz oder sonstiger Dritter
        nicht
        verantwortlich.


        <br><br>8. Folgen bei Verstoß gegen Ziffer 6 und 7 dieser AGB, Schadensersatz und Freistellung des
        Betreibers durch den Nutzer
        <br>(1) Dem Nutzer ist bekannt, dass alle Chat-Nachrichten, Bilder sowie einzelne Bestandteile des
        Profils vom Betreiber zu jeder Zeit überprüft und ggf. zensiert werden.
        <br>(2) Liegen tatsächliche Anhaltspunkte vor, die den Verdacht begründen, dass vom Nutzer eingestellte
        Inhalte (insbesondere auf den Profilseiten und in
        der öffentlichen Kommunikation mit anderen Nutzern) nicht den Anforderungen in Ziffer 6 dieser AGB
        genügen oder Rechte Dritter verletzen, ist der Betreiber
        ohne Weiteres berechtigt:<br>
        einzelne Textinhalte oder Profilangaben zu löschen,<br>
        vollständige Texte bzw. Chat-Nachrichten, Profilangaben oder Bilder zu löschen,<br>
        den Nutzer für einen Tag oder einen Monat zu sperren,<br>
        oder den Nutzer dauerhaft (für immer) zu sperren.<br>
        Eine Sperrung kann auch in Fällen erfolgen, in denen ein Nutzer wiederholt ungerechtfertigt andere
        Chatter oder Verstöße im Chat meldet. Bei der Entscheidung
        über die im Einzelnen zu treffende Maßnahme wird der Betreiber die berechtigten Interessen des Nutzers
        sowie die Schwere des Verstoßes berücksichtigen.
        Ungeachtet der Berechtigung, solche Inhalte zu sperren oder zu entfernen, bleibt der Betreiber
        berechtigt, das Vertragsverhältnis mit dem Nutzer insgesamt
        unter den Voraussetzungen der Ziffer 8 dieser AGB zu kündigen und/oder sonstige Ansprüche (insbesondere
        Schadensersatzansprüche) gegen diesen geltend zu
        machen. Zudem ist der Betreiber bei Straftaten berechtigt, diese anzuzeigen.
        <br>(3) Der Nutzer ist dem Betreiber zum Ersatz der Schäden verpflichtet, die dem Betreiber aus einer
        Verletzung der Pflichten des Nutzers entstehen, es
        sei denn, der Nutzer hat die Pflichtverletzung nicht zu vertreten. Der Nutzer stellt den Betreiber
        insbesondere von sämtlichen Ansprüchen Dritter frei, die
        sich aus einer Verletzung der Pflichten des Nutzers aus diesen AGB ergeben, es sei denn, der Nutzer hat
        die Pflichtverletzung nicht zu vertreten<br>

        <br>
        9. Kosten, Preise und Kündigung
        <br>(1)Das Nutzen der Software und das Registrieren als Nutzer ist
        kostenfrei.
        (2) Das Anbieten von Boards kann jederzeit kostenpflichtig werden. Wird aber dann für den Nutzer klar erkennbar
        und vor dem Einstellen eines Angebotes sein.

        <br><br>
        10. Supportleistungen<br>
        Surfrizz ist nicht verpflichtet, Supportleistungen zu erbringen:<br>
        • bei Fehlern, die auf unzulässigen Änderungen oder Anpassungen der App beruhen;<br>
        • für andere Software (insbesondere Fremdsoftware, die auf Kundensystemen eingesetzt wird);<br>
        • bei Fehlern, die auf unsachgemäßer oder nicht autorisierter Nutzung der App-Lösung oder auf<br>
        Bedienungsfehlern beruhen;<br>
        • bei jeglichen Hardwaredefekten;<br>
        • in Form von Vor-Ort-Einsätzen von unseren Mitarbeitern<br>
        Wir sind berechtigt, solche Leistungen als gesonderte Beauftragung zu behandeln und entsprechend
        den jeweils gültigen Dienstleistungssätzen in Rechnung zu stellen.<br>
        <br>
        12. Vertragsübernahme<br>
        Wir sind berechtigt, mit einer Ankündigungsfrist von vier 4 Wochen Rechte und Pflichten aus diesem
        Vertragsverhältnis ganz oder
        teilweise auf einen Dritten zu übertragen. In diesem Fall ist der Nutzer berechtigt, den Vertrag
        innerhalb von zwei Wochen nach
        Anzeige der Vertragsübernahme zu kündigen.<br>
        <br>
        13. Änderungen dieser Geschäftsbedingungen<br>
        Die Geltung entgegenstehender oder abweichender Geschäftsbedingungen ist ausgeschlossen, auch wenn wir
        ihnen nicht ausdrücklich
        widersprechen oder Leistungen vorbehaltlos annehmen. Wir behalten uns nach Maßgabe der nachfolgenden
        Bestimmungen das Recht vor,
        diese allgemeinen Geschäftsbedingungen zu ändern, sofern diese Änderung unter Berücksichtigung der
        Interessen von uns für Sie zumutbar ist;
        dies ist insbesondere der Fall, wenn die Änderung für Sie ohne wesentliche rechtliche oder
        wirtschaftliche Nachteile ist, z.B. bei
        Veränderungen im Registrierungsprozess, Änderungen von Kontaktinformationen. Im Übrigen werden wir Sie
        vor einer Änderung dieser
        Geschäftsbedingungen mit angemessenem Vorlauf, mindestens jedoch einen Monat vor dem beabsichtigten
        Inkrafttreten informieren. Die
        Information erfolgt an die von Ihnen benannte Email- Adresse. Sollten Sie mit einer von uns
        beabsichtigten Änderung nicht einverstanden
        sein, haben Sie das Recht, der Änderung innerhalb eines Monats nach Mitteilung zu widersprechen. Wenn
        Sie fristgerecht widersprechen,
        sind wir berechtigt, den Vertrag mit einer Frist von einem Monat zum Ende des Kalendermonats schriftlich
        zu kündigen.<br>

        <br>14. Datenschutz<br>
        Der Nutzer stimmt mit der Nutzung von Surfrizz explizit der Anwendung der unter
        https://www.Surfrizz.com/privacy veröffentlichen Erläuterungen zum
        Datenschutz einschließlich der dort beschriebenen Methoden und Verfahren zu.<br>

        15. Gerichtsstand<br>
        Erfüllungsort und Gerichtsstand für Kaufleute, juristischen Personen des öffentlichen Rechts oder
        öffentlich-rechtlichen Sondervermögen
        ist Salzburg. Es gilt ausschließlich österreichisches Recht.<br><br>

        16. Schlussbestimmungen <br>
        Sofern eine Bestimmung dieser AGB ganz oder teilweise unwirksam ist oder wird, bleiben die übrigen
        Bestimmungen davon unberührt.<br>
        <br>

        <a href="../../../../../assets/docs/agb.pdf">Download PDF</a>
    </div>
</div>