import { provideHttpClient } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { REGION } from "@angular/fire/compat/functions";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { routes } from "./app.routes";
import { environment } from "../environments/environment.prod";
import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: REGION, useValue: 'europe-west1' },
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom([
      AngularFireModule.initializeApp(environment.firebase, { automaticDataCollectionEnabled: false }),
      AngularFirestoreModule,
      AngularFireAnalyticsModule
    ]),
    provideHttpClient(),
  ],
};