<div class="vstack">
    <app-header (buttonClick)="onActionButton()" [showBack]="false"></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">
        <div class="vstack">

            <!--photo-->
            <div class="spacer-s"></div>
            <div class="hstack accent-3-verylight maval" style=" --margin: 0 auto 32px 0;">
                <div class="zstack center">
                    <div *ngIf="!imageUrl" class="imgval radiusval" style="--h: 200px; --radius: 3px;"
                        onclick="document.getElementById('file1').click()">
                        <img src='../../../../assets/photos/placeholder1.png' alt="">
                    </div>
                    <div class="imgval radiusval" style="--w: 100%; --h: 200px; --radius: 3px;"
                        onclick="document.getElementById('file1').click()">
                        <img src='{{imageUrl}}' alt="">
                    </div>
                    <label for="file1">
                        <span class="upload-icon material-symbols-outlined">
                            add_photo_alternate
                        </span>
                        <input class="file-upload" type="file" name="file1" id="file1" (change)="selectFile($event)">
                    </label>
                </div>
            </div>

            <!--values-->
            <h3 class="superbold accent-2">{{ 'ud-userdata-values-title' | translate }}</h3>
            <div class="spacer-xs"></div>
            <h6 class="">{{ user?.email }}</h6>

            <div class="spacer-xl"></div>
            <div *ngIf="registerForm != undefined">
                <form [formGroup]="registerForm" novalidate>
                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'ud-first-name' | translate }}</mat-label>
                        <input maxlength="20" matInput formControlName="firstName" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'ud-second-name' | translate }}</mat-label>
                        <input maxlength="20" matInput formControlName="secondName" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'ud-about-you' | translate }}</mat-label>
                        <textarea matInput maxlength="500" rows="8" formControlName="about" required></textarea>
                    </mat-form-field>

                    <!-- 
                    <h5>Optional</h5>
                    <div class="spacer-xl"></div>    
                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'phone' | translate }}</mat-label>
                        <input maxlength="20" matInput formControlName="phone">
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'street' | translate }}</mat-label>
                        <input maxlength="50" matInput formControlName="streetNr">
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'city' | translate }}</mat-label>
                        <input maxlength="20" matInput formControlName="city">
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'post' | translate }}</mat-label>
                        <input maxlength="10" matInput formControlName="post">
                    </mat-form-field>

                    <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                        <mat-label>{{ 'country' | translate }}</mat-label>
                        <input maxlength="20" matInput formControlName="country">
                    </mat-form-field>-->
                </form>
            </div>
            <div class="hstack">
                <div class="spacer"></div>
                <button mat-flat-button color="primary" (click)="onActionButton()">
                    <span>{{'save' | translate}}</span>
                </button>
            </div>
            <div class="spacer-xxl"></div>
            <div class="spacer-xxl"></div>

            <div class="divider"></div>
            <div class="spacer-xxl"></div>

            <h3 class="superbold accent-2">{{'ud-setpw-headline' | translate}}</h3>
            <div class="spacer-xs"></div>
            <p class="">{{'ud-setpw-text' | translate}}</p>
            <div class="spacer-xl"></div>
            <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                <mat-label>{{'ud-setpw-field' | translate }}</mat-label>
                <input matInput [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="password" maxlength="20"
                    name="password">
                <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="'Toggle password visibility'" [attr.aria-pressed]="!hidePassword">
                    <mat-icon *ngIf="!hidePassword"><span class="material-symbols-outlined">
                            visibility
                        </span></mat-icon>
                    <mat-icon *ngIf="hidePassword"><span class="material-symbols-outlined">
                            visibility_off
                        </span></mat-icon>
                </button>
            </mat-form-field>
            <div class="hstack">
                <div class="spacer"></div>
                <button mat-flat-button color="primary" (click)="onUpdatePassword()">
                    <span>{{'save' | translate}}</span>
                </button>
            </div>

            <div class="spacer-xxl"></div>
            <div class="spacer-xxl"></div>
            <div class="divider"></div>
            <div class="spacer-xxl"></div>
            <h3 class="superbold accent-2">{{'ud-edit-account' | translate}}</h3>
            <div class="spacer-l"></div>
            <h6 class="superbold">Google analytics</h6>
            <div class="spacer-l"></div>
            <p>{{'analytics-question' | translate}}</p>
            <div class="spacer-xxl"></div>
            <div class="hstack">
                <button [disabled]="!this.consentService.checkConsent()" mat-flat-button color="primary"
                    (click)="onUserConsentDenied()">{{'no' | translate}}</button>
                <div class="spacer"></div>
                <button [disabled]="this.consentService.checkConsent()" mat-flat-button color="primary"
                    (click)="onUserConsentGiven()">{{'yes' | translate}}</button>
            </div>
            <div class="spacer-xxl"></div>
            <div class="divider"></div>
            <div class="spacer-xxl"></div>
            <h6 class="superbold">{{'ud-delete-headline' | translate}}</h6>
            <div class="spacer-l"></div>
            <div class="hstack">
                <div class="vstack">
                    <mat-slide-toggle [(ngModel)]="enableAccountDelete">{{'ud-enable-deletion' |
                        translate}}</mat-slide-toggle>
                    <div class="spacer-m"></div>
                    <button [disabled]="!enableAccountDelete" mat-flat-button color="primary" (click)="deleteAccount()">
                        <span>{{'ud-delete-account' | translate}}</span>
                    </button>
                </div>
            </div>

            <div class="spacer-xxl"></div>
            <div class="spacer-xxl"></div>
            <div class="spacer-xxl"></div>
        </div>
    </div>
</div>