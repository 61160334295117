import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { ListElementComponent } from "../../../../../../rooms/src/app/views/offer/view-elements/list-element/list-element.component";
import { SeachParam } from '../../../enums/searchparam';
import { SearchData } from '../../../models/search';
import { FooterComponent } from "../../shared/footer/footer.component";
import { HeaderComponent } from "../../shared/header/header.component";
import { LoginStateFieldComponent } from '../../shared/login-state-field/login-state-field.component';
import { FilterBoardsComponent } from '../filter-boards/filter-boards.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BoardListViewModel } from '../../../viewmodels/boardlist-viewmodel.service';
import { Subscription } from 'rxjs';
import { Availability } from '../../../models/availability';
import { TranslateService } from '../../../localization/translate.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-board-list',
  standalone: true,
  templateUrl: './board-list.component.html',
  styleUrl: './board-list.component.scss',
  imports: [
    PipesModule,
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    HeaderComponent
  ]
})
export class BoardListComponent implements OnInit, OnDestroy {

  Availability = Availability
  Date = Date

  actionName = "filter_list"
  titleName = this.translateService.translate('bl-new-boards')
  filterName = this.translateService.translate('bl-filtered')

  static searchData: SearchData | null = null
  get searchData() { return BoardListComponent.searchData }

  noBoardsFound: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);

  private subscription: Subscription | null = null;

  scaleValue = 1
  showText = ""
  static visitors = "1"
  showRegister = true

  constructor(
    private overlay: Overlay,
    public router: Router,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    public viewModel: BoardListViewModel,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('BoardList')
    analytics.logEvent("Open-BoardList")

    BoardListComponent.visitors = localStorage.getItem('remval') ?? "1"
    if (BoardListComponent.visitors == "1") {
      this.collectVisitors()
    } else {
      setTimeout(() => {
        this.collectVisitors()
      }, 1000 * 30);
    }
    this.changeAd()
  }

  collectVisitors() {
    const now = new Date();
    const hours = now.getHours();
    var factor = 20

    if (hours >= 23 || hours < 6) {
      factor = 1
    } else if (hours >= 6 && hours < 12) {
      factor = 6
    } else if (hours >= 12 && hours < 17) {
      factor = 10
    } else if (hours >= 17 && hours < 23) {
      factor = 16;
    }

    BoardListComponent.visitors = "" + (Math.floor(Math.random() * factor) + 2);

    localStorage.setItem('remval', BoardListComponent.visitors);

    setTimeout(() => {
      this.collectVisitors()
    }, 1000 * 60 * 5);
  }

  changeAd() {

    this.scaleValue = 0

    setTimeout(() => {
      this.showRegister = !this.showRegister

      if (this.showRegister) {
        this.showText = this.translateService.translate('bl-promote-register')
      } else {
        this.showText = BoardListComponent.visitors + ' ' + this.translateService.translate('bl-visitors')
      }

      this.scaleValue = 1
      setTimeout(() => {
        this.changeAd()
      }, 1000 * 10);
    }, 1000);
  }

  ngOnInit(): void {
    this.initData()
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  initData() {

    this.spinnerService.show()

    this.subscription = this.viewModel.boards.subscribe(boards => {
      // Check if the boards array is empty
      if (boards == null || boards?.length === 0) {
        this.noBoardsFound.next(true);
      } else {
        this.noBoardsFound.next(false);
      }

      this.spinnerService.hide()
    });


    this.viewModel.resetData()
    if (this.router.url.includes('myboards')) {
      BoardListComponent.searchData = null
      this.actionName = 'add'
      this.titleName = this.translateService.translate('bl-my-boards')
      this.viewModel.loadUserBoards()
    } else {
      this.actionName = "filter_list"
      this.titleName = this.translateService.translate('bl-new-boards')
      this.searchBoard()
    }
  }

  firstPhoto(photos: any[]): string | null {
    if (photos.length > 0) {
      return photos[0]['data'] ?? null
    }
    return null
  }

  onActionButton() {
    if (this.router.url.includes('myboards')) {
      this.router.navigate(['/add']);
    } else {
      this.openOverlay()
    }
  }

  onRemoveLengthFilter() {
    BoardListComponent.searchData!.fromLength = null
    BoardListComponent.searchData!.toLength = null
    this.searchBoard()
  }
  onRemoveVolumeFilter() {
    BoardListComponent.searchData!.fromVol = null
    BoardListComponent.searchData!.toVol = null
    this.searchBoard()
  }
  onRemovePriceFilter() {
    BoardListComponent.searchData!.fromPrice = null
    BoardListComponent.searchData!.toPrice = null
    this.searchBoard()
  }
  onRemoveLocationFilter() {
    BoardListComponent.searchData!.location = null
    this.searchBoard()
  }

  searchBoard() {
    this.spinnerService.show()
    this.viewModel.searchBoards(
      BoardListComponent.searchData?.fromLength ?? null,
      BoardListComponent.searchData?.toLength ?? null,
      BoardListComponent.searchData?.fromVol ?? null,
      BoardListComponent.searchData?.toVol ?? null,
      BoardListComponent.searchData?.location ?? null,
      BoardListComponent.searchData?.fromPrice ?? null,
      BoardListComponent.searchData?.toPrice ?? null
    )
    this.spinnerService.hide()
  }

  openOverlay() {

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const filePreviewPortal = new ComponentPortal(FilterBoardsComponent);

    // Attach ComponentPortal to PortalHost
    const ref = overlayRef.attach(filePreviewPortal);
    ref.instance.closePanel.subscribe(() => overlayRef.detach());
    ref.instance.searchData = BoardListComponent.searchData ?? new SearchData()
    ref.instance.onSearch = (searchData: SearchData) => {
      BoardListComponent.searchData = searchData
      this.searchBoard()
    }

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
    });
  }
}
