import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { ListElementComponent } from "../../../../../../rooms/src/app/views/offer/view-elements/list-element/list-element.component";
import { SearchData } from '../../../models/search';
import { FooterComponent } from "../../shared/footer/footer.component";
import { HeaderComponent } from "../../shared/header/header.component";
import { LoginStateFieldComponent } from '../../shared/login-state-field/login-state-field.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BoardListViewModel } from '../../../viewmodels/boardlist-viewmodel.service';
import { Subscription } from 'rxjs';
import { Availability } from '../../../models/availability';
import { TranslateService } from '../../../localization/translate.service';
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-board-lister-list',
  standalone: true,
  templateUrl: './board-lister-list.component.html',
  styleUrl: './board-lister-list.component.scss',
  imports: [
    PipesModule,
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    LanguageButtonComponent,
    LoginStateFieldComponent,
    ListElementComponent,
    HeaderComponent,
    FooterComponent
  ]
})
export class BoardListerListComponent implements OnInit, OnDestroy {

  Availability = Availability
  Date = Date

  titleName = ""

  static listerKey: string | null = null

  noBoardsFound: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);

  private subscription: Subscription | null = null;

  constructor(
    private snackbarService: SnackbarService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    public viewModel: BoardListViewModel,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('BoardListerList')
    analytics.logEvent("Open-BoardListerList")

    this.route.queryParams.subscribe(params => {
      BoardListerListComponent.listerKey = params['lkey'];
    })
  }

  ngOnInit(): void {
    this.initData()
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  initData() {

    if (!BoardListerListComponent.listerKey) {
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }

    this.spinnerService.show()

    this.subscription = this.viewModel.boards.subscribe(boards => {
      // Check if the boards array is empty
      if (boards == null || boards?.length === 0) {
        this.noBoardsFound.next(true);
      } else {
        this.noBoardsFound.next(false);
        this.titleName = boards[0].ownerName ?? ""
      }

      this.spinnerService.hide()
    });


    this.viewModel.resetData()
    this.titleName = ""
    this.viewModel.loadListerBoards(BoardListerListComponent.listerKey)
  }

  firstPhoto(photos: any[]): string | null {
    if (photos.length > 0) {
      return photos[0]['data'] ?? null
    }
    return null
  }
}
