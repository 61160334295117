import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment.prod';
import { NotificationData } from './models/notification';
import { UserData } from './models/user';


@Injectable({
  providedIn: 'root',
})
export class Globals {

  public user: UserData | null = null

  public language: string = "en-US"

  // For key for data encryption.
  public cryptKey = '';

  public notification: BehaviorSubject<NotificationData | null> = new BehaviorSubject<NotificationData | null>(null)

  public framewidth = 600
  public frameHeight = 900

  constructor(
  ) {
    let language = navigator.language
    if (language.startsWith("en")) {
      this.language = "en-US"
    } else if (language.startsWith("de")) {
      this.language = "de-DE"
    } else if (language.startsWith("fr")) {
      this.language = "fr-FR"
    } else if (language.startsWith("es")) {
      this.language = "es-ES"
    } else if (language.startsWith("pt")) {
      this.language = "pt-PT"
    } else {
      this.language = "en-US"
    }

    this.frameHeight = window.innerHeight

    if (window.innerWidth > 600) {
      this.framewidth == 600
    } else {
      this.framewidth = window.innerWidth
    }
  }

  public baseUrl(): String {
    if (environment.production) {
      return 'https://surfrizz.com'
    }

    return 'https://develop.surfrizz.com' //'http://localhost:4200'; //
  }

  public clear() {
    if (typeof window !== 'undefined') {
      // window.localStorage.removeItem('emailForSignIn');
    }
    console.log('clear user')
    this.user = null;
  }
}
