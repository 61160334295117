import { LocationData } from "./location"

export class SearchData {
    toPrice: number | null = null
    fromPrice: number | null = null
    fromVol: number | null = null
    toVol: number | null = null
    fromLength: number | null = null
    toLength: number | null = null
    location: LocationData | null = null

    hasData() {
        return this.toPrice || this.fromLength || this.fromPrice || this.fromVol || this.toLength || this.toVol || this.location
    }
}
