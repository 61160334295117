import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { DirectivesModule } from '../../../directives/directives.module';
import { Globals } from '../../../global';

@Component({
  selector: 'app-image-gallery',
  standalone: true,
  imports: [
    CommonModule,
    DirectivesModule
  ],
  templateUrl: './image-gallery.component.html',
  styleUrl: './image-gallery.component.scss'
})
export class ImageGalleryComponent implements OnInit {

  constructor(
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.showImage(ImageGalleryComponent.currentIndex);
  }

  @Output() closePanel = new EventEmitter<void>();

  static images: string[] = [];
  get images() { return ImageGalleryComponent.images; }

  currentImage: string = ImageGalleryComponent.images[0];
  static currentIndex: number = 0;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      this.showNextImage();
    } else if (event.key === 'ArrowLeft') {
      this.showPreviousImage();
    }
  }

  showImage(index: number) {
    ImageGalleryComponent.currentIndex = index;
    this.currentImage = ImageGalleryComponent.images[ImageGalleryComponent.currentIndex];
  }

  close() {
    this.closePanel.emit();
  }

  showNextImage() {
    ImageGalleryComponent.currentIndex = (ImageGalleryComponent.currentIndex + 1) % ImageGalleryComponent.images.length;
    this.currentImage = ImageGalleryComponent.images[ImageGalleryComponent.currentIndex];
  }

  showPreviousImage() {
    ImageGalleryComponent.currentIndex = (ImageGalleryComponent.currentIndex - 1 + ImageGalleryComponent.images.length) % ImageGalleryComponent.images.length;
    this.currentImage = ImageGalleryComponent.images[ImageGalleryComponent.currentIndex];
  }
}
