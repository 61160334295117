<div class="vstack">
    <app-header [showBack]=true></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">
        <div class="vstack">
            <div class="hstack">
                <h5 class="sec-color superbold">{{ titleName }}</h5>
                <div class="spacer"></div>
            </div>
            <div class="spacer-s"></div>
            <div *ngIf="noBoardsFound | async">
                <div class="spacer-xxl"></div>
                <h5>{{'bl-no-boards-found' | translate}}</h5>
            </div>
            <div *ngFor="let board of viewModel.boards | async">
                <a [routerLink]="['/board']" [queryParams]="{bkey: board.key}">

                    <div class="maval" style="--margin: 8px 0 8px 0">
                        <div class="hstack dimval" style="--h: 200px; --w: 100%;">
                            <div class="zstack">
                                <div class="imgval radiusval" style="--h: 200px;--w: 130px;--radius:8px 0 0 8px">
                                    <img *ngIf="firstPhoto(board.photos)" src={{firstPhoto(board.photos)}}
                                        alt="Photo of {{board.title}}">
                                    <img *ngIf="!firstPhoto(board.photos)"
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        class="accent-1-verylight" alt="">
                                </div>
                                <div class="vstack dimval" style="--h: 100%; --w: 100%;">
                                    <div class="spacer"></div>
                                    <p class="tag-elements unavailable" *ngIf="board.publish == false">
                                        {{
                                        'ab-not-published' | translate }}</p>
                                    <p class="tag-elements unavailable" *ngIf="board.availability == Availability.sold">
                                        {{
                                        board.availability | available }}</p>
                                    <p class="tag-elements reserved"
                                        *ngIf="board.availability == Availability.reserved">{{
                                        board.availability | available }}</p>
                                    <div class="spacer"
                                        *ngIf="board.availability == Availability.sold || board.availability == Availability.reserved || board.publish == false">
                                    </div>
                                    <div
                                        *ngIf="board.availability == Availability.availableFrom && (board.availableFrom ?? 0) > Date.now()">
                                        <p class="tag-elements-date"> {{ 'bl-from' | translate}} {{
                                            board.availableFrom | date }} <span *ngIf="board.availableTo"> - {{
                                                board.availableTo | date }}</span> </p>
                                    </div>
                                </div>
                            </div>

                            <div class=" dimval vstack padval accent-3-verylight"
                                style="--h: 100%; --w: 100%; --padding: 16px;">

                                <h5 class="superbold accent-1">{{ board.title }}</h5>
                                <div class="spacer-xs"></div>
                                <h4 class="superbold accent-2">{{ board.subtitle }}</h4>
                                <p class="spacer"></p>

                                <div class="hstack">
                                    <p class="spacer"></p>
                                    <h3 class="hand">{{board.length | cmToFeetInch}}
                                        <span *ngIf="board.volume">| {{ board.volume }} Liter</span>
                                    </h3>
                                </div>
                                <!--<p>{{ board.aboutOffer| slice:0:50}}..</p>-->
                                <p class="divider"></p>
                                <p class="spacer-m"></p>
                                <div class="hstack">
                                    <h6 class="">
                                        <span *ngIf="(board.location?.length ?? 0) > 20">{{ board.location|
                                            slice:0:20}}..</span>
                                        <span *ngIf="(board.location?.length ?? 0) <= 20">{{ board.location
                                            }}</span>
                                    </h6>
                                    <p class="spacer"></p>
                                    <h6 class=""><span>{{board.price}}€</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>