import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HeaderComponent } from "../../shared/header/header.component";
import { SupporterDetailViewModel } from '../../../viewmodels/supporterdetail-viewmodel.service';
import { CommonModule } from '@angular/common';
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { PipesModule } from '../../../../../../../src/app/pipes/pipes.module';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-supporterdetail',
  standalone: true,
  imports: [HeaderComponent, RouterModule, CommonModule, PipesModule, AngularMaterialModule],
  templateUrl: './supporterdetail.component.html',
  styleUrl: './supporterdetail.component.scss'
})
export class SupporterdetailComponent implements OnInit {

  static supporterId: string | null = null
  get supporterId() { return SupporterdetailComponent.supporterId }

  static password: string | null = null
  get password() { return SupporterdetailComponent.password }

  static supporterData: any = null
  get supporterData() { return SupporterdetailComponent.supporterData }

  constructor(
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private viewModel: SupporterDetailViewModel,
    private snackbarService: SnackbarService,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('SupporterdetailComponent')
    analytics.logEvent("Open-SupporterdetailComponent")
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      SupporterdetailComponent.supporterId = params['sid'] ?? null;
    })

    if (SupporterdetailComponent.password == null) {
      SupporterdetailComponent.password = window.prompt("Password") ?? null;
    }

    if (this.supporterData == null) {
      this.loadData()
    }
  }

  private async loadData() {
    this.spinnerService.show()
    if (SupporterdetailComponent.supporterId && SupporterdetailComponent.password) {
      let result = await this.viewModel.loadSupporterData(SupporterdetailComponent.supporterId, SupporterdetailComponent.password)
      if (result.success) {
        SupporterdetailComponent.supporterData = result.data
      } else {
        this.snackbarService.openSnackBar(result.error)
      }
    } else {
      this.snackbarService.openSnackBar("No password or ID provided")
    }

    this.spinnerService.hide()
  }
}
