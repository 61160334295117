<a [hidden]="globals.user" [routerLink]="['/login']">
    <button class="login" mat-flat-button color="accent">
        <span>{{ 'landingpage-welcome-menu-login' | translate }}</span>
    </button>
</a>

<button *ngIf="globals.user" mat-flat-button color="accent" [matMenuTriggerFor]="menu">
    <span>{{globals.user.firstName}}</span>
</button>

<!--
<a>
    <span class="material-symbols-outlined mobile" [matMenuTriggerFor]="menu">
        person
    </span>
</a>
-->

<mat-menu #menu="matMenu">
    <div *ngIf="globals.user?.accountType == 'ACCOUNT_COMMERCIAL'">
        <a [routerLink]="['/dashboard']">
            <p class="menuItem">{{ 'dashboard' | translate }}</p>
        </a>
        <a [routerLink]="['/booking']">
            <p class="menuItem">{{ 'private-bookings' | translate }}</p>
        </a>
    </div>
    <a *ngIf="globals.user?.accountType == 'ACCOUNT_PRIVATE'" [routerLink]="['/booking']">
        <p class="menuItem">{{ 'my-bookings' | translate }}</p>
    </a>
    <p class="menuItem" (click)="logout()">Logout</p>
</mat-menu>