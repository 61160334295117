<a [routerLink]="['/detail']" [queryParams]="{lkey: listingData.key, rkey: roomData.key}">
    <div class="listing desktop">

        <div class="right-part">
            <div class="text-part">
                <p>{{ listingData.name }} - {{ listingData.city }}</p>
                <h5>{{ roomData.name }}</h5>
                <p class="mt-2">{{ roomData.roomDesc| slice:0:100}}..</p>
                <p class="bottom-left"></p>
                <div class="bottom-right">
                    <span>{{ roomData.bedKind }}</span><br>
                    <p class="bold">{{ 'from' | translate }} <span>{{ roomData.pricePerNight}}</span> {{
                        'per-night' |
                        translate}}
                    </p>
                </div>
            </div>
        </div>
        <div class="left-part">
            <img [src]="getRoomImageUrl()" alt="Exterior photo of {{roomData.name}}">
        </div>

    </div>
    <div class="listing-mobile mobile">

        <div class="right-part">
            <div class="text-part">
                <p>{{ listingData.name }} - {{ listingData.city }}</p>
                <h5>{{ roomData.name }}</h5>
                <p class="bottom-left"></p>
                <div class="bottom-right">
                    <span>{{ roomData.bedKind }}</span><br>
                    <p class="bold">{{ 'from' | translate }} <span>{{ roomData.pricePerNight}}</span> {{ 'per-night' |
                        translate}}
                    </p>
                </div>
            </div>
        </div>
        <div class="left-part">
            <img [src]="getRoomImageUrl()" alt="Exterior photo of {{roomData.name}}">
        </div>

    </div>
</a>