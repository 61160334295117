import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mmToInch'
})
export class MmToInchPipe implements PipeTransform {

    transform(value: number): string {
        if (!value) return '';

        // Convert mm to inches
        const totalInches = Math.round(value / 25.4 * 100) / 100;

        return `${totalInches}"`
    }

}