import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { ListElementComponent } from "../../../../../../rooms/src/app/views/offer/view-elements/list-element/list-element.component";
import { FooterComponent } from "../../shared/footer/footer.component";
import { HeaderComponent } from "../../shared/header/header.component";
import { LoginStateFieldComponent } from '../../shared/login-state-field/login-state-field.component';
import { ContactData } from '../../../models/contact';
import { CmToFeetInchPipe } from '../../../../../../../src/app/pipes/cmToFeetInch.pipe';
import { BoardData } from '../../../models/board';
import { Globals } from '../../../global';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { ContactViewModel } from '../../../viewmodels/contact-viewmodel.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-contact-list',
  standalone: true,
  templateUrl: './contact-list.component.html',
  styleUrl: './contact-list.component.scss',
  imports: [
    PipesModule,
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    LanguageButtonComponent,
    LoginStateFieldComponent,
    ListElementComponent,
    HeaderComponent,
    FooterComponent
  ]
})
export class ContactListComponent {

  actionName = ""

  constructor(
    private toFeet: CmToFeetInchPipe,
    public viewModel: ContactViewModel,
    public globals: Globals,
    private spinnerService: SpinnerService,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('ContactList')
    analytics.logEvent("Open-ContactList")

    this.viewModel.loadContacts()
  }

  getBadgeCount(contactId: string | null): number {
    if (this.globals.notification.value && this.globals.notification.value.countMap && contactId) {
      for (const [key, value] of Object.entries(this.globals.notification.value.countMap)) {
        if (key == contactId) {
          return value
        }
      }
    }

    return 0
  }

  getOtherName(contact: ContactData): String {
    let requestor = ContactData.getRequesterData(contact)
    if (contact.ownerKey === this.globals.user?.key) {
      return requestor?.firstName ?? ""
    }

    return contact.ownerName ?? ""
  }

  getBoard(contact: ContactData): BoardData {
    return ContactData.getBoardData(contact.boardJson)!
  }

  getBoardKey(contact: ContactData): string {
    let board = ContactData.getBoardData(contact.boardJson)
    return board?.key ?? ""
  }

  getBoardName(contact: ContactData): string {
    let board = ContactData.getBoardData(contact.boardJson)
    return board?.title ?? ""
  }

  getBoardDimensions(contact: ContactData): string {
    let board = ContactData.getBoardData(contact.boardJson)
    console.log(board)
    return this.toFeet.transform(board?.length ?? 0) + ' | ' + board?.volume
  }
}
