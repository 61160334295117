<div class="vstack">
    <app-header [showBack]=false></app-header>

    <div class="content scroll-container padval container-height" style="--padding: 8px 16px 0 16px;--minus: 66px;">
        <div class="vstack">
            <div *ngFor="let contact of viewModel.contacts| async">
                <a [routerLink]="['/contact']" [queryParams]="{ckey: contact.key, bkey: getBoardKey(contact)}">

                    <div class="maval" style="--margin: 8px 0 8px 0">
                        <div class="hstack dimval" style="--h: 80px; --w: 100%;">
                            <div class="dimval vstack padval accent-2-verylight"
                                style="--h: 100%; --w: 100%; --padding: 12px;">
                                <div class="hstack">
                                    <h5 class="superbold accent-2">{{ getBoard(contact).title }}</h5>
                                    <p class="spacer"></p>
                                    <h6 class="bold">{{getOtherName(contact)}}</h6>
                                </div>
                                <div class="hstack">
                                    <div class="vstack">
                                        <p class="spacer-s"></p>
                                        <h5 class="">{{contact.lastMessage}}</h5>
                                    </div>

                                    <p class="spacer"></p>
                                    <div *ngIf="getBadgeCount(contact.key) > 0" class="badge">
                                        <p>{{ getBadgeCount(contact.key) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div *ngIf="((viewModel.contacts|async)?.length ?? 0) <= 0">
                <p class="spacer-xxl"></p>
                <h4>{{'cl-no-messages' | translate}}</h4>
            </div>
        </div>
    </div>
</div>