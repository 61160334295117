<div id="gallery-container" class="dimval"
  [ngStyle]="{'--w': globals.framewidth + 'px', '--h': globals.frameHeight + 'px'}">

  <div class="image-container" appSwipe (swipeLeft)="showNextImage()" (swipeRight)="showPreviousImage()">

    <div class="close-button" (click)="close()">
      <span class="material-symbols-outlined">close</span>
    </div>

    <div class="previous-image" (click)="showPreviousImage()">
      <span class="material-symbols-outlined">arrow_back_ios</span>
    </div>

    <img id="currentImage" [src]="currentImage" />

    <div class="next-image" (click)="showNextImage()">
      <span class="material-symbols-outlined">arrow_forward_ios</span>
    </div>

  </div>

  <div class="preview-container">
    <div class="preview-image" *ngFor="let image of images; let i = index" (click)="showImage(i)">
      <img [src]="image" />
    </div>
  </div>
</div>