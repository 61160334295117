

import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";

import { BehaviorSubject, Observable } from "rxjs";
import { LoginState } from "../enum/login-state";
import { Globals } from "../global";
import { Result } from "../../../../../src/app/model/helper/result.model";
import { UserData } from "../model/user";

import firebase from "firebase/compat/app";
import { Businessdata } from "../model/business/business";
import { ListingData } from "../model/business/listing";
import { ListingDataComponent } from "../views/dashboard/business/listing-data/listing-data.component";
import { BusinessRepoService } from "./business-repo.service";
import { FiresoreAdapter } from "../../../../../src/app/services/firestoreadapter.service";
import { SnackbarService } from "../../../../../src/app/services/snackbar.service";
import { ListingdataService } from "./listingdata.service";


@Injectable({
  providedIn: 'root',
})

export class AccountService {

  public loggedIn: BehaviorSubject<LoginState> = new BehaviorSubject<LoginState>(LoginState.pending);
  public account: BehaviorSubject<UserData | null> = new BehaviorSubject<UserData | null>(null);
  private _user: Observable<firebase.User | null> = new BehaviorSubject<firebase.User | null>(null)

  constructor(
    private _db: FiresoreAdapter,
    private _globals: Globals,
    private _afAuth: AngularFireAuth,
    private snackbarService: SnackbarService,
    private businessService: BusinessRepoService,
    private listingService: ListingdataService,
  ) {
    this.refreshUser()
  }

  /**
   * call if u want to load current user data
   * @param userId
   */
  public async refreshUserAccount(userId: string): Promise<void> {
    return this.refreshAccount(userId, await this._afAuth.currentUser);
  }

  public async getUserToken() {
    let user = await this._afAuth.currentUser
    return user?.getIdToken()
  }

  public refreshUser() {
    this._user = this._afAuth.authState;

    this._user.subscribe(
      (auth: firebase.User | null) => {
        if (!auth) {
          console.log("User logged out")
          this._globals.clear();
          this.account.next(null);
          this.loggedIn.next(LoginState.loggedOut);
          return;
        }
        console.log("User logged in: " + auth.email + ' ' + auth.uid)
        this.refreshAccount(auth.uid, auth);
      }
    );
  }


  private refreshAccount(userId: string, auth: firebase.User | null): Promise<void> {

    return new Promise<void>((refreshAccountDone) => {
      this.getAccountData(userId, async result => {

        if (!auth) {
          refreshAccountDone()
          this.loggedIn.next(LoginState.loggedOut);
          return
        }

        if (!result && auth) {
          // Create new account
          const userData = new UserData();
          // TODO check!?
          userData.accountType = "ACCOUNT_COMMERCIAL"
          userData.firstName = auth.displayName;
          userData.email = auth.email;
          userData.phone = auth.phoneNumber;
          userData.key = auth.uid;
          userData.userCreationDate = firebase.firestore.FieldValue.serverTimestamp()

          this.getPath(userId).set(UserData.toObject(userData))
            .then(success => {
              this.fillGlobalData(userData);
              // if there is no user, then there is no contract
              this.account.next(userData);
              this.loggedIn.next(LoginState.loggedInNoContract);
            })
            .catch(error => {
              this.snackbarService.openDefaultErrorSnackBar();
              console.error(error)
            });
          refreshAccountDone()
          return;
        }

        try {
          var userData = UserData.toUser(result!, userId)!
          console.log("refreshAccount: " + userData)

          if (userData.accountType != "ACCOUNT_COMMERCIAL") {
            this.fillGlobalData(userData);
            this.account.next(userData);
            this.loggedIn.next(LoginState.loggedIn);
            return
          }

          var businessData = await this.businessService.getBusiness(userData.businessKey ?? null)
          if (!businessData) {
            businessData = await this.businessService.searchBusiness(userId)
            if (businessData && !userData.businessKey) {
              console.log(businessData)
              userData.businessKey = businessData.key
              // we have a business that is not connected to the user
              await this.getPath(userId).set(UserData.toObject(userData))
            }
          }

          // manage known account

          if (!userData.lastUserApprovalDate) {
            this.fillGlobalData(userData);
            this.account.next(userData);
            this.loggedIn.next(LoginState.loggedInNoContract);
          } else if (!userData.firstName ||
            !userData.secondName ||
            !userData.email || !userData.about
          ) {
            this.fillGlobalData(userData);
            this.account.next(userData);
            this.loggedIn.next(LoginState.loggedInNoUser);
          } else if (!userData.businessKey || !businessData) {

            businessData = new Businessdata()
            businessData.userId = userId

            businessData.phone = userData.phone
            businessData.streetNr = userData.streetNr
            businessData.city = userData.city
            businessData.district = userData.district
            businessData.country = userData.country
            businessData.post = userData.post

            businessData.email = userData.email
            const businessKey = await this.businessService.saveBusiness(userData.businessKey ?? null, businessData)
            userData.businessKey = businessKey

            await this.getPath(userId).set(UserData.toObject(userData))
            // todo delete business if fails
            this.fillGlobalData(userData);
            this.account.next(userData);
            this.loggedIn.next(LoginState.loggedInNoBusiness);
          } else if (!businessData.name) {
            this.fillGlobalData(userData);
            this.account.next(userData);
            this.loggedIn.next(LoginState.loggedInNoBusiness);
          } else {
            var listingDatas = await this.listingService.getListings(userData.businessKey, false, null)
            console.log(listingDatas)
            if (listingDatas.length <= 0) {
              const listingData = new ListingData()
              listingData.businessKey = userData.businessKey
              listingData.streetNr = businessData.streetNr
              listingData.city = businessData.city
              listingData.district = businessData.district
              listingData.post = businessData.post
              listingData.country = businessData.country
              listingData.hostAbout = userData.about
              listingData.hostKey = userData.key
              listingData.hostName = userData.firstName
              listingData.hostEmail = userData.email
              const listingId = await this.listingService.saveListing(null, listingData)
              ListingDataComponent.listingKey = listingId
              this.fillGlobalData(userData);
              this.account.next(userData);
              this.loggedIn.next(LoginState.loggedInNoListing);
            } else if (!listingDatas[0].name) {
              ListingDataComponent.listingKey = listingDatas[0].key
              this.fillGlobalData(userData);
              this.account.next(userData);
              this.loggedIn.next(LoginState.loggedInNoListing);
            } else {
              this.fillGlobalData(userData);
              this.account.next(userData);
              this.loggedIn.next(LoginState.loggedIn);
            }
          }

          this.fillGlobalData(userData);
        } catch (error) {
          this.snackbarService.openDefaultErrorSnackBar();
          console.error(error)
        }

        refreshAccountDone()

      }, () => {
        this.loggedIn.next(LoginState.loggedOut);
      });
    })
  }

  /**
   * Save account id db.
   * @param {UserData} account Account.
   * @returns {Observable<Result>} Observable of saving status.
   */
  public saveAccount(account: UserData): Promise<Result> {
    if (!account) {

      return new Promise(async (result) => {
        result(({ status: false, errorMessage: 'account-required' } as Result));
      });
    }

    return new Promise(async (result) => {

      if (account.userCreationDate === undefined) {
        account.userCreationDate = firebase.firestore.FieldValue.serverTimestamp()
      }

      console.log(account)

      if (account.key == null) {
        return
      }

      this.getPath(account.key).set(UserData.toObject(account))
        .then((success) => {
          result({ status: true } as Result);
        })
        .catch((error) => {
          result(({ status: false, errorMessage: 'account-saving-error' } as Result))
          this.snackbarService.openDefaultErrorSnackBar();
          console.error(error);
        })
    });
  }

  private fillGlobalData(account: UserData | null) {

    if (account == null) {
      return
    }

    console.log('fill user data: ' + account)

    this._globals.user = account
  }


  private getPath(userid: string) {
    var docRef = this._db.db.collection("user").doc(userid);
    return docRef;
  }


  private getAccountData(userid: string, finish: (account: UserData | null) => any, error: () => any) {

    this.getPath(userid).get().toPromise().then((result) => {
      finish(UserData.toUser(result!.data()!, userid));
    })
      .catch(errorResult => {
        this.snackbarService.openDefaultErrorSnackBar();
        console.error(errorResult)
      });
  }
}
