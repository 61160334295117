import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Globals } from "../global";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider } from 'firebase/auth';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _afAuth: AngularFireAuth,
    private _globals: Globals
  ) { }

  public loginWithGoogle() {
    return this._afAuth.signInWithPopup(new GoogleAuthProvider);
  }

  public resetPassword(email: string) {
    return this._afAuth.sendPasswordResetEmail(email)
  }


  public loginWithFacebook() {
    return this._afAuth.signInWithPopup(new FacebookAuthProvider());
  }


  public logout() {
    this._globals.clear();
    this._afAuth.signOut();
  }

  public deleteUser() {
    this._globals.clear();
    /* this._afAuth.delete().then( result => {
       console.log('deleted');
     }).catch( error => {
       console.log('error deleting');
     });*/
  }


  /**
   * dont use, just for test!!
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  public loginForTest() {
    //this.testRegister();
    return this._afAuth.signInWithEmailAndPassword('uitest@massundziel.net', '1234qaywsx');
  }

  public login(email: string, password: string) {
    //this.testRegister();
    return this._afAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * dont use, just for test!!
   */
  public testRegister() {
    this._afAuth.createUserWithEmailAndPassword('uitest@massundziel.net', '1234qaywsx');
  }
}
