import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../../projects/boardswap/src/app/localization/translate.service';
import { Availability } from '../../../projects/boardswap/src/app/models/availability';



@Pipe({
  name: 'available',
  pure: false,
})
export class AvailabilityPipe implements PipeTransform {

  constructor(
    private _translateService: TranslateService
  ) {

  }

  transform(value: number): string {

    switch (value) {
      case Availability.available:
        return this._translateService.translate('ab-availability-available');
      case Availability.notavailable:
        return this._translateService.translate('ab-availability-notavailable');
      case Availability.deleted:
        return this._translateService.translate('ab-availability-deleted');
      case Availability.availableFrom:
        return this._translateService.translate('ab-availability-availableFrom');
      case Availability.reserved:
        return this._translateService.translate('ab-availability-reserved');
      case Availability.sold:
        return this._translateService.translate('ab-availability-sold');
      default: return this._translateService.translate('ab-availability-available');
    }
  }

}
