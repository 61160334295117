import { Injectable } from '@angular/core';
import { fieldListingImage } from '../enum/helper/fields';
import { ListingData } from '../model/business/listing';
import { IndexDataType } from '../../../../../src/app/model/helper/IndexDataType';
import { FiresoreAdapter } from '../../../../../src/app/services/firestoreadapter.service';
import { ImageService } from './image.service';

@Injectable({
  providedIn: 'root'
})
export class ListingdataService {

  constructor(
    private db: FiresoreAdapter,
    private imageService: ImageService
  ) { }

  public getListings(businessKey: string | null, publishedOnly: boolean, locationId: number | null): Promise<any[]> {
    return new Promise(async (result) => {

      var ref = this.db.db.collection('/listing').ref.limit(100)

      try {
        if (locationId) {
          ref = ref.where("locationId", "==", locationId)
        }
        if (businessKey) {
          ref = ref.where("businessKey", "==", businessKey)
        }
        if (publishedOnly) {
          ref = ref.where("publish", "==", true)
        }

        var queryResult = await ref.get()
        const resultData = queryResult?.docs.map(d =>
          ListingData.toListing(d.data()!, d.id)
        );
        result(resultData ?? []);

      } catch (error) {
        console.error(error);
        result([])
      }
    });
  }

  public getListing(listingId: string): Promise<ListingData | null> {
    return new Promise(async (result) => {
      this.db.db.collection('/listing').doc(listingId).get().toPromise()
        .then((d) => {
          result(ListingData.toListing(d!.data()!, listingId));
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    })
  }

  public async getListingImageUrls(listing: ListingData): Promise<IndexDataType[]> {
    return this.imageService.getListingImageUrls(listing)
  }

  public deleteListing(listingId: string): Promise<boolean | null> {

    return new Promise(async (result) => {

      this.imageService.deleteImages(listingId, fieldListingImage)
        .then(() => {
          this.db.db.collection('/listing').doc(listingId).delete()
            .then(() => {
              result(true);
            })
            .catch((error) => {
              result(false);
              console.error(error);
            })
        })
        .catch((error) => {
          result(false);
          console.error(error);
        })
    })
  }

  public addListing(listingId: string, listing: ListingData): Promise<string | null> {

    listing.key = listingId;

    return new Promise(async (result) => {

      if (listing === null || listing === undefined) {
        result(null);
        return
      }

      console.log(ListingData.toObject(listing))
      this.db.db.collection('/listing').doc(listingId).set(ListingData.toObject(listing))
        .then(() => {
          result(listingId);
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    });
  }


  public saveListing(listingId: string | null, listing: ListingData): Promise<string | null> {

    listing.key = listingId;

    return new Promise(async (result) => {

      if (listing === null || listing === undefined) {
        result(null);
        return
      }

      if (listingId) {
        this.db.db.collection('/listing').doc(listingId).update(ListingData.toObject(listing))
          .then(() => {
            result(listingId);
          })
          .catch((error) => {
            result(null);
            console.error(error);
          })
      } else {
        this.db.db.collection('/listing').add(ListingData.toObject(listing))
          .then((docRef) => {
            result(docRef.id);
          })
          .catch((error) => {
            result(null);
            console.error(error);
          })
      }
    });
  }
}
