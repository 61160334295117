<div class="calendar">

    <div class="cal-title">
        <span class="nav-icon material-symbols-outlined" [ngClass]="{'disabled': !hasPreviousMonth()}"
            (click)="onPreviousMonth()">
            navigate_before
        </span>
        <span class="cal-title"> {{ calendarCreator.getMonthName(monthNumber) }} {{ year }} </span>
        <span class="nav-icon material-symbols-outlined" [ngClass]="{'disabled': !hasNextMonth()}"
            (click)="onNextMonth()">
            navigate_next
        </span>
    </div>

    <div>
        <span *ngFor='let weekDay of weekDaysName' class="weekDay my-3">
            {{ weekDay }}
        </span>

        <span *ngFor='let day of monthDays' class="day" [ngClass]="{'day' : (day.number ?? 0) > 0, 
            'weekDay' : (day.number ?? 0) <= 0}" (click)="onSelectDay(day)">

            <div [ngClass]="{'selected': day?.timestamp != null && 
            (day.timestamp == startDay?.timestamp || day.timestamp == endDay?.timestamp || 
            (startDay?.timestamp != null && endDay?.timestamp != null && day.timestamp! >= startDay!.timestamp! && day.timestamp! <= endDay!.timestamp!)),
            'past': isPastDay(day)}">

                {{ day.number }}
            </div>
        </span>
    </div>
    <div class="">
        <div class="sel-desc">
            <span class="">{{startDay?.timestamp | date}} <span *ngIf="endDay"> - {{endDay.timestamp | date}}</span>
            </span>
        </div>
    </div>
</div>