import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Globals } from "../global";
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _afAuth: AngularFireAuth,
    private globals: Globals,
    private fns: AngularFireFunctions,
  ) { }

  public loginWithGoogle() {
    return this._afAuth.signInWithPopup(new GoogleAuthProvider);
  }

  public resetPassword(email: string) {
    return this._afAuth.sendPasswordResetEmail(email)
  }

  public loginWithFacebook() {
    return this._afAuth.signInWithPopup(new FacebookAuthProvider());
  }

  public logout() {
    this.globals.clear();
    this._afAuth.signOut();
  }

  public async register(email: string, continueUrl: string): Promise<any> {

    let passData = {
      email: email,
      continueUrl: continueUrl,
    }

    return new Promise((result) => {
      const callable = this.fns.httpsCallable('boardswap-onNewRegister');
      const data = callable(passData);
      firstValueFrom(data)
        .then((resultValue) => {
          console.log('resultValue: ' + JSON.stringify(resultValue));
          result(resultValue);
        })
        .catch((error) => {
          console.error(error);
          result({
            success: false,
          });
        })
    });
  }

  public login(email: string, continueUrl: string): Promise<any> {
    let passData = {
      email: email,
      continueUrl: continueUrl,
    }

    return new Promise((result) => {
      const callable = this.fns.httpsCallable('boardswap-onNewLogin');
      const data = callable(passData);
      firstValueFrom(data)
        .then((resultValue) => {
          console.log('resultValue: ' + JSON.stringify(resultValue));
          result(resultValue);
        })
        .catch((error) => {
          console.error(error);
          result({
            success: false,
          });
        })
    });
  }
}
