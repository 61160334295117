import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { FiresoreAdapter } from "../../../../../src/app/services/firestoreadapter.service";
import { NotificationData } from "../models/notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private db: FiresoreAdapter,
  ) { }

  private subscription: Subscription | null = null

  public listenToNotificationCount(userKey: string, onChange: (notification: NotificationData | null) => void) {

    this.subscription?.unsubscribe()

    const docRef = this.db.db.collection('/notification').doc(userKey)
    this.subscription = docRef.snapshotChanges().subscribe(changes => {
      const resultData = NotificationData.toNotification(changes.payload.data()!, changes.payload.id)
      onChange(resultData)
    });
  }

  public markAsRead(userKey: string, contactKey: string) {

    const updateObject: any = {};
    updateObject[`countMap.${contactKey}`] = 0;

    this.db.db.collection('/notification').doc(userKey).update(updateObject)
  }
}
