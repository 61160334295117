import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Reference } from '@angular/fire/compat/storage/interfaces';
import { firstValueFrom } from 'rxjs';
import { fieldListingImage } from '../enum/helper/fields';
import { SnackbarService } from '../../../../../src/app/services/snackbar.service';
import { SpinnerService } from '../../../../../src/app/services/spinner.service';
import { ListingData } from '../model/business/listing';
import { IndexDataType } from '../../../../../src/app/model/helper/IndexDataType';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private storage: AngularFireStorage,
    private spinnerService: SpinnerService,
    private snackbarService: SnackbarService
  ) { }


  public async getListingImageUrls(listing: ListingData): Promise<IndexDataType[]> {

    var urls: IndexDataType[] = []


    var refs = await this.getAllRefs(listing)

    for (let i = 0; i < refs.length; i++) {
      var url = await refs[i].getDownloadURL()
      urls.push({ index: refs[i].name, data: url })
    }

    return urls
  }

  private getAllRefs(listing: ListingData): Promise<Reference[]> {

    var storageRef = this.storage.ref(fieldListingImage + '/' + listing.key);

    return firstValueFrom(storageRef.listAll())
      .then(result => result.items)
      .catch(error => {
        // Handle any errors
        console.error('Error fetching image references:', error);
        throw error; // Re-throw the error to propagate it further if needed
      });
  }

  loadImage(key: string, name: string, fieldImage: string): Promise<any> {
    return firstValueFrom(this.storage.ref(fieldImage + '/' + key + "/" + name).getDownloadURL())
  }

  deleteImage(key: string, fieldImage: string, name: string): Promise<void> {

    console.log(fieldImage + '/' + key + "/" + name)
    return new Promise<void>((result) => {
      this.storage.ref(fieldImage + '/' + key + "/" + name).delete().subscribe(
        () => {
          console.log()
          result()
        }
      );
    })
  }

  deleteImages(key: string, fieldImage: string): Promise<boolean> {

    return new Promise<boolean>((result) => {
      this.storage.ref(fieldImage + '/' + key + "/").delete().subscribe(
        (success) => {
          result(success)
        }
      );
    })
  }

  saveImage(listingkey: string, file: any, name: string, fieldImage: string): Promise<IndexDataType | null> {
    return new Promise((result) => {
      this.storage.ref(fieldImage + '/' + listingkey + "/" + name).put(file)
        .then(async (d) => {
          var url = await d.ref.getDownloadURL()
          result({ index: d.metadata.name, data: url });
        })
        .catch((error) => {
          result(null);
          console.error(error);
        })
    })

  }

  static compressImage(src: string, max: number) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {

        var MAX_WIDTH = max;
        var MAX_HEIGHT = max;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        const elem = document.createElement('canvas');
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);
        const data = ctx?.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  static dataURLtoFile(dataurl: string, filename: string) {

    var arr = dataurl.split(','),
      mime = (arr[0].match(/:(.*?);/))![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
