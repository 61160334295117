import { Injectable } from "@angular/core";
import { Day } from "../../models/day";

@Injectable({
    providedIn: 'root'
})
export class CalendarCreator {
    private currentYear: number;
    private currentMonthIndex: number;

    constructor() {
        let date = new Date();
        this.currentYear = date.getFullYear();
        this.currentMonthIndex = date.getMonth();
    }

    public getCurrentMonth(): Day[] {
        return this.getMonth(this.currentMonthIndex, this.currentYear);
    }

    public getMonth(monthIndex: number, year: number): Day[] {
        let days = [];

        let firstday = Day.createDay(1, monthIndex, year);

        //create empty days
        for (let i = 1; i < firstday.weekDayNumber!; i++) {
            days.push({
                weekDayNumber: i,
                monthIndex: monthIndex,
                year: year,
            } as Day);
        }
        days.push(firstday);
        //

        let countDaysInMonth = new Date(year, monthIndex + 1, 0).getDate();
        for (let i = 2; i < countDaysInMonth + 1; i++) {
            days.push(Day.createDay(i, monthIndex, year));
        }

        return days;
    }

    public getMonthName(monthIndex: number) { return Day.getMonthName(monthIndex) }
}