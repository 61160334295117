import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class FiresoreAdapter {

  constructor(
    public db: AngularFirestore
  ) {
    this.db.firestore.settings({
      experimentalAutoDetectLongPolling: true,
      // experimentalForceLongPolling: true,
      merge: true
    });

    // firebase.firestore.
    //firebase.firestore.setLogLevel("debug")
  }
}
