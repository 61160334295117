
export class LocationId {

    static moroccoTamraghtTagazout = { locationId: 101, locationName: 'Morocco' }

    static findName(locationId: number): string | null {
        if (this.moroccoTamraghtTagazout.locationId == locationId) {
            return this.moroccoTamraghtTagazout.locationName
        }

        return null
    }

}