import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cmToInch'
})
export class CmToInchPipe implements PipeTransform {

    transform(value: number): string {
        if (!value) return '';

        // Convert cm to inches
        const cmToInches = 0.393701;
        const inches = value * cmToInches;
        const wholeInches = Math.floor(inches);
        const fraction = inches - wholeInches;

        // Round the fraction to the nearest eighth
        const eighths = Math.round(fraction * 8);
        let fractionString;

        switch (eighths) {
            case 0:
                fractionString = "";
                break;
            case 1:
                fractionString = "1/8";
                break;
            case 2:
                fractionString = "1/4";
                break;
            case 3:
                fractionString = "3/8";
                break;
            case 4:
                fractionString = "1/2";
                break;
            case 5:
                fractionString = "5/8";
                break;
            case 6:
                fractionString = "3/4";
                break;
            case 7:
                fractionString = "7/8";
                break;
            case 8:
                fractionString = ""; // This would be a full inch
                return `${wholeInches + 1}"`; // Move to the next whole inch
            default:
                fractionString = "";
        }

        // Return the formatted inches with fractions
        if (fractionString) {
            return `${wholeInches}" ${fractionString}`;
        } else {
            return `${wholeInches}"   `;
        }
    }

}