import { Occupied } from "../Occupied"
import { Day } from "../day"

export class RoomData {

    key: string | null = null

    name: string | null = null
    numberOfGuests: number = 0
    bedKind: string | null = null
    roomDesc: string | null = null

    window: boolean = false

    roomType: string | null = null

    publish = false

    // price
    pricePerNight: number = 0
    additionalGuestPrice: number = 0

    // booking
    // kinds: booking -bk, external -ex, manuel -ma
    // format: id|startdate|enddate|kind#id|startdate|enddate|kind#
    occupied: string | null = null

    static allFilled(roomData: RoomData): boolean {
        return roomData.name != null && roomData.numberOfGuests > 0
            && roomData.roomDesc != null && roomData.pricePerNight > 0

            && roomData.name.length > 0 && roomData.roomDesc.length > 0
    }

    static getOccupiedObjects(roomData: RoomData): Occupied[] {

        var rawOccupiedList = roomData.occupied?.split('#') ?? []
        var occupiedList: Occupied[] = []

        for (let i = 0; i < rawOccupiedList.length; i++) {
            var split = rawOccupiedList[i].split('|')
            var occupied = new Occupied()
            occupied.key = split[0]
            // TODO evaluate this concept
            occupied.fromDay = Day.createDayTS(Number(split[1]))
            occupied.toDay = Day.createDayTS(Number(split[2]))
            occupied.kind = split[3]
            occupiedList.push(occupied)
        }

        return occupiedList
    }

    static getOccupiedStrings(occupiedList: Occupied[]): string {
        var stringList: string[] = []
        for (let i = 0; i < occupiedList.length; i++) {
            var stringValue = occupiedList[i].key + '|'
                + occupiedList[i].fromDay?.timestamp + '|' + occupiedList[i].toDay?.timestamp + '|' + 'ma'
            stringList.push(stringValue)
        }

        return stringList.join('#')
    }

    static toBasicObject(room: RoomData): any {
        return {
            key: room.key,
            name: room.name,
            bedKind: room.bedKind,
            roomDesc: room.roomDesc
        }
    }
}