export const environment = {
  production: true,
  defaultLocale: 'en-US',
  maxBookingUploadFileSize: 10000000,
  notifyBarTimeDelay: 5000,
  firebase: {
    apiKey: "AIzaSyCOUe5B8VQ1Zb1lzNYhYOJKe45OvAeClic",
    authDomain: "staynconnect-develop.firebaseapp.com",
    projectId: "staynconnect-develop",
    storageBucket: "staynconnect-develop.appspot.com",
    messagingSenderId: "335304782933",
    appId: "1:335304782933:web:a42cd977f03918659490dc"
  },
  actionCodeSettings: {
    // Your redirect URL
    url: 'https://boardswap.freeboho.com/search',
    handleCodeInApp: true,
  }
};

