// consent.service.ts
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Globals } from '../global';
import { AccountManager } from './account-manager.service';

@Injectable({
    providedIn: 'root'
})
export class ConsentService {

    constructor(
        public analytics: AngularFireAnalytics,
        private globals: Globals,
        private accountManager: AccountManager,
    ) {

    }

    checkConsent(): boolean {
        if (this.globals.user?.allowTracking != null) {
            return this.globals.user?.allowTracking;
        }
        return localStorage.getItem('trackingConsentX') === 'true';
    }

    askForConsent(): boolean {
        if (localStorage.getItem('trackingConsentX') == null && this.globals.user?.allowTracking == null) {
            return true
        }

        this.analytics.setAnalyticsCollectionEnabled(true)

        return false
    }

    grantConsent() {
        this.analytics.setAnalyticsCollectionEnabled(true)
        localStorage.setItem('trackingConsentX', 'true');
        if (this.globals.user != null) {
            let user = this.globals.user
            user.allowTracking = true
            this.accountManager.saveAccount(user)
        }
    }

    denyConsent() {
        this.analytics.setAnalyticsCollectionEnabled(false)
        localStorage.setItem('trackingConsentX', 'false');
        if (this.globals.user != null) {
            let user = this.globals.user
            user.allowTracking = false
            this.accountManager.saveAccount(user)
        }
    }
}