import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, firstValueFrom, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    private nominatimUrl = 'https://nominatim.openstreetmap.org/search';
    private lastRequestTime = 0;

    constructor(private http: HttpClient) { }

    searchLocation(query: string): Promise<any> {
        const now = Date.now();
        const timeSinceLastRequest = now - this.lastRequestTime;

        if (timeSinceLastRequest < 1000) {
            // If the last request was less than a second ago, wait
            const delayTime = 1000 - timeSinceLastRequest;
            return firstValueFrom(of(null).pipe(
                delay(delayTime),
                switchMap(() => this.makeRequest(query))
            ));
        } else {
            return this.makeRequest(query);
        }
    }

    private makeRequest(query: string): Promise<any> {
        this.lastRequestTime = Date.now();
        const url = `${this.nominatimUrl}?q=${query}&format=json&limit=10&accept-language=en`;

        return firstValueFrom(this.http.get(url).pipe(
            catchError(error => {
                console.error('Error making request:', error);
                return of(null);
            })
        ));

    }
}
