import { Injectable } from '@angular/core';
import { UserData } from './model/user';


@Injectable({
  providedIn: 'root',
})
export class Globals {

  public user: UserData | null = null

  public language: string = "en-US"

  // For key for data encryption.
  public cryptKey = '';

  constructor(
  ) {
  }


  public otherLanguage() {
    if (this.language == 'de-DE') {
      return 'EN'
    } else {
      return 'DE'
    }
  }

  public toggleLanguage() {
    if (this.language == 'de-DE') {
      this.language = 'en-US'
    } else {
      this.language = 'de-DE'
    }
  }

  public clear() {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('emailForSignIn');
    }
    console.log('clear user')
    this.user = null;
  }
}
