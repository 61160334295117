import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Globals } from '../global';
import { BoardData } from '../models/board';
import { ContactData } from '../models/contact';
import { MessageData } from '../models/message';
import { UserData } from '../models/user';
import { AccountManager } from '../services/account-manager.service';
import { ContactService } from '../services/contact.service';
import { BoarddataService } from '../services/boarddata.service';

@Injectable({
  providedIn: 'root'
})
export class ContactViewModel {

  public contacts: BehaviorSubject<ContactData[] | null> = new BehaviorSubject<ContactData[] | null>(null);
  public messages: BehaviorSubject<Map<string, MessageData[]>> = new BehaviorSubject<Map<string, MessageData[]>>(new Map());

  constructor(
    private globals: Globals,
    private accountManager: AccountManager,
    private contactService: ContactService,
    private boardService: BoarddataService
  ) {
  }

  public resetData() {
    this.contacts.next(null)
  }

  async loadContacts() {

    this.contacts.next(null)
    try {
      var result = await this.contactService.getContacts(this.globals.user!.key!)
      result = result.sort((a, b) => (a?.lastMessageTS ?? 0) > (b?.lastMessageTS ?? 0) ? -1 : 1);
      this.contacts.next(result)
    } catch (error) {
      console.log('RequestContainer' + error);
    }
  }

  public getBoard(key: string): Promise<BoardData | null> {

    return new Promise<BoardData | null>(async (result) => {
      let board = await this.boardService.getBoard(key)
      if (board) {
        board.photos = await this.boardService.getImageUrls(board)
      }

      result(board)
    })
  }

  public async startContact(user: UserData, boardData: BoardData, message: string): Promise<any> {
    let userToken = await this.accountManager.getUserToken()
    return this.contactService.startContact(user, boardData, message, userToken)
  }

  public findContact(boardKey: string): Promise<ContactData | null> {
    return new Promise<ContactData | null>(async (result) => {
      var contact = this.contacts.getValue()?.find(contact => contact.boardKey == boardKey && contact.senderKey == this.globals.user!.key!) ?? null

      if (!contact) {
        contact = await this.contactService.findContact(boardKey, this.globals.user!.key!)
      }
      result(contact)
    })
  }

  public getContact(boardKey: string, contactKey: string): Promise<ContactData | null> {

    return new Promise<ContactData | null>(async (result) => {
      var contact = this.contacts.getValue()?.find(contact => contact.key == contactKey) ?? null

      if (!contact) {
        contact = await this.contactService.getContact(boardKey, contactKey)
      }

      result(contact)
    })
  }
}
