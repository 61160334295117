import { DocumentData } from "firebase/firestore"


export class Businessdata {

  public key: string | null = null
  public userId: string | null = null

  public email: string | null = null
  public phone: string | null = null

  public website: string | null = null
  public name: string | null = null

  public streetNr: string | null = null
  public city: string | null = null
  public country: string | null = null
  public district: string | null = null
  public post: string | null = null
  public geoLoc: string | null = null

  public termsLink: string | null = null
  public privacyLink: string | null = null


  static toObject(data: Businessdata): {} {
    const mappped = new Map(Object.entries(data));

    return Object.fromEntries(mappped)
  }

  static toBusiness(doc: DocumentData, userKey: string): Businessdata | null {

    if (doc === undefined || doc === null) {
      return null
    }

    const data = doc as Businessdata
    data.key = userKey

    return data
  }

}