import { Component } from '@angular/core';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-company-details',
  standalone: true,
  templateUrl: './company-details.component.html',
  styleUrl: './company-details.component.scss',
  imports: [PipesModule, RouterModule]
})
export class CompanyDetailsComponent {

}
