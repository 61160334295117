import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../../src/app/pipes/pipes.module";
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { ListElementComponent } from "../../../../../../rooms/src/app/views/offer/view-elements/list-element/list-element.component";
import { SeachParam } from '../../../enums/searchparam';
import { SearchData } from '../../../models/search';
import { FooterComponent } from "../../shared/footer/footer.component";
import { HeaderComponent } from "../../shared/header/header.component";
import { LoginStateFieldComponent } from '../../shared/login-state-field/login-state-field.component';
import { FilterBoardsComponent } from '../filter-boards/filter-boards.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BoardListViewModel } from '../../../viewmodels/boardlist-viewmodel.service';
import { Subscription } from 'rxjs';
import { Availability } from '../../../models/availability';
import { TranslateService } from '../../../localization/translate.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-board-list',
  standalone: true,
  templateUrl: './board-list.component.html',
  styleUrl: './board-list.component.scss',
  imports: [
    PipesModule,
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    LanguageButtonComponent,
    LoginStateFieldComponent,
    ListElementComponent,
    HeaderComponent,
    FooterComponent
  ]
})
export class BoardListComponent implements OnInit, OnDestroy {

  Availability = Availability
  Date = Date

  actionName = "filter_list"
  titleName = this.translateService.translate('bl-new-boards')
  filterName = this.translateService.translate('bl-filtered')

  static searchData: SearchData | null = null
  get searchData() { return BoardListComponent.searchData }

  noBoardsFound: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);

  private subscription: Subscription | null = null;

  constructor(
    private overlay: Overlay,
    private router: Router,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    public viewModel: BoardListViewModel,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('BoardList')
    analytics.logEvent("Open-BoardList")
  }

  ngOnInit(): void {
    this.initData()
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  initData() {

    this.spinnerService.show()

    this.subscription = this.viewModel.boards.subscribe(boards => {
      // Check if the boards array is empty
      if (boards == null || boards?.length === 0) {
        this.noBoardsFound.next(true);
      } else {
        this.noBoardsFound.next(false);
      }

      this.spinnerService.hide()
    });


    this.viewModel.resetData()
    if (this.router.url.includes('myboards')) {
      BoardListComponent.searchData = null
      this.actionName = 'add'
      this.titleName = this.translateService.translate('bl-my-boards')
      this.viewModel.loadUserBoards()
    } else {
      this.actionName = "filter_list"
      this.titleName = this.translateService.translate('bl-new-boards')
      this.searchBoard()
    }
  }

  firstPhoto(photos: any[]): string | null {
    if (photos.length > 0) {
      return photos[0]['data'] ?? null
    }
    return null
  }

  onActionButton() {
    if (this.router.url.includes('myboards')) {
      this.router.navigate(['/add']);
    } else {
      this.openOverlay()
    }
  }

  onRemoveLengthFilter() {
    BoardListComponent.searchData!.fromLength = null
    BoardListComponent.searchData!.toLength = null
    this.searchBoard()
  }
  onRemoveVolumeFilter() {
    BoardListComponent.searchData!.fromVol = null
    BoardListComponent.searchData!.toVol = null
    this.searchBoard()
  }
  onRemovePriceFilter() {
    BoardListComponent.searchData!.fromPrice = null
    BoardListComponent.searchData!.toPrice = null
    this.searchBoard()
  }
  onRemoveLocationFilter() {
    BoardListComponent.searchData!.location = null
    this.searchBoard()
  }

  searchBoard() {
    this.spinnerService.show()
    this.viewModel.searchBoards(
      BoardListComponent.searchData?.fromLength ?? null,
      BoardListComponent.searchData?.toLength ?? null,
      BoardListComponent.searchData?.fromVol ?? null,
      BoardListComponent.searchData?.toVol ?? null,
      BoardListComponent.searchData?.location ?? null,
      BoardListComponent.searchData?.fromPrice ?? null,
      BoardListComponent.searchData?.toPrice ?? null
    )
    this.spinnerService.hide()
  }

  openOverlay() {

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const filePreviewPortal = new ComponentPortal(FilterBoardsComponent);

    // Attach ComponentPortal to PortalHost
    const ref = overlayRef.attach(filePreviewPortal);
    ref.instance.closePanel.subscribe(() => overlayRef.detach());
    ref.instance.searchData = BoardListComponent.searchData ?? new SearchData()
    ref.instance.onSearch = (searchData: SearchData) => {
      BoardListComponent.searchData = searchData
      this.searchBoard()
    }

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
    });
  }
}
