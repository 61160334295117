import { DocumentData } from "@angular/fire/compat/firestore";

export class UserData {

  public key: string | null = null
  public email: string | null = null

  public firstName: string | null = null
  public secondName: string | null = null

  public streetNr: string | null = null
  public city: string | null = null
  public country: string | null = null
  public district: string | null = null
  public post: string | null = null

  public phone: string | null = null
  public about: string | null = null

  //"ACCOUNT_COMMERCIAL" "ACCOUNT_PRIVATE"
  public accountType: string | null = null

  public lastUserApprovalDate: any | null
  public userCreationDate: any | null
  public lastLoginDate: any | null

  public imageUrl: string | null = null

  public markedToBeDeleted = false
  public deletionInitiated: any

  public allowTracking: boolean | null = null

  public supporterId: string | null = null

  static toObject(data: UserData): {} {
    const mappped = new Map(Object.entries(data));

    return Object.fromEntries(mappped)
  }

  static toUser(doc: DocumentData, userKey: string): UserData | null {

    if (doc === undefined || doc === null) {
      return null
    }

    const data = doc as UserData
    data.key = userKey

    return data
  }

  static toBasicObject(user: UserData): any {
    return {
      key: user.key,
      email: user.email,
      firstName: user.firstName,
      secondName: user.secondName,
      streetNr: user.streetNr,
      city: user.city,
      country: user.country,
      district: user.district,
      post: user.post,
      phone: user.phone,
    }
  }
}  
