<div class="vstack">

    <div class="content padval container-height" style="--padding: 8px 16px 0 16px;--minus: 0px;">
        <div class="vstack">

            <div class="spacer"></div>

            <div class="spacer-xl"></div>
            <div>
                <h3 class="superbold accent-1">{{
                    'pwauth-headline' | translate }}</h3>
                <div class="spacer-s"></div>
                <h5>{{
                    'ud-userdata-values-title-finish-register' | translate }}</h5>
                <div class="spacer-m"></div>
                <div class="spacer-m"></div>

                <!--values-->

                <div class="spacer-xxxl"></div>
                <div *ngIf="registerForm != undefined">
                    <form [formGroup]="registerForm" novalidate>
                        <mat-form-field appearance="fill" color="primary" class="dimval" style="--w: 100%">
                            <mat-label>{{ 'ud-first-name' | translate }}</mat-label>
                            <input maxlength="30" matInput formControlName="firstName" required>
                        </mat-form-field>
                    </form>
                </div>
                <div class="hstack">
                    <div class="spacer"></div>
                    <button mat-flat-button color="primary" (click)="onActionButton()">
                        <span>{{'save' | translate}}</span>
                    </button>
                </div>
            </div>

            <div class="spacer-xxl"></div>
            <div class="spacer-xxl"></div>
            <div class="spacer"></div>
        </div>
    </div>
</div>