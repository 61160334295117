import { Component, Input } from '@angular/core';
import { ListingData } from '../../../../model/business/listing'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RoomData } from '../../../../model/business/room';
import { PipesModule } from "../../../../../../../../src/app/pipes/pipes.module";

@Component({
  selector: 'app-list-element',
  standalone: true,
  templateUrl: './list-element.component.html',
  styleUrl: './list-element.component.scss',
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ]
})
export class ListElementComponent {
  @Input() listingData!: ListingData;
  @Input() roomData!: RoomData;

  getRoomImageUrl(): string {
    return this.listingData.photos.find(data => data['index'] == this.roomData!.key)?.['data'] ?? ""
  }
}
