import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from '../../../../../../../src/app/pipes/pipes.module';
import { ConfirmHandlerService } from '../../../../../../../src/app/services/confirm-handler.service';
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { FormHelperService } from '../../../../../../../src/app/utils/form-helper.service';
import { LanguageButtonComponent } from "../../../../../../../src/app/views/general/language-button/language-button.component";
import { fieldBoardImage } from '../../../enums/fields';
import { Globals } from '../../../global';
import { BoardData } from '../../../models/board';
import { LocationData } from '../../../models/location';
import { ImageService } from '../../../services/image.service';
import { FooterComponent } from "../../shared/footer/footer.component";
import { HeaderComponent } from "../../shared/header/header.component";
import { LocationOverviewComponent } from '../../shared/location-overview/location-overview.component';
import { LoginStateFieldComponent } from '../../shared/login-state-field/login-state-field.component';
import { BoarddataService } from '../../../services/boarddata.service';
import { TranslateService } from '../../../localization/translate.service';
import { CalendarComponent } from '../../shared/calendar/calendar.component';
import { Availability } from '../../../models/availability';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-add-board',
  standalone: true,
  templateUrl: './add-board.component.html',
  styleUrl: './add-board.component.scss',
  imports: [
    AngularMaterialModule,
    CommonModule,
    FormsModule,
    RouterModule,
    PipesModule,
    CalendarComponent,
    LoginStateFieldComponent,
    LanguageButtonComponent,
    HeaderComponent,
    FooterComponent
  ]
})
export class AddBoardComponent implements OnInit {

  Availability = Availability

  url1: any = null;
  url2: any = null;
  url3: any = null;
  url4: any = null;
  url5: any = null;

  public registerForm: FormGroup | undefined;
  static boardKey: string | null = null
  public boardData: BoardData | null = null
  private oldBoardData: BoardData | null = null

  constructor(
    private route: ActivatedRoute,
    public snackbarService: SnackbarService,
    private formHelperService: FormHelperService,
    private boardService: BoarddataService,
    private spinnerService: SpinnerService,
    private router: Router,
    private imageService: ImageService,
    private confirmHandler: ConfirmHandlerService,
    private dialog: MatDialog,
    public globals: Globals,
    private overlay: Overlay,
    private translate: TranslateService,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('AddBoard')
    analytics.logEvent("Open-AddBoard")

    this.route.queryParams.subscribe(params => {
      AddBoardComponent.boardKey = params['bkey'];
    })
  }

  ngOnInit(): void {
    this.initData()
  }

  async onSaveClicked() {
    let result = await this.saveBoard()
    if (result) {
      this.snackbarService.openSuccessBar()
      this.router.navigate(['myboards']);
    } else {
      this.snackbarService.openDefaultErrorSnackBar()
    }
  }

  saveAndPublishBoard() {

    if (this.canSave()) {
      this.boardData!.publish = true
      this.onSaveClicked()
    } else {
      this.snackbarService.openDefaultErrorSnackBar()
    }
  }

  dataHasChanged(): Boolean {

    return this.boardData?.availability != this.oldBoardData?.availability ||
      this.boardData?.aboutOffer != this.oldBoardData?.aboutOffer ||
      this.boardData?.length != this.oldBoardData?.length ||
      this.boardData?.location != this.oldBoardData?.location ||
      this.boardData?.volume != this.oldBoardData?.volume ||
      this.boardData?.width != this.oldBoardData?.width ||
      this.boardData?.price != this.oldBoardData?.price ||
      this.boardData?.title != this.oldBoardData?.title ||
      this.boardData?.subtitle != this.oldBoardData?.subtitle ||
      this.boardData?.thick != this.oldBoardData?.thick ||
      this.registerForm?.value.title != this.oldBoardData?.title ||
      this.registerForm?.value.subtitle != this.oldBoardData?.subtitle ||
      this.registerForm?.value.aboutOffer != this.oldBoardData?.aboutOffer ||
      this.newPhotoUploaded()
  }

  private async initData() {
    this.spinnerService.show()

    const board = await this.boardService.getBoard(AddBoardComponent.boardKey!) ?? new BoardData()
    this.oldBoardData = JSON.parse(JSON.stringify(board));

    this.boardData = board
    if (this.boardData.key) {
      this.imageService.loadImage(this.boardData.key!, '0', fieldBoardImage)
        .then((result) => {
          this.url1 = result ?? null;
        })
        .catch(errorResult => {
          console.error(errorResult);
        });
      this.imageService.loadImage(this.boardData.key!, '1', fieldBoardImage)
        .then((result) => {
          this.url2 = result ?? null;
        })
        .catch(errorResult => {
          console.error(errorResult);
        });
      this.imageService.loadImage(this.boardData.key!, '2', fieldBoardImage)
        .then((result) => {
          this.url3 = result ?? null;
        })
        .catch(errorResult => {
          console.error(errorResult);
        });
      this.imageService.loadImage(this.boardData.key!, '3', fieldBoardImage)
        .then((result) => {
          this.url4 = result ?? null;
        })
        .catch(errorResult => {
          console.error(errorResult);
        });
      this.imageService.loadImage(this.boardData.key!, '4', fieldBoardImage)
        .then((result) => {
          this.url5 = result ?? null;
        })
        .catch(errorResult => {
          console.error(errorResult);
        });
    }

    this.formInit();

    this.spinnerService.hide();
  }

  selectFile(event: any, num: number) { //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.snackbarService.openInvalidImageBar()
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.snackbarService.openInvalidImageBar()
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {

      if (num === 1) {
        ImageService.compressImage(reader.result as string, 1200).then(compressed => {
          this.url1 = compressed ?? null;
        })
      } else if (num === 2) {
        ImageService.compressImage(reader.result as string, 1200).then(compressed => {
          this.url2 = compressed ?? null;
        })
      } else if (num === 3) {
        ImageService.compressImage(reader.result as string, 1200).then(compressed => {
          this.url3 = compressed ?? null;
        })
      } else if (num === 4) {
        ImageService.compressImage(reader.result as string, 1200).then(compressed => {
          this.url4 = compressed ?? null;
        })
      } else {
        ImageService.compressImage(reader.result as string, 1200).then(compressed => {
          this.url5 = compressed ?? null;
        })
      }
    }
  }

  public canSave(): boolean {
    return this.boardData != undefined &&
      (this.boardData?.length ?? 0) > 0 &&
      !(this.registerForm?.invalid ?? true)
  }

  public canPublish(): boolean {
    return this.canSave() && this.boardData != undefined && this.boardData.price > 0 && (this.boardData.location?.length ?? 0) > 0
      && this.url1 != null
  }

  public async deleteBoard() {
    this.confirmHandler.openDialog(this.dialog, 'really_delete', null, null)
      .then(async userResponse => {
        if (userResponse == true) {
          await this.boardService.deleteBoard(AddBoardComponent.boardKey!)
          this.router.navigate(['myboards']);
        }
      })
  }

  public newPhotoUploaded() {
    return this.url1?.startsWith('data') || this.url2?.startsWith('data') || this.url3?.startsWith('data')
      || this.url4?.startsWith('data') || this.url5?.startsWith('data')
  }

  async saveBoard(): Promise<boolean> {

    return new Promise(async (result) => {
      if (!this.boardData) {
        console.error("No board data!")
        this.snackbarService.openDefaultErrorSnackBar()
        result(false)
        return
      }

      if (!this.registerForm) {
        console.log('form invalid');
        this.snackbarService.openFillAllDataBar()
        result(false)
        return
      }

      this.formHelperService.markFormGroupTouched(this.registerForm);

      if (this.registerForm.invalid) {
        console.log('form invalid');
        console.log(this.registerForm.getError);
        this.snackbarService.openFillAllDataBar()
        result(false)
        return
      }

      if (this.boardData.publish && !this.canPublish()) {
        this.snackbarService.openSnackBar(this.translate.translate('ab-missing-price-location'))
        result(false)
        return
      }

      this.boardData.title = this.registerForm.value.title;
      this.boardData.subtitle = this.registerForm.value.subtitle;
      this.boardData.aboutOffer = this.registerForm.value.aboutOffer;

      this.boardData.ownerAbout = this.globals.user?.about ?? null
      this.boardData.ownerKey = this.globals.user?.key ?? null
      this.boardData.ownerName = this.globals.user?.firstName ?? null
      this.boardData.ownerMail = this.globals.user?.email ?? null
      this.boardData.ownerImage = this.globals.user?.imageUrl ?? null

      if (this.boardData.availability != Availability.availableFrom) {
        this.boardData.availableFrom = null
        this.boardData.availableTo = null
      }

      console.log(this.boardData)

      this.spinnerService.show();

      try {

        let key = await this.boardService.saveBoard(AddBoardComponent.boardKey ?? null, this.boardData)
        if (!key) {
          this.spinnerService.hide();
          result(false)
          return
        }
        this.boardData.key = key
        AddBoardComponent.boardKey = key

        if (this.newPhotoUploaded()) {
          await this.saveImages()
          this.spinnerService.hide();
          this.snackbarService.openSuccessBar()
        } else {
          this.spinnerService.hide();
          this.snackbarService.openSuccessBar()
        }

        result(true)
      } catch (error) {
        console.error(error);
        this.spinnerService.hide();
        result(false)
      }
    });
  }

  async saveImages() {
    if (!this.boardData?.key) {
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }
    this.spinnerService.show()
    if (this.url1 && this.url1.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url1, '0');
      var result = await this.imageService.saveImage(this.boardData.key!, file, '0', fieldBoardImage)
      this.url1 = result?.['data']
    }
    if (this.url2 && this.url2.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url2, '1');
      var result = await this.imageService.saveImage(this.boardData.key!, file, '1', fieldBoardImage)
      this.url2 = result?.['data']
    }
    if (this.url3 && this.url3.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url3, '2');
      var result = await this.imageService.saveImage(this.boardData.key!, file, '2', fieldBoardImage)
      this.url3 = result?.['data']
    }
    if (this.url4 && this.url4.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url4, '3');
      var result = await this.imageService.saveImage(this.boardData.key!, file, '3', fieldBoardImage)
      this.url4 = result?.['data']
    }
    if (this.url5 && this.url5.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.url5, '4');
      var result = await this.imageService.saveImage(this.boardData.key!, file, '4', fieldBoardImage)
      this.url5 = result?.['data']
    }

    this.spinnerService.hide()
  }

  openLocationOverlay() {

    let overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const filePreviewPortal = new ComponentPortal(LocationOverviewComponent);

    // Attach ComponentPortal to PortalHost
    const ref = overlayRef.attach(filePreviewPortal);
    ref.instance.locationPanel.subscribe(() => {
      overlayRef.detach()
    });

    ref.instance.onSearch = (result: LocationData) => {
      if (this.boardData) {
        this.boardData.location = result.name
        this.boardData.lng = result.lng
        this.boardData.lat = result.lat
        this.boardData.geohash = result.geohash
      }

      overlayRef.detach();
    }

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
    });
  }

  private formInit() {

    if (!this.boardData) {
      console.error("No board data!")
      return
    }

    this.registerForm = new FormGroup({

      title: new FormControl(this.boardData.title || '', Validators.required),
      subtitle: new FormControl(this.boardData.subtitle || '', Validators.required),
      aboutOffer: new FormControl(this.boardData.aboutOffer || '', Validators.required),
    });
  }
}
