<div class="vstack">
    <app-header (buttonClick)="onActionButton()" [showBack]=false></app-header>

    <div class="content">
        <div class="vstack padval" style="--padding: 8px 16px 0 16px;">
            <div class="hstack">
                <h5 class="sec-color superbold" *ngIf="(searchData?.hasData() ?? false) == false">{{ titleName }}</h5>
                <h5 class="sec-color" *ngIf="searchData?.hasData()">{{ filterName }}</h5>
                <div class="spacer"></div>
                <span class="action-icon material-symbols-outlined" (click)="onActionButton()">
                    {{actionName}}
                </span>
            </div>
            <div class="spacer-s"></div>
            <div class="chip-container" *ngIf="searchData && searchData?.hasData()">
                <div *ngIf="searchData.fromLength || searchData.toLength" class="chip"> <span
                        *ngIf="searchData.fromLength">{{searchData.fromLength | cmToFeetInch }}</span>
                    -
                    <span *ngIf="searchData.toLength">{{searchData.toLength | cmToFeetInch }}</span>
                    <span class="chip-icon material-symbols-outlined" (click)="onRemoveLengthFilter()">
                        close
                    </span>
                </div>
                <div *ngIf="searchData.fromPrice || searchData.toPrice" class="chip"> <span
                        *ngIf="searchData.fromPrice">{{searchData.fromPrice}}€</span>
                    -
                    <span *ngIf="searchData.toPrice">{{searchData.toPrice }}€</span>
                    <span class="chip-icon material-symbols-outlined" (click)="onRemovePriceFilter()">
                        close
                    </span>
                </div>
                <div *ngIf="searchData.fromVol || searchData.toVol" class="chip"> <span
                        *ngIf="searchData.fromVol">{{searchData.fromVol }} Liter</span>
                    -
                    <span *ngIf="searchData.toVol">{{searchData.toVol }} Liter</span>
                    <span class="chip-icon material-symbols-outlined" (click)="onRemoveVolumeFilter()">
                        close
                    </span>
                </div>
                <div class="chip" *ngIf="searchData.location">{{searchData.location.name |
                    slice:0:14 }}
                    <span class="chip-icon material-symbols-outlined" (click)="onRemoveLocationFilter()">
                        close
                    </span>
                </div>
            </div>
            <div class="spacer-l" *ngIf="searchData?.hasData()"></div>
            <div *ngIf="noBoardsFound | async">
                <div class="spacer-xxl"></div>
                <h5>{{'bl-no-boards-found' | translate}}</h5>
            </div>
        </div>
        <div class="scroll-container container-height padval" style="--padding: 0 16px 0 16px; --minus: 108px;">
            <div *ngFor="let board of viewModel.boards | async">
                <a [routerLink]="['/board']" [queryParams]="{bkey: board.key}">

                    <div class="maval" style="--margin: 8px 0 8px 0">
                        <div class="hstack dimval" style="--h: 200px; --w: 100%;">
                            <div class="zstack">
                                <div class="imgval radiusval" style="--h: 200px;--w: 130px;--radius:8px 0 0 8px">
                                    <img *ngIf="firstPhoto(board.photos)" src={{firstPhoto(board.photos)}}
                                        alt="Photo of {{board.title}}">
                                    <img *ngIf="!firstPhoto(board.photos)"
                                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        class="accent-1-verylight" alt="">
                                </div>
                                <div class="vstack dimval" style="--h: 100%; --w: 100%;">
                                    <div class="spacer"></div>
                                    <p class="tag-elements unavailable"
                                        *ngIf="board.publish == false && board.availability != Availability.deleted">
                                        {{
                                        'ab-not-published' | translate }}</p>
                                    <p class="tag-elements unavailable"
                                        *ngIf="board.availability == Availability.sold || board.availability == Availability.deleted">
                                        {{
                                        board.availability | available }}</p>
                                    <p class="tag-elements reserved"
                                        *ngIf="board.availability == Availability.reserved">{{
                                        board.availability | available }}</p>
                                    <div class="spacer"
                                        *ngIf="board.availability == Availability.sold || board.availability == Availability.reserved || board.publish == false">
                                    </div>
                                    <div
                                        *ngIf="board.availability == Availability.availableFrom && (board.availableFrom ?? 0) > Date.now()">
                                        <p class="tag-elements-date"> {{ 'bl-from' | translate}} {{
                                            board.availableFrom | date }} <span *ngIf="board.availableTo"> - {{
                                                board.availableTo | date }}</span> </p>
                                    </div>
                                </div>
                            </div>

                            <div class=" dimval vstack padval accent-3-verylight"
                                style="--h: 100%; --w: 100%; --padding: 16px;">

                                <h5 class="superbold accent-1">
                                    <span *ngIf="(board.title?.length ?? 0) > 18">{{ board.title |
                                        slice:0:18}}..</span>
                                    <span *ngIf="(board.title?.length ?? 0) <= 18">{{ board.title
                                        }}</span>
                                </h5>
                                <div class="spacer-xs"></div>
                                <h4 class="superbold accent-2">
                                    <span *ngIf="(board.subtitle?.length ?? 0) > 20">{{ board.subtitle |
                                        slice:0:20}}..</span>
                                    <span *ngIf="(board.subtitle?.length ?? 0) <= 20">{{ board.subtitle
                                        }}</span>
                                </h4>
                                <p class="spacer"></p>

                                <div class="hstack">
                                    <p class="spacer"></p>
                                    <h3 class="hand">{{board.length | cmToFeetInch}}
                                        <span *ngIf="board.volume">| {{ board.volume }} Liter</span>
                                    </h3>
                                </div>
                                <!--<p>{{ board.aboutOffer| slice:0:50}}..</p>-->
                                <p class="divider"></p>
                                <p class="spacer-m"></p>
                                <div class="hstack">
                                    <h6 class="">
                                        <span *ngIf="(board.location?.length ?? 0) > 15">{{ board.location |
                                            slice:0:15}}..</span>
                                        <span *ngIf="(board.location?.length ?? 0) <= 15">{{ board.location
                                            }}</span>
                                    </h6>
                                    <p class="spacer"></p>
                                    <h6 class=""><span>{{board.price}}€</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="hstack fixbottom" *ngIf="this.router.url.includes('search')">

        <div class="spacer"></div>
        <a [routerLink]="['/add']">
            <div class="user-online-info hstack rotate" [style.transform]="'scaleY(' + scaleValue + ')'">
                <h6 class="">{{showText}}</h6>
                <div *ngIf="showRegister == false" class="online-dot"></div>
            </div>
        </a>
        <div class="spacer"></div>
    </div>
</div>