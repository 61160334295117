
export const dictionary = {

  // First variable don't delete and don't use. Only for detect available languages.
  'locales': {
    'en-US': 'en_english',
    'de-DE': 'de_german',
    'fr-FR': 'fr_french',
    'es-ES': 'es_spanish',
    'pt-PT': 'pt_portuguese',
  },

  'app-name': {
    'en-US': 'Surfrizz',
    'de-DE': 'Surfrizz',
    'fr-FR': 'Surfrizz',
    'es-ES': 'Surfrizz',
    'pt-PT': 'Surfrizz',
  },

  'back': {
    'en-US': 'Back',
    'de-DE': 'Zurück',
    'fr-FR': 'Retour',
    'es-ES': 'Volver',
    'pt-PT': 'Voltar',
  },
  'search': {
    'en-US': 'Search',
    'de-DE': 'Search',
    'fr-FR': 'Chercher',
    'es-ES': 'Buscar',
    'pt-PT': 'Buscar',
  },
  'forward': {
    'en-US': 'Forward',
    'de-DE': 'Weiter',
    'fr-FR': 'Avancer',
    'es-ES': 'Avanzar',
    'pt-PT': 'Avançar',
  },
  'choose': {
    'en-US': 'Choose',
    'de-DE': 'Wähle',
    'fr-FR': 'Choisir',
    'es-ES': 'Elegir',
    'pt-PT': 'Escolher',
  },
  'save': {
    'en-US': 'Save',
    'de-DE': 'Speichern',
    'fr-FR': 'Enregistrer',
    'es-ES': 'Guardar',
    'pt-PT': 'Guardar',
  },
  'delete': {
    'en-US': 'Delete',
    'de-DE': 'Löschen',
    'fr-FR': 'Supprimer',
    'es-ES': 'Eliminar',
    'pt-PT': 'Eliminar',
  },
  'cancel': {
    'en-US': 'Cancel',
    'de-DE': 'Abbrechen',
    'fr-FR': 'Annuler',
    'es-ES': 'Cancelar',
    'pt-PT': 'Cancelar',
  },
  'yes': {
    'en-US': 'Yes',
    'de-DE': 'Ja',
    'fr-FR': 'Oui',
    'es-ES': 'Sí',
    'pt-PT': 'Sim',
  },
  'no': {
    'en-US': 'No',
    'de-DE': 'Nein',
    'fr-FR': 'Non',
    'es-ES': 'No',
    'pt-PT': 'Não',
  },
  'or': {
    'en-US': 'Or',
    'de-DE': 'Oder',
    'fr-FR': 'Ou',
    'es-ES': 'O',
    'pt-PT': 'Ou'
  },
  'password': {
    'en-US': 'Password',
    'de-DE': 'Passwort',
    'fr-FR': 'Mot de passe',
    'es-ES': 'Contraseña',
    'pt-PT': 'Senha'
  },
  'price': {
    'en-US': 'Price',
    'de-DE': 'Preis',
    'fr-FR': 'Prix',
    'es-ES': 'Precio',
    'pt-PT': 'Preço',
  },
  'send': {
    'en-US': 'Send',
    'de-DE': 'Senden',
    'fr-FR': 'Envoyer',
    'es-ES': 'Enviar',
    'pt-PT': 'Enviar',
  },
  'email': {
    'en-US': 'Email',
    'de-DE': 'Email',
    'fr-FR': 'Email',
    'es-ES': 'Email',
    'pt-PT': 'Email',
  },
  'terms': {
    'en-US': 'Terms of Service',
    'de-DE': 'AGB',
    'fr-FR': 'Conditions d’utilisation',
    'es-ES': 'Términos de Servicio',
    'pt-PT': 'Termos de Serviço',
  },
  'data-protection': {
    'en-US': 'Privacy Policy',
    'de-DE': 'Datenschutzrichtlinie',
    'fr-FR': 'Politique de confidentialité',
    'es-ES': 'Política de Privacidad',
    'pt-PT': 'Política de Privacidade',
  },
  'imprint': {
    'en-US': 'Imprint',
    'de-DE': 'Impressum',
    'fr-FR': 'Mentions légales',
    'es-ES': 'Aviso legal',
    'pt-PT': 'Impressão',
  },
  'analytics-question': {
    'en-US': 'We use Google Analytics to improve our services. Do you allow us to track your usage and save your decision in the browser?',
    'de-DE': 'Wir verwenden Google Analytics, um unsere Dienste zu verbessern. Erlaube uns, die Nutzung zu tracken und deine Auswahl im Browser zu speichern?',
    'fr-FR': 'Nous utilisons Google Analytics pour améliorer nos services. Acceptez-vous de nous permettre de suivre votre utilisation et d\'enregistrer votre décision dans le navigateur ?',
    'es-ES': 'Usamos Google Analytics para mejorar nuestros servicios. ¿Nos permite realizar un seguimiento de su uso y guardar su decisión en el navegador?',
    'pt-PT': 'Utilizamos o Google Analytics para melhorar os nossos serviços. Permite-nos acompanhar o seu uso e guardar a sua decisão no navegador?',
  },
  'all-cookies': {
    'en-US': 'Allow cookies and Analytics',
    'de-DE': 'Analytics und Cookies erlauben',
    'fr-FR': 'Autoriser Analytics et les cookies',
    'es-ES': 'Permitir Analytics y cookies',
    'pt-PT': 'Permitir Analytics e cookies',
  },
  'just-cookies': {
    'en-US': 'Only technically necessary cookies',
    'de-DE': 'Nur technisch notwendige Cookies',
    'fr-FR': 'Uniquement les cookies nécessaires',
    'es-ES': 'Solo los cookies técnicamente necesarios',
    'pt-PT': 'Apenas cookies tecnicamente necessários',
  },
  'report': {
    'en-US': 'Report content',
    'de-DE': 'Inhalt melden',
    'fr-FR': 'Signaler le contenu',
    'es-ES': 'Reportar contenido',
    'pt-PT': 'Denunciar conteúdo',
  },
  'report-mail': {
    'en-US': 'Place your message here, please describe what needs to be reported:',
    'de-DE': 'Deine Nachricht bitte hier her, erzähle uns was Du melden möchtest:',
    'fr-FR': 'Placez votre message ici, veuillez décrire ce qui doit être signalé:',
    'es-ES': 'Coloque su mensaje aquí, por favor describa qué debe ser reportado:',
    'pt-PT': 'Coloque a sua mensagem aqui, por favor descreva o que precisa ser reportado:'
  },

  // add board

  'ab-publish-title': {
    'en-US': 'Required *',
    'de-DE': 'Pflichtfeld *',
    'fr-FR': 'Champ obligatoire *',
    'es-ES': 'Obligatorio *',
    'pt-PT': 'Obrigatório *',
  },
  'ab-publish': {
    'en-US': 'Publish',
    'de-DE': 'Veröffentlichen',
    'fr-FR': 'Publier',
    'es-ES': 'Publicar',
    'pt-PT': 'Publicar',
  },
  'ab-just-save-1': {
    'en-US': 'Just save,',
    'de-DE': 'Nur speichern,',
    'fr-FR': 'Enregistrer seulement,',
    'es-ES': 'Solo guardar,',
    'pt-PT': 'Apenas salvar,',
  },
  'ab-just-save-2': {
    'en-US': 'publish later',
    'de-DE': 'später veröffentlichen',
    'fr-FR': 'publier plus tard',
    'es-ES': 'publicar más tarde',
    'pt-PT': 'publicar depois',
  },
  'ab-location-title': {
    'en-US': 'Where do you sell the board?',
    'de-DE': 'Wo verkaufst Du?',
    'fr-FR': 'Où vendez-vous la planche?',
    'es-ES': '¿Dónde vendes la tabla?',
    'pt-PT': 'Onde você vende a prancha?',
  },
  'ab-price': {
    'en-US': 'Price',
    'de-DE': 'Preis',
    'fr-FR': 'Prix',
    'es-ES': 'Precio',
    'pt-PT': 'Preço',
  },
  'ab-availability': {
    'en-US': 'Availability',
    'de-DE': 'Verfügbarkeit',
    'fr-FR': 'Disponibilité',
    'es-ES': 'Disponibilidad',
    'pt-PT': 'Disponibilidade',
  },
  'ab-availability-available': {
    'en-US': 'Available',
    'de-DE': 'Verfügbar',
    'fr-FR': 'Disponible',
    'es-ES': 'Disponible',
    'pt-PT': 'Disponível',
  },
  'ab-availability-notavailable': {
    'en-US': 'Not available',
    'de-DE': 'Nicht verfügbar',
    'fr-FR': 'Non disponible',
    'es-ES': 'No disponible',
    'pt-PT': 'Indisponível',
  },
  'ab-availability-deleted': {
    'en-US': 'Deleted',
    'de-DE': 'Gelöscht',
    'fr-FR': 'Supprimé',
    'es-ES': 'Eliminado',
    'pt-PT': 'Excluído',
  },
  'ab-availability-reserved': {
    'en-US': 'Reserved',
    'de-DE': 'Reserviert',
    'fr-FR': 'Réservé',
    'es-ES': 'Reservado',
    'pt-PT': 'Reservado',
  },
  'ab-availability-availablefrom': {
    'en-US': 'Available from (to)',
    'de-DE': 'Verfügbar ab (bis)',
    'fr-FR': 'Disponible du (au)',
    'es-ES': 'Disponible desde (hasta)',
    'pt-PT': 'Disponível de (a)',
  },
  'ab-availability-sold': {
    'en-US': 'Sold',
    'de-DE': 'Verkauft',
    'fr-FR': 'Vendu',
    'es-ES': 'Vendido',
    'pt-PT': 'Vendido',
  },
  'ab-sell-title': {
    'en-US': 'Sales details',
    'de-DE': 'Verkaufsdetails',
    'fr-FR': 'Détails de vente',
    'es-ES': 'Detalles de venta',
    'pt-PT': 'Detalhes de venda',
  },
  'ab-not-published': {
    'en-US': 'Not published',
    'de-DE': 'Nicht veröffentlicht',
    'fr-FR': 'Non publié',
    'es-ES': 'No publicado',
    'pt-PT': 'Não publicado',
  },
  'ab-width': {
    'en-US': 'Width',
    'de-DE': 'Breite',
    'fr-FR': 'Largeur',
    'es-ES': 'Anchura',
    'pt-PT': 'Largura',
  },
  'ab-condition': {
    'en-US': 'Condition',
    'de-DE': 'Zustand',
    'fr-FR': 'État',
    'es-ES': 'Condición',
    'pt-PT': 'Condição',
  },
  'ab-condition-new': {
    'en-US': 'New',
    'de-DE': 'Neu',
    'fr-FR': 'Neuf',
    'es-ES': 'Nuevo',
    'pt-PT': 'Novo',
  },
  'ab-condition-used': {
    'en-US': 'Used',
    'de-DE': 'Gebraucht',
    'fr-FR': 'Usagé',
    'es-ES': 'Usado',
    'pt-PT': 'Usado',
  },
  'ab-thickness': {
    'en-US': 'Thickness',
    'de-DE': 'Dicke',
    'fr-FR': 'Épaisseur',
    'es-ES': 'Espesor',
    'pt-PT': 'Espessura',
  },
  'ab-volume': {
    'en-US': 'Volume',
    'de-DE': 'Volumen',
    'fr-FR': 'Volume',
    'es-ES': 'Volumen',
    'pt-PT': 'Volume',
  },
  'ab-length': {
    'en-US': 'Length',
    'de-DE': 'Länge',
    'fr-FR': 'Longueur',
    'es-ES': 'Longitud',
    'pt-PT': 'Comprimento',
  },
  'ab-description': {
    'en-US': 'Description',
    'de-DE': 'Beschreibung',
    'fr-FR': 'Description',
    'es-ES': 'Descripción',
    'pt-PT': 'Descrição',
  },
  'ab-subtitle': {
    'en-US': 'Subtitle',
    'de-DE': 'Untertitel',
    'fr-FR': 'Sous-titre',
    'es-ES': 'Subtítulo',
    'pt-PT': 'Subtítulo',
  },
  'ab-title': {
    'en-US': 'Title',
    'de-DE': 'Titel',
    'fr-FR': 'Titre',
    'es-ES': 'Título',
    'pt-PT': 'Título',
  },
  'ab-headline-1': {
    'en-US': 'Offer new board - Free',
    'de-DE': 'Neues Board anbieten - Gratis',
    'fr-FR': 'Proposer un nouveau tableau - Gratuit',
    'es-ES': 'Ofrecer nuevo tablero - Gratis',
    'pt-PT': 'Oferecer novo quadro - Grátis'
  },
  'ab-headline-2': {
    'en-US': 'Board Details',
    'de-DE': 'Board Details',
    'fr-FR': 'Détails du tableau',
    'es-ES': 'Detalles del tablero',
    'pt-PT': 'Detalhes do quadro'
  },
  'ab-missing-price-location': {
    'en-US': 'Price and location missing',
    'de-DE': 'Preis und Ort müssen gesetzt sein',
    'fr-FR': 'Prix et emplacement manquants',
    'es-ES': 'Precio y ubicación faltantes',
    'pt-PT': 'Falta preço e localização',
  },

  // board detail

  'bd-from-title': {
    'en-US': 'Offer from',
    'de-DE': 'Angebot von',
    'fr-FR': 'Offre de',
    'es-ES': 'Oferta de',
    'pt-PT': 'Oferta de',
  },
  'bd-edit': {
    'en-US': 'Edit',
    'de-DE': 'Bearbeiten',
    'fr-FR': 'Modifier',
    'es-ES': 'Editar',
    'pt-PT': 'Editar',
  },
  'bd-request': {
    'en-US': 'Request',
    'de-DE': 'Anfrage',
    'fr-FR': 'Demande',
    'es-ES': 'Solicitud',
    'pt-PT': 'Solicitar',
  },

  // board list

  'bl-no-boards-found': {
    'en-US': 'No surfboards found',
    'de-DE': 'Keine Surfboards gefunden',
    'fr-FR': 'Aucune planche de surf trouvée',
    'es-ES': 'No se encontraron tablas de surf',
    'pt-PT': 'Nenhuma prancha de surf encontrada',
  },
  'bl-my-boards': {
    'en-US': 'My surfboards',
    'de-DE': 'Meine Surfboards',
    'fr-FR': 'Mes planches de surf',
    'es-ES': 'Mis tablas de surf',
    'pt-PT': 'Minhas pranchas de surf',
  },
  'bl-from': {
    'en-US': 'From',
    'de-DE': 'Ab',
    'fr-FR': 'À partir de',
    'es-ES': 'Desde',
    'pt-PT': 'A partir de',
  },
  'bl-new-boards': {
    'en-US': 'New surfboards',
    'de-DE': 'Neue Surfboards',
    'fr-FR': 'Nouvelles planches de surf',
    'es-ES': 'Nuevas tablas de surf',
    'pt-PT': 'Novas pranchas de surf',
  },
  'bl-filtered': {
    'en-US': 'Filtered',
    'de-DE': 'Gefiltert',
    'fr-FR': 'Filtré',
    'es-ES': 'Filtrado',
    'pt-PT': 'Filtrado',
  },
  'bl-visitors': {
    'en-US': 'people browsing surfboards right now',
    'de-DE': 'Leute suchen gerade Surfboards',
    'fr-FR': 'des gens cherchent des planches de surf maintenant',
    'es-ES': 'personas buscan tablas de surf ahora',
    'pt-PT': 'pessoas procuram pranchas de surf agora',
  },
  'bl-promote-register': {
    'en-US': 'List your surfboard for free now!',
    'de-DE': 'Jetzt gratis Surfboard einstellen!',
    'fr-FR': 'Ajoutez votre planche de surf gratuitement !',
    'es-ES': '¡Publica tu tabla de surf gratis ahora!',
    'pt-PT': 'Anuncie sua prancha de surf grátis agora!',
  },

  // lister surfboard list

  'bll-all-boards': {
    'en-US': 'See all surfboards from ',
    'de-DE': 'Alle Surfboards von ',
    'fr-FR': 'Voir toutes les planches de surf de ',
    'es-ES': 'Ver todas las tablas de surf de ',
    'pt-PT': 'Ver todas as pranchas de surf de ',
  },

  // filter boards

  'fb-to': {
    'en-US': 'to',
    'de-DE': 'bis',
    'fr-FR': 'à',
    'es-ES': 'a',
    'pt-PT': 'até',
  },
  'fb-title': {
    'en-US': 'Filter your results',
    'de-DE': 'Ergebnisse filtern',
    'fr-FR': 'Filtrez vos résultats',
    'es-ES': 'Filtra tus resultados',
    'pt-PT': 'Filtre seus resultados',
  },
  'fb-length-from': {
    'en-US': 'Length from',
    'de-DE': 'Länge ab',
    'fr-FR': 'Longueur à partir de',
    'es-ES': 'Longitud desde',
    'pt-PT': 'Comprimento a partir de',
  },
  'fb-volume-from': {
    'en-US': 'Volume from',
    'de-DE': 'Volumen ab',
    'fr-FR': 'Volume à partir de',
    'es-ES': 'Volumen desde',
    'pt-PT': 'Volume a partir de',
  },
  'fb-location-radius': {
    'en-US': 'Location - radius 50km',
    'de-DE': 'Standort - Radius 50km',
    'fr-FR': 'Emplacement - rayon 50km',
    'es-ES': 'Ubicación - radio 50 km',
    'pt-PT': 'Localização - raio 50 km',
  },
  'fb-price-from': {
    'en-US': 'Price from',
    'de-DE': 'Preis ab',
    'fr-FR': 'Prix à partir de',
    'es-ES': 'Precio desde',
    'pt-PT': 'Preço a partir de',
  },

  // contact detail

  'cd-just-now-text': {
    'en-US': 'Just now',
    'de-DE': 'Gerade eben',
    'fr-FR': 'À l\'instant',
    'es-ES': 'Ahora mismo',
    'pt-PT': 'Agora mesmo',
  },
  'cd-board-removed': {
    'en-US': 'Board is no longer available.',
    'de-DE': 'Das Board ist nicht mehr verfügbar.',
    'fr-FR': 'La planche de surf n’est plus disponible.',
    'es-ES': 'La tabla ya no está disponible.',
    'pt-PT': 'A prancha já não está disponível.',
  },

  // contact list

  'cl-no-messages': {
    'en-US': 'No messages yet',
    'de-DE': 'Noch keine Nachrichten',
    'fr-FR': 'Pas encore de messages',
    'es-ES': 'Aún no hay mensajes',
    'pt-PT': 'Ainda não há mensagens',
  },

  // location picker

  'lo-headline': {
    'en-US': 'Find your location',
    'de-DE': 'Finde deinen Standort',
    'fr-FR': 'Trouvez votre emplacement',
    'es-ES': 'Encuentra tu ubicación',
    'pt-PT': 'Encontre a sua localização',
  },
  'lo-no-location': {
    'en-US': 'No location found',
    'de-DE': 'Kein Standort gefunden',
    'fr-FR': 'Aucun emplacement trouvé',
    'es-ES': 'No se encontró ninguna ubicación',
    'pt-PT': 'Nenhuma localização encontrada',
  },

  // menu

  'menu-my-account': {
    'en-US': 'My account',
    'de-DE': 'Mein Konto',
    'fr-FR': 'Mon compte',
    'es-ES': 'Mi cuenta',
    'pt-PT': 'Minha conta',
  },
  'menu-my-boards': {
    'en-US': 'My surfboards',
    'de-DE': 'Meine Surfboards',
    'fr-FR': 'Mes planches de surf',
    'es-ES': 'Mis tablas de surf',
    'pt-PT': 'Minhas pranchas de surf',
  },
  'menu-add-board': {
    'en-US': 'Add board',
    'de-DE': 'Board hinzufügen',
    'fr-FR': 'Ajouter un tableau',
    'es-ES': 'Añadir tablero',
    'pt-PT': 'Adicionar quadro',
  },
  'menu-messages': {
    'en-US': 'Messages',
    'de-DE': 'Nachrichten',
    'fr-FR': 'Messages',
    'es-ES': 'Mensajes',
    'pt-PT': 'Mensagens',
  },
  'menu-logout': {
    'en-US': 'Logout',
    'de-DE': 'Abmelden',
    'fr-FR': 'Déconnexion',
    'es-ES': 'Cerrar sesión',
    'pt-PT': 'Sair',
  },

  // auth

  'pwauth-headline': {
    'en-US': 'Welcome to Surfrizz!',
    'de-DE': 'Willkommen bei Surfrizz!',
    'fr-FR': 'Bienvenue sur Surfrizz!',
    'es-ES': '¡Bienvenido a Surfrizz!',
    'pt-PT': 'Bem-vindo ao Surfrizz!',
  },
  'pwauth-login': {
    'en-US': 'Login',
    'de-DE': 'Anmelden',
    'fr-FR': 'Connexion',
    'es-ES': 'Iniciar sesión',
    'pt-PT': 'Iniciar sessão',
  },
  'pwauth-login-email': {
    'en-US': 'Login with Email only',
    'de-DE': 'Anmeldung nur mit Email',
    'fr-FR': 'Connexion avec e-mail uniquement',
    'es-ES': 'Iniciar sesión solo con correo electrónico',
    'pt-PT': 'Login apenas com e-mail'
  },
  'pwauth-login-pw': {
    'en-US': 'Login with Email and Password',
    'de-DE': 'Anmeldung mit Email und Passwort',
    'fr-FR': 'Connexion avec e-mail et mot de passe',
    'es-ES': 'Iniciar sesión con correo electrónico y contraseña',
    'pt-PT': 'Login com e-mail e senha'
  },
  'pwauth-register': {
    'en-US': 'Register',
    'de-DE': 'Registrieren',
    'fr-FR': 'S\'inscrire',
    'es-ES': 'Registrarse',
    'pt-PT': 'Registrar',
  },
  'pwauth-login-title': {
    'en-US': 'You already have an account? Just login here: ',
    'de-DE': 'Du hast bereits ein Konto bei uns? Logge Dich hier ein: ',
    'fr-FR': 'Vous avez déjà un compte ? Connectez-vous ici: ',
    'es-ES': '¿Ya tienes una cuenta? ¡Inicia sesión aquí: ',
    'pt-PT': 'Já tem uma conta? Faça login aqui: '
  },
  'pwauth-email-sent': {
    'en-US': 'Email sent, click Login there. You will be logged in automatically.',
    'de-DE': 'Email gesendet, bitte klicke dort auf Login. Du wirst dann automatisch angemeldet.',
    'fr-FR': 'E-mail envoyée, cliquez sur Connexion. Vous serez connecté automatiquement.',
    'es-ES': 'Correo enviado, haz clic en Iniciar sesión. Serás conectado automáticamente.',
    'pt-PT': 'E-mail enviado, clique em Entrar. Você será conectado automaticamente.',
  },
  'pwauth-email-correct': {
    'en-US': 'Correct email',
    'de-DE': 'Email korrigieren',
    'fr-FR': 'Corriger l’e-mail',
    'es-ES': 'Corregir correo',
    'pt-PT': 'Corrigir e-mail',
  },
  'pwauth-request-order-accept-sign-up': {
    'en-US': 'Click “Sign up with” to agree to our Terms of Service and acknowledge that our Privacy Policy applies to you.',
    'de-DE': 'Klicke auf „Sign up with“, um unseren AGB zuzustimmen und anzuerkennen, dass unsere Datenschutzrichtlinie für dich gilt.',
    'fr-FR': 'Cliquez sur « Sign up with » pour accepter nos conditions d’utilisation et reconnaître que notre politique de confidentialité s’applique à vous.',
    'es-ES': 'Haz clic en "Sign up with" para aceptar nuestros Términos de Servicio y reconocer que nuestra Política de Privacidad se aplica a ti.',
    'pt-PT': 'Clique em "Sign up with" para concordar com os nossos Termos de Serviço e reconhecer que a nossa Política de Privacidade se aplica a você.'
  },
  'pwauth-request-order-accept-sign-in': {
    'en-US': 'Click “Login with” to agree to our Terms of Service and acknowledge that our Privacy Policy applies to you.',
    'de-DE': 'Klicke auf „Login with", um unseren AGB zuzustimmen und anzuerkennen, dass unsere Datenschutzrichtlinie für dich gilt.',
    'fr-FR': 'Cliquez sur « Login with » pour accepter nos conditions d’utilisation et reconnaître que notre politique de confidentialité s’applique à vous.',
    'es-ES': 'Haz clic en "Login with" para aceptar nuestros Términos de Servicio y reconocer que nuestra Política de Privacidad se aplica a ti.',
    'pt-PT': 'Clique em "Login with" para concordar com os nossos Termos de Serviço e reconhecer que a nossa Política de Privacidade se aplica a você.'
  },
  'pwauth-login-error': {
    'en-US': 'Email unknown or something went wrong',
    'de-DE': 'E-Mail unbekannt oder etwas ist schiefgelaufen',
    'fr-FR': 'Email inconnu ou une erreur est survenue',
    'es-ES': 'Correo desconocido o algo salió mal',
    'pt-PT': 'E-mail desconhecido ou algo deu errado',
  },
  'pwauth-email-confirmation': {
    'en-US': 'Please provide your email again for confirmation',
    'de-DE': 'Bitte gib Deine E-Mail noch einmal zur Bestätigung an',
    'fr-FR': 'Veuillez fournir votre email pour confirmation',
    'es-ES': 'Por favor, proporciona tu correo electrónico nuevamente para confirmar',
    'pt-PT': 'Por favor, forneça seu e-mail novamente para confirmação',
  },

  // user details

  'ud-userdata-values-title': {
    'en-US': 'Your information',
    'de-DE': 'Deine Infos',
    'fr-FR': 'Vos informations',
    'es-ES': 'Tu información',
    'pt-PT': 'Suas informações',
  },
  'ud-userdata-values-title-finish-register': {
    'en-US': 'Complete registration',
    'de-DE': 'Registrierung abschließen',
    'fr-FR': 'Terminer l\'enregistrement',
    'es-ES': 'Completar el registro',
    'pt-PT': 'Concluir registro',
  },
  'ud-first-name': {
    'en-US': 'First name',
    'de-DE': 'Vorname',
    'fr-FR': 'Prénom',
    'es-ES': 'Nombre',
    'pt-PT': 'Nome',
  },
  'ud-second-name': {
    'en-US': 'Second name',
    'de-DE': 'Nachname',
    'fr-FR': 'Nom',
    'es-ES': 'Apellido',
    'pt-PT': 'Sobrenome',
  },
  'ud-about-you': {
    'en-US': 'About you',
    'de-DE': 'Über Dich',
    'fr-FR': 'À propos de vous',
    'es-ES': 'Acerca de ti',
    'pt-PT': 'Sobre você',
  },
  'ud-edit-account': {
    'en-US': 'Account settings',
    'de-DE': 'Einstellungen',
    'fr-FR': 'Paramètres du compte',
    'es-ES': 'Configuración de la cuenta',
    'pt-PT': 'Configurações da conta',
  },
  'ud-setpw-headline': {
    'en-US': 'Set password',
    'de-DE': 'Passwort festlegen',
    'fr-FR': 'Définir un mot de passe',
    'es-ES': 'Establecer contraseña',
    'pt-PT': 'Definir senha'
  },
  'ud-setpw-text': {
    'en-US': 'Set a password can only be done if you have been logged in recently.',
    'de-DE': 'Ein Passwort kann nur gesetzt werden, wenn Du Dich kürzlich eingeloggt hast.',
    'fr-FR': 'La définition d\'un mot de passe ne peut être effectuée que si vous vous êtes connecté récemment.',
    'es-ES': 'Establecer una contraseña solo se puede hacer si has iniciado sesión recientemente.',
    'pt-PT': 'Definir uma senha só pode ser feito se você tiver feito login recentemente.'
  },
  'ud-setpw-field': {
    'en-US': 'Password (min 8 characters, max 20)',
    'de-DE': 'Passwort (min. 8 Zeichen, max. 20)',
    'fr-FR': 'Mot de passe (8 à 20 caractères)',
    'es-ES': 'Contraseña (mínimo 8 caracteres, máximo 20)',
    'pt-PT': 'Senha (mínimo 8 caracteres, máximo 20)'
  },
  'ud-delete-headline': {
    'en-US': 'Here, you can delete your account',
    'de-DE': 'Hier kannst Du Dein Konto löschen',
    'fr-FR': 'Ici, vous pouvez supprimer votre compte',
    'es-ES': 'Aquí puedes eliminar tu cuenta',
    'pt-PT': 'Aqui, você pode excluir sua conta',
  },
  'ud-enable-deletion': {
    'en-US': 'Enable deletion',
    'de-DE': 'Löschung aktivieren',
    'fr-FR': 'Activer la suppression',
    'es-ES': 'Habilitar eliminación',
    'pt-PT': 'Ativar exclusão',
  },
  'ud-delete-account': {
    'en-US': 'Delete account',
    'de-DE': 'Konto löschen',
    'fr-FR': 'Supprimer le compte',
    'es-ES': 'Eliminar cuenta',
    'pt-PT': 'Excluir conta',
  },
  'ud-delete-account-success': {
    'en-US': 'Successfully deleted',
    'de-DE': 'Erfolgreich gelöscht',
    'fr-FR': 'Supprimé avec succès',
    'es-ES': 'Eliminado con éxito',
    'pt-PT': 'Excluído com sucesso',
  },
  'ud-login-email-sent': {
    'en-US': 'Login email sent',
    'de-DE': 'Login Email gesendet',
    'fr-FR': 'Email de connexion envoyé',
    'es-ES': 'Correo de inicio de sesión enviado',
    'pt-PT': 'E-mail de login enviado',
  },
  'ud-login-pw-updated': {
    'en-US': 'Password successfully set',
    'de-DE': 'Password erfolgreich gesetzt',
    'fr-FR': 'Mot de passe défini avec succès',
    'es-ES': 'Contraseña establecida con éxito',
    'pt-PT': 'Senha definida com sucesso'
  },
  'ud-login-pw-not-valid': {
    'en-US': 'Password not valid',
    'de-DE': 'Password nicht zulässig',
    'fr-FR': 'Mot de passe non valide',
    'es-ES': 'Contraseña no válida',
    'pt-PT': 'Senha inválida'
  },
  'ud-relogin': {
    'en-US': 'You need to re-login',
    'de-DE': 'Du musst Dich neu einloggen',
    'fr-FR': 'Vous devez vous reconnecter',
    'es-ES': 'Necesitas volver a iniciar sesión',
    'pt-PT': 'Você precisa fazer login novamente',
  },

  // welcome

  'welcome-userdata-headline-1': {
    'en-US': 'Got to surf to get the rizz!',
    'de-DE': 'Got to surf to get the rizz!',
    'fr-FR': 'Il faut surfer pour obtenir le rizz!',
    'es-ES': '¡Hay que surfear para conseguir el rizz!',
    'pt-PT': 'Tem que surfar para conseguir o rizz!',
  },
  'welcome-userdata-headline-2': {
    'en-US': 'Find, sell or swap surfboards.',
    'de-DE': 'Finde, verkaufe oder tausche Surfboards.',
    'fr-FR': 'Trouvez, vendez ou échangez des planches de surf.',
    'es-ES': 'Encuentra, vende o intercambia tablas de surf.',
    'pt-PT': 'Encontre, venda ou troque pranchas de surf.',
  },
  'welcome-coming-soon': {
    'en-US': 'Coming soon!',
    'de-DE': 'Demnächst!',
    'fr-FR': 'Bientôt disponible!',
    'es-ES': '¡Próximamente!',
    'pt-PT': 'Em breve!',
  },
  'welcome-find': {
    'en-US': 'Find',
    'de-DE': 'Suche',
    'fr-FR': 'Trouver',
    'es-ES': 'Encontrar',
    'pt-PT': 'Encontrar',
  },
  'welcome-offer': {
    'en-US': 'Sell',
    'de-DE': 'Biete',
    'fr-FR': 'Vends',
    'es-ES': 'Vende',
    'pt-PT': 'Vende',
  },
  'welcome-contact': {
    'en-US': 'Contact us!',
    'de-DE': 'Schreibe uns!',
    'fr-FR': 'Contactez-nous !',
    'es-ES': '¡Contáctanos!',
    'pt-PT': 'Contacte-nos!',
  },
  'welcome-contact-sub': {
    'en-US': 'Questions, ideas, wishes? We love feedback!',
    'de-DE': 'Fragen, Ideen, Wünsche? Wir lieben Feedback!',
    'fr-FR': 'Questions, idées, souhaits ? Nous adorons vos retours !',
    'es-ES': '¿Preguntas, ideas, deseos? ¡Nos encanta recibir comentarios!',
    'pt-PT': 'Questões, ideias, desejos? Adoramos feedback!',
  },

  // add board info page

  'abi-title': {
    'en-US': 'Sell surfboards on Surfrizz is',
    'de-DE': 'Surfboards auf Surfrizz anzubieten ist',
    'fr-FR': 'Vendre des planches de surf sur Surfrizz est',
    'es-ES': 'Vender tablas de surf en Surfrizz es',
    'pt-PT': 'Vender pranchas de surf no Surfrizz é',
  },
  'abi-dot-1': {
    'en-US': '.. completely for free!',
    'de-DE': '.. völlig kostenlos!',
    'fr-FR': '.. complètement gratuit !',
    'es-ES': '.. ¡completamente gratis!',
    'pt-PT': '.. completamente grátis!',
  },
  'abi-dot-2': {
    'en-US': 'Super easy and fast. You just need an email address, your name and 3+ words about yourself.',
    'de-DE': 'Super einfach und schnell. Du brauchst nur eine E-Mail-Adresse, deinen Namen und 3+ Wörter über dich.',
    'fr-FR': 'Super facile et rapide. Il vous suffit d\'une adresse e-mail, de votre nom et de 3+ mots sur vous-même.',
    'es-ES': 'Muy fácil y rápido. Solo necesitas una dirección de correo electrónico, tu nombre y 3+ palabras sobre ti.',
    'pt-PT': 'Super fácil e rápido. Você só precisa de um endereço de e-mail, seu nome e 3+ palavras sobre você.',
  },
  'abi-dot-3': {
    'en-US': 'You don\'t like it? Delete your account anytime!',
    'de-DE': 'Gefällt es dir nicht? Lösche dein Konto jederzeit!',
    'fr-FR': 'Ça ne vous plaît pas ? Supprimez votre compte à tout moment !',
    'es-ES': '¿No te gusta? ¡Elimina tu cuenta en cualquier momento!',
    'pt-PT': 'Não gostou? Exclua sua conta a qualquer momento!',
  },
  'abi-dot-4': {
    'en-US': 'Any questions? Feel free to reach out.',
    'de-DE': 'Hast du Fragen? Kontaktiere uns gerne.',
    'fr-FR': 'Des questions ? N’hésitez pas à nous contacter.',
    'es-ES': '¿Tienes preguntas? ¡No dudes en contactarnos!',
    'pt-PT': 'Alguma dúvida? Sinta-se à vontade para entrar em contato.',
  },
  'abi-contact': {
    'en-US': 'Send us a message, now!',
    'de-DE': 'Schreibe uns, jetzt!',
    'fr-FR': 'Envoyez-nous un message, maintenant !',
    'es-ES': '¡Envíanos un mensaje ahora!',
    'pt-PT': 'Envie-nos uma mensagem, agora!',
  },
  'abi-action': {
    'en-US': 'Forward',
    'de-DE': 'Weiter',
    'fr-FR': 'Transférer',
    'es-ES': 'Reenviar',
    'pt-PT': 'Encaminhar',
  },

  // error and toasts

  'fill_all_data': {
    'en-US': 'Please fill in all data',
    'de-DE': 'Bitte alles ausfüllen',
    'fr-FR': 'Veuillez remplir toutes les données',
    'es-ES': 'Por favor, complete todos los datos',
    'pt-PT': 'Por favor, preencha todos os dados',
  },
  'emailknown_confirm': {
    'en-US': 'Email already known, please confirm',
    'de-DE': 'Email bekannt, bitte bestätigen',
    'fr-FR': 'Email déjà connu, veuillez confirmer',
    'es-ES': 'Email ya conocido, por favor confirme',
    'pt-PT': 'Email já conhecido, por favor confirme',
  },
  'success': {
    'en-US': 'Success!',
    'de-DE': 'Erfolgreich!',
    'fr-FR': 'Succès!',
    'es-ES': '¡Éxito!',
    'pt-PT': 'Sucesso!',
  },
  'try_again': {
    'en-US': 'Error, please try again!',
    'de-DE': 'Fehler, bitte erneut versuchen!',
    'fr-FR': 'Erreur, veuillez réessayer!',
    'es-ES': 'Error, ¡por favor intenta de nuevo!',
    'pt-PT': 'Erro, por favor tente novamente!',
  },
  'mail_not_valid': {
    'en-US': 'Please enter a valid email address!',
    'de-DE': 'Email ungültig!',
    'fr-FR': 'Veuillez entrer une adresse email valide!',
    'es-ES': '¡Por favor, introduce una dirección de correo electrónico válida!',
    'pt-PT': 'Por favor, insira um endereço de e-mail válido!',
  },
  'mail_pw_not_valid': {
    'en-US': 'Email or password not valid!',
    'de-DE': 'Email oder Passwort ungültig!',
    'fr-FR': 'E-mail ou mot de passe non valide !',
    'es-ES': '¡Correo electrónico o contraseña no válidos!',
    'pt-PT': 'E-mail ou senha inválidos!'
  },
  'resent_mail': {
    'en-US': 'Incorrect or expired email, please resend!',
    'de-DE': 'Falsche Email oder abgelaufen, neu senden!',
    'fr-FR': 'Email incorrect ou expiré, veuillez renvoyer!',
    'es-ES': 'Correo electrónico incorrecto o expirado, ¡por favor reenvía!',
    'pt-PT': 'Email incorreto ou expirado, por favor reenvie!',
  },
  'invalid_image_data': {
    'en-US': 'Invalid image data',
    'de-DE': 'Bildformat ungültig',
    'fr-FR': 'Données d\'image invalides',
    'es-ES': 'Datos de imagen no válidos',
    'pt-PT': 'Dados de imagem inválidos',
  },
  'error_general': {
    'en-US': 'Sorry, something went wrong',
    'de-DE': 'Sorry, es ging etwas schief',
    'fr-FR': 'Désolé, quelque chose s\'est mal passé',
    'es-ES': 'Lo siento, algo salió mal',
    'pt-PT': 'Desculpe, algo deu errado',
  },
  'really_delete': {
    'en-US': 'Do you really want to delete?',
    'de-DE': 'Möchtest Du wirklich löschen?',
    'fr-FR': 'Voulez-vous vraiment supprimer?',
    'es-ES': '¿Realmente quieres eliminar?',
    'pt-PT': 'Você realmente quer deletar?',
  },
  'really_publish': {
    'en-US': 'Do you really want to publish?',
    'de-DE': 'Möchtest Du wirklich veröffentlichen?',
    'fr-FR': 'Voulez-vous vraiment publier?',
    'es-ES': '¿Realmente quieres publicar?',
    'pt-PT': 'Você realmente quer publicar?',
  },
  'really_unpublish': {
    'en-US': 'Do you really want to unpublish?',
    'de-DE': 'Möchtest Du wirklich die Veröffentlichung aufheben?',
    'fr-FR': 'Voulez-vous vraiment dépublier?',
    'es-ES': '¿Realmente quieres despublicar?',
    'pt-PT': 'Você realmente quer despublicar?',
  },
  'save_photo': {
    'en-US': 'Do you also want to save the photo?',
    'de-DE': 'Möchtest Du auch das Bild speichern?',
    'fr-FR': 'Voulez-vous également enregistrer la photo?',
    'es-ES': '¿También quieres guardar la foto?',
    'pt-PT': 'Você também quer salvar a foto?',
  },
};
