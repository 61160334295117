import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../shared/header/header.component";
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../src/app/pipes/pipes.module";
import { Globals } from '../../global';
import { CommonModule } from '@angular/common';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-welcome',
  standalone: true,
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
  imports: [
    HeaderComponent,
    RouterModule,
    AngularMaterialModule,
    PipesModule,
    CommonModule
  ]
})
export class WelcomeComponent implements OnInit {

  constructor(public globals: Globals,
    public analytics: AngularFireAnalytics,
    private activatedRoute: ActivatedRoute,
  ) {
    analytics.setCurrentScreen('Welcome')
    analytics.logEvent("Open-Welcome")
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      let supporterId = params['sid'] ?? null;
      if (supporterId) {
        window.localStorage.setItem('sid', supporterId);
      }
    })
  }
}
