import { Component } from '@angular/core';
import { Globals } from '../../../../../projects/rooms/src/app/global';

@Component({
  selector: 'app-language-button',
  standalone: true,
  imports: [],
  templateUrl: './language-button.component.html',
  styleUrl: './language-button.component.scss'
})
export class LanguageButtonComponent {

  constructor(
    public globals: Globals
  ) {

  }
}
