import { Component } from '@angular/core';
import { HeaderComponent } from "../shared/header/header.component";
import { FooterComponent } from "../shared/footer/footer.component";
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../../src/app/angular-material.module';
import { PipesModule } from "../../../../../../src/app/pipes/pipes.module";
import { Globals } from '../../global';
import { CommonModule } from '@angular/common';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-welcome',
  standalone: true,
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
  imports: [
    HeaderComponent,
    FooterComponent,
    RouterModule,
    AngularMaterialModule,
    PipesModule,
    CommonModule
  ]
})
export class WelcomeComponent {

  constructor(public globals: Globals,
    public analytics: AngularFireAnalytics
  ) {
    analytics.setCurrentScreen('Welcome')
    analytics.logEvent("Open-Welcome")
  }
}
