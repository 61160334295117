import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AngularMaterialModule } from '../../../../../../../src/app/angular-material.module';
import { PipesModule } from '../../../../../../../src/app/pipes/pipes.module';
import { SnackbarService } from '../../../../../../../src/app/services/snackbar.service';
import { SpinnerService } from '../../../../../../../src/app/services/spinner.service';
import { fieldUserImage } from '../../../enums/fields';
import { UserData } from '../../../models/user';
import { ImageService } from '../../../services/image.service';
import { ConfirmHandlerService } from '../../../../../../../src/app/services/confirm-handler.service';
import { FormHelperService } from '../../../../../../../src/app/utils/form-helper.service';
import { HeaderComponent } from "../../shared/header/header.component";
import { Globals } from '../../../global';
import { UserDataViewModel } from '../../../viewmodels/userdata-viewmodel.service';
import { Subscription } from 'rxjs';
import { AccountManager } from '../../../services/account-manager.service';
import { AuthService } from '../../../services/auth.service';
import { TranslateService } from '../../../localization/translate.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ConsentService } from '../../../services/content.service';

@Component({
  selector: 'app-user-data',
  standalone: true,
  templateUrl: './user-data.component.html',
  styleUrl: './user-data.component.scss',
  imports: [
    AngularMaterialModule,
    CommonModule,
    PipesModule,
    RouterModule,
    HeaderComponent,
    FormsModule
  ]
})
export class UserDataComponent implements OnInit, OnDestroy {

  public imageUrl: any;

  public user: UserData | undefined;
  public registerForm: FormGroup | undefined;

  actionName = "cloud_upload"

  public enableAccountDelete = false

  public supporterId: string | null = null

  private subscription: Subscription | null = null;

  public hidePassword = true;
  public password: string = '';

  constructor(
    public snackbarService: SnackbarService,
    private accountManager: AccountManager,
    private formHelperService: FormHelperService,
    private spinnerService: SpinnerService,
    private imageService: ImageService,
    private confirmHandler: ConfirmHandlerService,
    private dialog: MatDialog,
    private router: Router,
    private viewModel: UserDataViewModel,
    private globals: Globals,
    private authService: AuthService,
    private translate: TranslateService,
    public analytics: AngularFireAnalytics,
    public consentService: ConsentService,
    private activatedRoute: ActivatedRoute
  ) {
    analytics.setCurrentScreen('UserData')
    analytics.logEvent("Open-UserData")
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {

    this.spinnerService.show()

    this.activatedRoute.queryParams.subscribe(params => {
      this.supporterId = params['sid'] ?? null;
      if (this.supporterId) {
        window.localStorage.setItem('sid', this.supporterId);
      } else {
        this.supporterId = window.localStorage.getItem('sid');
      }
    })

    this.subscription = this.accountManager.account.subscribe(async user => {
      if (user != null) {
        this.user = user;
        this.formInit();
        this.imageUrl = await this.imageService.loadImage(user.key!, '0', fieldUserImage);
        this.spinnerService.hide()
      } else {
        this.spinnerService.hide()
        this.subscription?.unsubscribe();
        console.error("No user data!")
        this.snackbarService.openDefaultErrorSnackBar()
        this.router.navigate(['login']);
      }
    });
  }

  onActionButton() {
    this.saveAccount()
  }

  async onUpdatePassword() {
    if (this.password.length >= 8 && this.password.length <= 20 && this.globals.user) {

      let user = this.globals.user;

      try {
        this.spinnerService.show()
        let result = await this.viewModel.updatePassword(this.password)
        this.spinnerService.hide()
        if (!result.success) {
          if (result.relogin) {
            this.snackbarService.openActionPerformAfterSnackBar(this.translate.translate('ud-relogin'), "OK", async () => {
              if (user.email) {
                window.localStorage.setItem('emailForSignIn', user.email);
              }
              this.spinnerService.show()
              await this.authService.login(user.email!, this.globals.baseUrl() + '/user')
              this.subscription?.unsubscribe();
              this.authService.logout()
              this.accountManager.refreshUser(false)
              this.snackbarService.openActionSnackBar(this.translate.translate('ud-login-email-sent'), "OK")
              this.router.navigate(['']);
              this.spinnerService.hide()
            })
          } else {
            console.log("could not change pw")
            this.password = ""
            this.snackbarService.openDefaultErrorSnackBar()
            this.spinnerService.hide()
          }
        } else {
          this.password = ""
          this.snackbarService.openSnackBar(this.translate.translate('ud-login-pw-updated'))
          this.spinnerService.hide()
        }
      } catch (error) {
        console.log(error)
        this.snackbarService.openDefaultErrorSnackBar()
        this.spinnerService.hide()
      }
    } else {
      this.snackbarService.openSnackBar(this.translate.translate('ud-login-pw-not-valid'))
    }
  }

  onUserConsentGiven() {
    this.consentService.grantConsent(); // User accepts tracking
  }

  onUserConsentDenied() {
    this.consentService.denyConsent(); // User denies tracking
  }

  public deleteAccount() {
    this.confirmHandler.openDialog(this.dialog, 'really_delete', null, null)
      .then(async userResponse => {
        if (userResponse == true && this.globals.user) {

          let user = this.globals.user;

          const dateFromInput = user.lastLoginDate?.toDate() ?? new Date();
          const now = new Date();
          const differenceInMillis = now.getTime() - dateFromInput.getTime();

          // Check if the difference is greater than 1 hour (3600000 milliseconds)
          const isOlderThanOneHour = differenceInMillis > 3600000;

          try {
            if (isOlderThanOneHour) {
              this.snackbarService.openActionPerformAfterSnackBar(this.translate.translate('ud-relogin'), "OK", async () => {
                this.spinnerService.show()
                await this.authService.login(user.email!, this.globals.baseUrl() + '/user')
                this.subscription?.unsubscribe();
                this.authService.logout()
                this.accountManager.refreshUser(false)
                this.snackbarService.openActionSnackBar(this.translate.translate('ud-login-email-sent'), "OK")
                this.router.navigate(['']);
                this.spinnerService.hide()
              })
            } else {
              this.spinnerService.show()
              this.subscription?.unsubscribe();
              await this.viewModel.unpublishAllBoards(user)
              await this.accountManager.deleteUser()
              this.accountManager.refreshUser(false)
              this.snackbarService.openActionSnackBar(this.translate.translate('ud-delete-account-success'), "OK")
              this.router.navigate(['']);
              this.spinnerService.hide()
            }
          } catch (error) {
            this.snackbarService.openDefaultErrorSnackBar()
            this.spinnerService.hide()
          }
        }
      })
  }

  private async savePhoto() {

    if (!this.user) {
      console.error("no user found")
      return
    }

    if (this.imageUrl && this.imageUrl.startsWith('data')) {
      var file = ImageService.dataURLtoFile(this.imageUrl, '0');
      var result = await this.imageService.saveImage(this.user.key!, file, '0', fieldUserImage)
      this.imageUrl = result?.['data']
    }
  }

  public newPhotoUploaded(): boolean {
    return this.imageUrl?.startsWith('data') ?? false
  }

  public async saveAccount() {

    try {
      if (!this.user) {
        console.error("no user found")
        return
      }

      if (!this.registerForm) {
        console.log('form invalid');
        this.snackbarService.openFillAllDataBar()
        return
      }

      this.formHelperService.markFormGroupTouched(this.registerForm);

      if (this.registerForm.invalid) {
        console.log(this.registerForm.getError);
        this.snackbarService.openFillAllDataBar()
        return;
      }

      this.spinnerService.show()

      const userListingDataChanged = this.user.firstName != this.registerForm.value.firstName || this.user.about != this.registerForm.value.about || this.newPhotoUploaded()

      this.user.firstName = this.registerForm.value.firstName;
      this.user.secondName = this.registerForm.value.secondName;

      this.user.streetNr = this.registerForm.value.streetNr ?? null;
      this.user.city = this.registerForm.value.city ?? null;
      this.user.country = this.registerForm.value.country ?? null;
      this.user.district = this.registerForm.value.district ?? null;
      this.user.post = this.registerForm.value.post ?? null;

      this.user.phone = this.registerForm.value.phone ?? null;
      this.user.about = this.registerForm.value.about;

      if (this.user.supporterId == null) {
        if (this.supporterId) {
          console.log('sid: ' + this.supporterId)
          this.user.supporterId = this.supporterId;
        } else {
          this.user.supporterId = "OOB6km8s2pg6E6cnTYTu"
        }
      }

      if (this.newPhotoUploaded()) {
        await this.savePhoto()
      }

      this.user.imageUrl = this.imageUrl ?? "";

      this.accountManager.saveAccount(this.user)
        .then(async () => {
          if (userListingDataChanged) {
            this.viewModel.updateFromUser(this.user!)
          }

          this.snackbarService.openSuccessBar()
          this.spinnerService.hide();
          this.accountManager.refreshUserAccount(this.user?.key!)

          // clean up browser
          window.localStorage.removeItem('sid');
        })
        .catch((error) => {
          console.error(error);
          this.spinnerService.hide();
          this.snackbarService.openDefaultErrorSnackBar()
        })
    } catch (error) {
      this.spinnerService.hide();
      this.snackbarService.openDefaultErrorSnackBar()
    }
  }

  selectFile(event: any) { //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.snackbarService.openInvalidImageBar()
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.snackbarService.openInvalidImageBar()
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      ImageService.compressImage(reader.result as string, 500).then(compressed => {
        this.imageUrl = compressed;
      })
    }
  }

  private formInit() {

    if (!this.user) {
      console.error("no user found")
      this.snackbarService.openDefaultErrorSnackBar()
      return
    }

    this.registerForm = new FormGroup({

      firstName: new FormControl(this.user.firstName || '',
        Validators.required),

      secondName: new FormControl(this.user.secondName || '',
        Validators.required),

      about: new FormControl(this.user.about || ''),
      /*  phone: new FormControl(this.user.phone || '',
          Validators.required),
        streetNr: new FormControl(this.user.streetNr || '',
          Validators.required),
        city: new FormControl(this.user.city || '',
          Validators.required),
        country: new FormControl(this.user.country || '',
          Validators.required),
        district: new FormControl(this.user.district || ''),
        post: new FormControl(this.user.post || '',
          Validators.required),*/
    });
  }
}