import { Injectable } from '@angular/core';
import { ListingdataService } from '../services/listingdata.service';
import { ListingData } from '../model/business/listing';
import { Globals } from '../global';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from '../services/account.service';
import { ImageService } from '../services/image.service';
import { fieldListingImage } from '../enum/helper/fields';
import { ExampleService } from '../services/helper/example.service';
import { RoomData } from '../model/business/room';
import { IndexDataType } from '../../../../../src/app/model/helper/IndexDataType';
import { UserData } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class DashboardContainer {

  public listings: BehaviorSubject<ListingData[] | null> = new BehaviorSubject<ListingData[] | null>(null);

  constructor(
    private listingService: ListingdataService,
    private accountService: AccountService,
    private globals: Globals,
    private imageService: ImageService,
    private exampleService: ExampleService
  ) {
  }

  public resetData() {
    this.listings.next(null)
  }

  async loadListings(businessKey: string) {

    this.listings.next(null)
    try {
      var result = await this.listingService.getListings(businessKey, false, null)

      if (result.length <= 0) {
        // adding examples and load again
        // await this.exampleService.addExampleListings()
        // result = await this.listingService.getListings(businessKey, false, null)
      }

      this.processListingResult(result)
    } catch (error) {
      console.log('EventRepoService' + error);
    }
  }

  public async saveListingImage(listingKey: string, file: any, name: string): Promise<IndexDataType | null> {
    return new Promise(async (result) => {
      var url = await this.imageService.saveImage(listingKey, file, name, fieldListingImage)

      var listing = await this.getListing(listingKey)

      if (listing && url) {
        listing.photos.push(url);
      }

      result(url)
    })
  }

  public deleteRoom(roomKey: string, listingKey: string, listing: ListingData, deletePhoto: boolean): Promise<boolean> {

    return new Promise<boolean>(async (result) => {

      var rooms: RoomData[] = ListingData.getRooms(listing, false)

      var index = rooms.findIndex(room => room.key == roomKey) ?? null
      if (index >= 0) {
        rooms.splice(index, 1)
      } else {
        console.error("Room to delete not found")
        result(false)
        return
      }

      var roomsJson: string[] = []
      for (let i = 0; i < rooms.length; i++) {
        roomsJson.push(JSON.stringify(rooms[i]))
      }
      listing.rooms = roomsJson

      if (deletePhoto) {
        this.imageService.deleteImage(listingKey, fieldListingImage, roomKey)
          .then(async () => {
            const listingId = await this.saveListing(listingKey, listing)
            if (listingId) {
              result(true)
            } else {
              result(false)
            }
          })
          .catch(async (error) => {
            console.error(error)
            result(false)
          })
      } else {
        const listingId = await this.saveListing(listingKey, listing)
        if (listingId) {
          result(true)
        } else {
          result(false)
        }
      }
    })
  }

  public saveRoom(roomKey: string, roomData: RoomData, listingKey: string, listing: ListingData): Promise<string | null> {

    var rooms: RoomData[] = ListingData.getRooms(listing, false)

    var index = rooms.findIndex(room => room.key == roomKey) ?? null
    if (index >= 0) {
      rooms.splice(index, 1)
    }

    rooms.push(roomData)

    var roomsJson: string[] = []
    for (let i = 0; i < rooms.length; i++) {
      roomsJson.push(JSON.stringify(rooms[i]))
    }
    listing.rooms = roomsJson

    return this.saveListing(listingKey, listing)
  }

  public saveListing(listingKey: string, listing: ListingData): Promise<string | null> {

    var allListings = this.listings.getValue()
    if (allListings) {
      var index = allListings.findIndex(listing => listing.key == listingKey) ?? null
      if (index >= 0) {
        allListings.splice(index, 1)
        this.listings.next(allListings)
        allListings.push(listing)
      }
    }

    return this.listingService.saveListing(listingKey, listing)
  }

  public deleteListing(listingKey: string): Promise<boolean | null> {

    var allListings = this.listings.getValue()
    if (allListings) {
      var index = allListings.findIndex(listing => listing.key == listingKey) ?? null
      if (index >= 0) {
        allListings.splice(index, 1)
        this.listings.next(allListings)
      }
    }

    return this.listingService.deleteListing(listingKey)
  }

  public getListing(listingKey: string): Promise<ListingData | null> {

    return new Promise<ListingData | null>(async (result) => {
      var listing = await this.listings.getValue()?.find(listing => listing.key == listingKey) ?? null

      if (!listing) {
        listing = await this.listingService.getListing(listingKey)
      }

      result(listing)
    })

  }

  public updateFromUser(userdata: UserData) {
    this.listings.getValue()?.forEach((listing) => {
      if (listing.businessKey == userdata.businessKey) {
        listing.hostAbout = userdata.about
        listing.hostName = userdata.firstName
        this.saveListing(listing.key!, listing)
      }
    })
  }

  public async newListing(): Promise<string | null> {
    var listing = new ListingData()
    listing.businessKey = this.globals.user!.businessKey
    var result = this.listingService.saveListing(null, listing)

    var allListings = this.listings.getValue()
    allListings?.push(listing)
    this.listings.next(allListings)

    return result
  }

  private processListingResult(result: ListingData[]) {
    this.listings.next(result)

    result.forEach(async (element) => {

      try {

        var urls = await this.listingService.getListingImageUrls(element)
        element.photos = urls;

      } catch (error) {
        console.error(error);
      }
    });
  }
}
