import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountManager } from '../services/account-manager.service';
import { SnackbarService } from '../../../../../src/app/services/snackbar.service';
import { LoginState } from '../enums/login-state';

@Injectable({
  providedIn: 'root',
})
export class UserCheckGuard {

  constructor(
    private _router: Router,
    private accountManager: AccountManager,
    public snackbarService: SnackbarService,
  ) { }

  private _unsubscribe: Subject<boolean> = new Subject<boolean>();

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((result) => {
      this.accountManager.loggedIn.pipe(
        takeUntil(this._unsubscribe)
      ).subscribe(loginState => {

        if (loginState !== LoginState.pending) {

          if (loginState === LoginState.loggedInNoUser) {
            if (state.url.includes('user')) {
              result(true);
              return
            }
            this._router.navigate(['/user']);
            this.snackbarService.openFillUserDataBar()
            result(false);
            return
          }

          this._unsubscribe.next(true);
          result(true);
        } else {
          result(true);
        }
      });
    });
  }
}
