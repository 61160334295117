import { DocumentData } from "@angular/fire/compat/firestore";
import { BoardRequestState } from "../enums/board-request-state";
import { BoardData } from "./board";
import { UserData } from "./user";

export class ContactData {

    key: string | null = null
    date: any | null = null
    boardKey: string | null = null

    senderKey: string | null = null

    userJson: string | null = null

    ownerKey: string | null = null
    ownerName: string | null = null
    ownerMail: string | null = null

    boardJson: string | null = null

    lastMessage: string | null = null
    lastMessageTS: number | null = null
    lastSender: string | null = null

    state: BoardRequestState = BoardRequestState.request

    static getBoardData(boardJson: string | null): BoardData | null {
        if (boardJson == null) return null
        let board: BoardData = JSON.parse(boardJson)

        return board
    }

    static getRequesterData(data: ContactData): UserData | null {
        if (data.userJson == null) return null
        let user: UserData = JSON.parse(data.userJson)

        return user
    }

    static toObject(data: ContactData): {} {
        const mappped = new Map(Object.entries(data));

        return Object.fromEntries(mappped)
    }

    static toContact(doc: DocumentData, key: string): ContactData {

        const data = doc as ContactData
        data.key = key
        if (data.state == null) {
            data.state = BoardRequestState.request
        }

        return data
    }
}
