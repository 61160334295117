import { Injectable } from "@angular/core"
import { Businessdata } from "../model/business/business"
import { BusinessdataService } from "./businessdata.service"


@Injectable({
  providedIn: 'root'
})
export class BusinessRepoService {

  constructor(
    private businessService: BusinessdataService
  ) { }

  public getBusiness(businessId: string | null): Promise<any | null> {
    if (businessId === null) {
      return new Promise(async (result) => {
        result(null)
      })
    }
    return this.businessService.getBusiness(businessId)
  }

  public saveBusiness(businessId: string | null, business: Businessdata): Promise<string | null> {
    return this.businessService.saveBusiness(businessId, business)
  }

  public searchBusiness(userKey: string): Promise<(Businessdata | null)> {
    return new Promise(async (result) => {
      this.businessService.searchBusiness(userKey)
        .then(data => {
          result(data)
        }).catch(error => {
          console.log('BusinessRepoService' + error);
        });
    })
  }
}
